{
  "common": {
    "form": {
      "input": {
        "characters": "Characters: {currentCharacters} / {characters}",
        "email": {
          "label": "Email address",
          "placeholder": "email{'@'}domain.tld",
          "validation": {
            "required": "The email address is required.",
            "invalid": "The email address is invalid."
          }
        },
        "password": {
          "label": "Password",
          "placeholder": "10 characters minimum",
          "validation": {
            "required": "The password is required.",
            "min": "The password must have at least 10 characters.",
            "format": "The password must be minimum 10 characters long to include a combination of at least 2 uppercase letters, 2 lowercase letters and 2 digits (white spaces not allowed)."
          }
        },
        "oldPassword": {
          "label": "Current password",
          "validation": {
            "required": "The current password is required."
          }
        },
        "newPassword": {
          "label": "New password",
          "placeholder": "10 characters minimum",
          "validation": {
            "required": "The new password is required.",
            "min": "The new password must have at least 10 characters.",
            "format": "The new password must be minimum 10 characters long to include a combination of at least 2 uppercase letters, 2 lowercase letters and 2 digits (white spaces not allowed)."
          }
        },
        "confirmPassword": {
          "label": "Confirm password",
          "validation": {
            "required": "The confirm password is required.",
            "bothMatch": "Passwords do not match"
          }
        },
        "firstName": {
          "label": "First name",
          "placeholder": "Your first name",
          "validation": {
            "required": "The first name is required."
          }
        },
        "lastName": {
          "label": "Last name",
          "placeholder": "Your last name",
          "validation": {
            "required": "The last name is required."
          }
        },
        "zip": {
          "label": "ZIP code",
          "placeholder": "ZIP code",
          "validation": {
            "format": "The ZIP code is not valid.",
            "required": "The ZIP code is required.",
            "min": "The ZIP code must have at least {min} characters.",
            "max": "The ZIP code must have at most {max} characters."
          }
        },
        "phone": {
          "label": "Phone number",
          "placeholder": "Enter your phone number",
          "validation": {
            "required": "The phone number is required.",
            "regex": "The phone number should only contain digits.",
            "min": "The minimum number of digits is {min}.",
            "max": "The maximum number of digits is {max}."
          }
        },
        "phonePrefix": {
          "label": "Phone prefix",
          "placeholder": "Select a phone prefix",
          "validation": {
            "required": "The phone prefix is required."
          }
        },
        "linkedIn": {
          "label": "LinkedIn (optional)",
          "placeholder": "https://www.linkedin.com/",
          "validation": {
            "format": "The URL should be a valid LinkedIn URL."
          }
        },
        "image": {
          "label": "Upload an image",
          "validation": {
            "required": "The logo is required.",
            "format": "The file format is not supported.",
            "size": "The file size is larger than the maximum size allowed."
          }
        },
        "policy": {
          "label": "I have read and accept the {0} and the {1}",
          "terms": "TOS",
          "privacy": "Privacy Policy",
          "validation": {
            "required": "Please accept the terms of service and the privacy policy."
          }
        },
        "cardNumber": {
          "label": "Card number",
          "required": "The card number is required."
        },
        "cardExpiry": {
          "label": "Expiration MM/YY",
          "required": "The expiration date is required."
        },
        "cardCvv": {
          "label": "CVV",
          "required": "The CVV is required."
        },
        "cardName": {
          "label": "Cardholder name",
          "required": "The cardholder name is required."
        },
        "cardNickname": {
          "label": "Card nickname (optional)",
          "placeholder": "Card nickname",
          "required": "The cardholder name is required."
        },
        "imageUpload": {
          "label": "Upload an image",
          "fileType": "PNG, JPG up to 50MB",
          "minimumResolution": "Minimum resolution {minWidth}×{minHeight} or higher.",
          "cropperMinimumResolution": "Resolution min {minWidth}×{minHeight} and max {maxWidth}×{maxHeight}.",
          "validation": {
            "required": "The image is required.",
            "format": "The file format is not supported.",
            "size": "The file size is larger than the maximum size allowed.",
            "minResolution": "The file resolution is too low ({minWidth}x{minHeight}).",
            "maxResolution": "The file resolution is too high ({maxWidth}x{maxHeight})."
          }
        },
        "videoUpload": {
          "label": "Choose a video",
          "fileType": "MP4, MOV, AVI up to 1GB",
          "aspectRatio": "16:9 or 9:16",
          "minimumResolution": "Minimum resolution 1920x1080 (HD)",
          "duration": "Duration 30 - 60 seconds",
          "validation": {
            "format": "The video format is not supported."
          }
        },
        "search": {
          "placeholder": "Search",
          "validation": {
            "required": "The search is required.",
            "max": "The search is too long."
          }
        }
      },
      "select": {
        "default": "Choose an option",
        "option": "Select an option"
      },
      "checkbox": {
        "validation": {
          "required": "You must select at least one option."
        }
      },
      "textarea": {
        "characters": "Characters: {currentCharacters} / {characters}"
      },
      "textEditor": {
        "characters": "Characters: {currentCharacters} / {characters}"
      },
      "submit": {
        "savedSuccess": "Saved Successfully"
      }
    },
    "button": {
      "expand": "Expand",
      "clear": "Clear",
      "clearAll": "Clear all",
      "collapse": "Collapse",
      "markAllRead": "Mark all as read",
      "markRead": "Mark as read",
      "removeFromList": "Remove from list",
      "settings": "Settings",
      "showAll": "Show all",
      "previous": "Prev",
      "login": "Sign in",
      "continue": "Continue",
      "skip": "Skip",
      "send": "Send",
      "sending": "Sending...",
      "loading": "Loading...",
      "downloading": "Downloading...",
      "leaving": "Leaving...",
      "searching": "Searching...",
      "confirm": "Confirm",
      "resetPassword": "Reset password",
      "nextStep": "Next Step",
      "back": "Back",
      "start": "Start your first campaign",
      "cancel": "Cancel",
      "save": "Save",
      "makePayment": "Make a payment",
      "confirmPay": "Confirm and Pay",
      "saveCard": "Save card",
      "saveChanges": "Save changes",
      "next": "Next",
      "close": "Close",
      "createCampaign": "Create campaign",
      "create": "Create",
      "backCampaign": "Back to campaign {campaign}",
      "editProduct": "Edit product",
      "edit": "Edit",
      "yes": "Yes",
      "no": "No",
      "ok": "Ok",
      "creating": "Creating...",
      "requestDemo": "Request a demo",
      "socialMedia": {
        "signIn": {
          "facebook": "Sign in with Facebook",
          "google": "Sign in with Google",
          "apple": "Sign in with Apple"
        },
        "signUp": {
          "facebook": "Sign up with Facebook",
          "google": "Sign up with Google",
          "apple": "Sign up with Apple"
        },
        "alt": {
          "facebook": "Sign in with Facebook",
          "google": "Sign in with Google",
          "apple": "Sign in with Apple"
        },
        "altSignup": {
          "facebook": "Sign up with Facebook",
          "google": "Sign up with Google",
          "apple": "Sign up with Apple"
        }
      },
      "update": "Update",
      "upload": "Upload",
      "addCreditCard": "Add credit card",
      "addNewCard": "Add a new card",
      "showExample": "Show examples",
      "home": "Go back home",
      "apply": "Apply",
      "filters": "Filters",
      "email": "Email",
      "leave": "Leave",
      "reload": "Reload",
      "delete": "Delete",
      "verify": "Verify",
      "changePaymentMethod": "Change payment method",
      "SeeDetails": "See details",
      "contractDetails": "Job details",
      "leaveReview": "Leave a review",
      "offerDetails": "Offer details",
      "inviteMoreCreators": "Invite more creators",
      "cancelOffer": "Cancel offer",
      "resendOffer": "Resend offer",
      "approveOffer": "Approve offer",
      "counterOffer": "Counter offer",
      "denyOffer": "Deny offer",
      "startJob": "Start job",
      "cancelJob": "Cancel job",
      "confirmShipping": "Confirm shipping",
      "goCalendar": "Go to calendar",
      "chat": "Chat",
      "end": "End",
      "reject": "Reject"
    },
    "error": {
      "global": {
        "internalServerError": "We currently cannot process your request, our technical staff has been notified about this problem (incident code: {id}) and they are on it!",
        "connectivityError": "We encounter connectivity problems. Retrying...",
        "networkError": "A network error occurred when processing your request. Please retry or contact our support.",
        "offline": "It looks like you are offline. Please check your internet connection...",
        "maintenance": "{project} is undergoing maintenance, please check back later.",
        "upgrading": "{project} is being updated, please be patient...",
        "notAuthorized": "You have been logged out to protect your account, please login again.",
        "userSuspended": "There is an issue with your account, please contact our support team.",
        "userLoggedOut": {
          "title": "Sign in to continue",
          "message": "You logged out from another tab. Please sign in again to continue."
        },
        "userChanged": {
          "title": "Reload to continue",
          "message": "You seem to have switched to another account in this browser.  Press reload to continue."
        },
        "websocketReconnectionFailed": {
          "title": "Reload to continue",
          "message": "A reload is required to re-establish the browser connection to JoinBrands. Press reload to continue."
        },
        "googleLoginFailed": "An unexpected error happened when logging you in with your Google account",
        "facebookLoginFailed": "An unexpected error happened when logging you in with your Facebook account",
        "appleLoginFailed": "An unexpected error happened when logging you in with your Apple account",
        "addressVerificationFailed": "Address verification failed.",
        "endpointNotFound": "An error occurred when processing your request. Please retry or contact our support.",
        "emailNotSetup": "Email is missing required field.",
        "emailVerificationRequired": "Email verification is required. Please check your inbox.",
        "emailVerificationResendTooSoon": "Please wait a minute before requesting a new email confirmation.",
        "invalidEmailPasswordCombination": "The credentials you provided are incorrect.",
        "invalidInput": "Some information that you provided is missing or invalid.",
        "invalidEmailVerificationCode": "Verification code has expired.",
        "invalidPassword": "The password you provided is incorrect.",
        "invalidFileUpload": "No file uploaded or invalid file type.",
        "companyExists": "The email address is already in use.",
        "companyNotFound": "We can't find an account with this email address.",
        "companyOnboardNotComplete": "Onboarding has not been completed yet.",
        "companyOnboardPointerMismatch": "Section Onboarding not found.",
        "brandNotFound": "Brand not found.",
        "jobNotFound": "Job not found.",
        "jobTypeNotFound": "Job Type not found.",
        "companyTodoJobNotFound": "Todo not found.",
        "campaignNotFound": "Campaign not found.",
        "productNotFound": "Product not found.",
        "creditCardNotFound": "Credit card payment not found.",
        "creditCardNotAccepted": "Credit card payment method is not accepted.",
        "creditCardInvalid": "The credit card number is invalid.",
        "paymentFailed": "Card declined",
        "chatNotFound": "We can't find this chat.",
        "shippingCarrierNotFound": "Shipping carrier not found",
        "creatorFirebaseDeviceExists": "The problem of giving permission.",
        "creditCardInUse": "The payment card is still in use for some campaigns, it can't be deleted.",
        "cannotDeleteADefaultCard": "The default credit card cannot be deleted.",
        "emailAlreadyInUse": "Email already in use",
        "invalidResponse": "An unexpected error occurred when processing your request. Please retry or contact our support.",
        "companyAlreadyHasRejectedContent": "You have already rejected the content once from the creator.",
        "creatorNotFound": "Creator not found.",
        "creditCardHasAnIssue": "Add a different credit card to finish creating your campaign.",
        "creatorTiktokNotFound": "The TikTok creator can't be found.",
        "walletInsufficientBalance": "The amount to be withdrawn is greater than the available balance wallet.",
        "jobRefundFailed": "The reimbursement cannot be processed, please contact our support team.",
        "jobAlreadyTaken": "Job already taken for this campaign.",
        "tiktokAdServiceUnavailable": "TikTok is currently unavailable.",
        "tikTokAdInvalidBudgetException": "To change your budget now, set it to at least 105% of your current spend. Otherwise, set a scheduled budget change.",
        "tiktokAdCampaignHasBeenDeleted": "This TikTok ad has already been deleted.",
        "campaignBundleCouponNotFound": "The coupon is not valid or has expired.",
        "stripeApiRequestFailed": "An error has occurred, please try again later.",
        "2faExpired": "Your session has expired, please login.",
        "stripePlanNoIncludeWatermark": "The current subscription plan doesn't include custom watermark.",
        "whiteLabelHostExists": "The host already exists.",
        "whiteLabelHostNotFound": "The custom domain or subdomain is not configured.",
        "whiteLabelHostApiClientError": "The SSL certificate for the custom domain or subdomain is not properly set up.",
        "stripePlanNoIncludeDeadline": "The current Subscription plan doesn't include extended deadline.",
        "stripePlanCreatorInvitedLimit": "The current subscription plan has exceeded the invites limit for creators.",
        "accessDenied": "You don't have access to that section.",
        "recurringContractInvalidEvent": "This action is not possible for the recurring job currently."
      },
      "firebase": {
        "accountExists": "Your {project} account is already linked to your {existingProvider} account. Please sign in your {existingProvider} account so we can link your {newProvider} account too."
      },
      "validation": {
        "minStringChars": "The minimum number of characters required is {min}.",
        "maxStringChars": "The maximum number of characters allowed is {max}.",
        "minNumber": "The minimum number required is {min}.",
        "maxNumber": "The maximum number allowed is {max}.",
        "fileTooLarge": "The file is too large (maximum size {maxSize}) or an error occurred while uploading the file.",
        "imageInsufficientResolution": "The minimum resolution required is {requiredWidth}x{requiredHeight} but the following resolution has been detected: {detectedWidth}x{detectedHeight}.",
        "videoInsufficientResolution": "The minimum resolution required is {requiredWidth}x{requiredHeight} but the following resolution has been detected: {detectedWidth}x{detectedHeight}.",
        "notDurationRequired": "The duration should be between {min} and {max} seconds.",
        "numberTypeError": "You must specify a number.",
        "billingNoInvoices": "There is no invoice available for this period.",
        "alreadyHasRating": "This job has already received a review rating.",
        "url": "The link provided is not a valid URL."
      },
      "image": "Image error",
      "stripe": {
        "insufficientFunds": "The credit card has insufficient funds to cover this payment.",
        "authenticationRequired": "The payment requires authentication to proceed.",
        "incorrectNumber": "The card number is incorrect. Check the card’s number or use a different card.",
        "processingError": "An error occurred while processing the card. Try again later or with a different payment method.",
        "expiredCard": "The card has expired. Check the expiration date or use a different card.",
        "incorrectCvc": "The card’s security code is incorrect. Check the card’s security code or use a different card.",
        "invalidCvc": "The card’s security code is invalid. Check the card’s security code or use a different card.",
        "transactionNotAllowed": "The customer needs to contact their card issuer for more information.",
        "doNotHonor": "The customer needs to contact their card issuer for more information.",
        "genericDecline": "The customer needs to contact their card issuer for more information.",
        "defaultErrorMessage": "The card was declined for an unknown reason. Please contact our support."
      }
    },
    "system": {
      "version": "New version",
      "updateMessage": "There is a new version of the system, please press update."
    },
    "toast": {
      "information": "Information",
      "warning": "Warning",
      "error": "Error",
      "success": "Success"
    },
    "route": {
      "dashboard": {
        "meta": "Dashboard",
        "link": "Dashboard"
      },
      "chat": {
        "meta": "Chat",
        "link": "Chat"
      },
      "logout": {
        "link": "Log out"
      },
      "notifications": {
        "meta": "Notification"
      }
    },
    "flash": {
      "resendVerification": "We sent you a new verification link to your email.",
      "sendResetPassword": "We sent you an email to reset your password."
    },
    "link": {
      "showExamples": "Show examples",
      "chatWithCreator": "Chat with creator"
    },
    "label": {
      "from": "From",
      "new": "New",
      "savePercent": "Save {percent}%",
      "included": "Included",
      "fullName": "Full name",
      "status": "Status",
      "street": "Street",
      "country": "Country",
      "city": "City/Town",
      "state": "State",
      "zipCode": "ZIP code",
      "address": "Address",
      "date": "Date",
      "copyAddress": "Copy address",
      "seeDetails": "See details",
      "download": "Download",
      "copied": "Copied",
      "other": "Other",
      "sort": "Sort by",
      "content": "Content",
      "paymentMethodError": "Issue",
      "listStatus": {
        "completed": "Completed",
        "finished": "Closed",
        "canceled": "Canceled"
      },
      "late": "late",
      "left": "left",
      "showFirst": "Show first",
      "admin": "Admin {id}",
      "suspended": "Suspended",
      "beta": "Beta"
    },
    "imageSlider": {
      "title": "Image preview",
      "error": "This file has not a valid image format."
    },
    "videoSlider": {
      "title": "Video preview",
      "error": "This file is not a valid video format."
    },
    "datatable": {
      "showing": "Showing",
      "to": "to",
      "of": "of"
    },
    "jobs": "{count} Job | {count} jobs",
    "creators": "{count} creator | {count} creators",
    "payment": {
      "payDirectly": "Pay directly from your credit card.",
      "card": {
        "required": "Please add a credit card to continue"
      }
    },
    "sort": {
      "newest": "Newest",
      "oldest": "Oldest",
      "rating": "Highest rated",
      "level": "Level",
      "jobStatus": "Job status",
      "lowestPrice": "Price: low to high",
      "highestPrice": "Price: high to low",
      "followers": "Highest followers count",
      "posts": "Highest post views",
      "avgViews": "Avg. views",
      "recently": "Recently applied",
      "lastSeen": "Last seen online",
      "completedJobs": "Completed jobs: high to low"
    },
    "showFirst": {
      "newest": "Newest",
      "awaitPublication": "Awaiting publication",
      "completed": "Completed"
    },
    "jobType": {
      "1": {
        "title": "How-to video",
        "description": "Detailed video tutorial to guide customers through all steps of using your product."
      },
      "2": {
        "title": "Unboxing video",
        "description": "Entertaining unboxing video showing the packaging and what comes along with the product."
      },
      "3": {
        "title": "Product review",
        "description": "Positive feedback videos where creators spread the word about how awesome your product is."
      },
      "4": {
        "title": "Product demo",
        "description": "Detailed on-camera demonstration of how your product works and looks in real life."
      },
      "5": {
        "title": "Lifestyle shot",
        "description": "Photos of your product being used in real-life activities."
      },
      "6": {
        "title": "Selfie with product",
        "description": "Selfies with content creators and your product in focus."
      },
      "7": {
        "title": "Product demo",
        "description": "Detailed product demonstration of what your product can do."
      },
      "8": {
        "title": "Product only",
        "description": "Images showing your product in the best way possible."
      },
      "9": {
        "title": "TikTok video",
        "description": ""
      },
      "10": {
        "title": "Instagram Reel",
        "description": ""
      },
      "11": {
        "title": "YouTube Short",
        "description": ""
      },
      "12": {
        "title": "Amazon Shoppable video",
        "description": ""
      },
      "13": {
        "title": "TikTok Shop video",
        "description": ""
      }
    },
    "recurringCampaignType": {
      "tiktok": "TikTok",
      "instagram": "Instagram",
      "tiktok_instagram": "TikTok + Instagram",
      "tiktok_shop": "TikTok Shop"
    },
    "slotFrequency": {
      "daily": "Once a day",
      "every_other_day": "Every second day",
      "every_third_day": "Every third day",
      "twice_a_day": "Twice a day"
    },
    "socialUgc": {
      "image": "Image",
      "video": "Video",
      "tiktok": "TikTok",
      "instagram": "Instagram",
      "youtube": "YouTube",
      "amazon": "Amazon"
    },
    "deliveryType": {
      "1": "Free product",
      "2": "Reimbursement"
    },
    "placeholder": {
      "search": "Search"
    },
    "levels": {
      "level1": "Level 1",
      "level2": "Level 2",
      "level3": "Level 3",
      "level4": "Pro Level"
    },
    "gender": {
      "title": "Gender",
      "input": {
        "1": "Male",
        "2": "Female",
        "3": "Non-binary"
      }
    },
    "age": {
      "title": "Preferable age",
      "title2": "Age",
      "input": {
        "1": "18 - 24",
        "2": "25 - 34",
        "3": "35 - 44",
        "4": "45+"
      }
    },
    "ethnicity": {
      "title": "Ethnicity",
      "input": {
        "1": "White",
        "2": "Hispanic",
        "3": "African American",
        "4": "Asian",
        "5": "Middle Eastern"
      }
    },
    "favorites": {
      "title": "Only favorites"
    },
    "breadcrumbs": {
      "home": "Home",
      "campaigns": "Campaigns",
      "creatorTitle": "{name}'s job",
      "newCampaign": "New campaign",
      "recurringCampaigns": "Recurring campaigns"
    },
    "privacy": "Privacy Policy",
    "terms": "Terms & Conditions",
    "termsOfService": "Terms of Service",
    "needHelp": "Need help?",
    "cost": {
      "from": "from {price}"
    },
    "contentPublished": "Content published",
    "seePost": "See post",
    "sparkCode": "Spark code",
    "download": "Download"
  },
  "core": {
    "shared": {
      "components": {
        "layout": {
          "footer": {
            "rights": "All rights reserved",
            "blog": "Blog",
            "academy": "Academy",
            "faq": "FAQ",
            "language": "English",
            "contact": "Contact support",
            "news": "News"
          },
          "navbar": {
            "dashboard": "Dashboard",
            "account": "Account",
            "wallet": "Wallet",
            "billing": "Billing",
            "paymentMethods": "Payment methods",
            "brands": "Brands",
            "notifications": "Notifications",
            "subscription": "Subscription",
            "affiliate": "Affiliate program",
            "chat": "Chat",
            "campaigns": "Campaigns",
            "jobs": "Jobs",
            "delivery": "Delivery",
            "products": "Products",
            "content": "Content",
            "more": "More",
            "requestAFeature": "Request a feature",
            "contact": "Contact support",
            "adCenter": "Ad center",
            "whiteLabeling": "White labeling",
            "collaborators": "Collaborators",
            "recurringCampaigns": "Recurring campaigns",
            "recurringJobs": "Recurring jobs"
          },
          "sidebar": {
            "link": {
              "profile": "Profile"
            }
          },
          "progressBar": {
            "uploading": "Uploading..."
          }
        },
        "pagination": {
          "paginationDeets": {
            "showing": "Showing",
            "pagination": "{0} {1} to {2} of {3} {4}"
          }
        },
        "videoUpload": {
          "status": {
            "processing": "Processing...",
            "failed": "We were not able to process your video. Please try it again or upload another video."
          }
        },
        "imageCropper": {
          "desktopLabel": "Scroll to Zoom",
          "mobileLabel": "Pinch to zoom"
        },
        "calendar": {
          "options": {
            "1": "Today",
            "2": "This week",
            "3": "Last week",
            "4": "This month",
            "5": "Last month",
            "6": "This year"
          },
          "month": {
            "0": "January",
            "1": "February",
            "2": "March",
            "3": "April",
            "4": "May",
            "5": "June",
            "6": "July",
            "7": "August",
            "8": "September",
            "9": "October",
            "10": "November",
            "11": "December"
          },
          "day": {
            "1": "Sun",
            "2": "Mon",
            "3": "Tue",
            "4": "Wed",
            "5": "Thu",
            "6": "Fri",
            "7": "Sat"
          },
          "buttons": {
            "cancel": "Cancel",
            "confirmation": "Ok"
          }
        },
        "slider": {
          "more": "Show details",
          "less": "Collapse"
        },
        "contactSupport": {
          "title": "Contact support",
          "subject": "Subject",
          "characters": "Characters: {currentCharacters} / {characters}",
          "buttons": {
            "cancel": "Cancel",
            "send": "Send",
            "ok": "Ok"
          },
          "form": {
            "details": {
              "label": "Details",
              "placeholder": "Please describe your problem with as much details as possible!"
            },
            "name": {
              "label": "Enter your name",
              "validation": {
                "required": "The name is required",
                "min": "The minimum characters required is {nb}"
              }
            },
            "email": {
              "label": "Enter your email",
              "placeholder": "name{'@'}example.com",
              "validation": {
                "required": "The email is required"
              }
            },
            "subjectValidation": {
              "required": "The subject is required",
              "max": "The maximum characters allowed is {nb}",
              "min": "The minimum characters required is {nb}"
            },
            "detailsValidation": {
              "required": "Details field is required",
              "max": "The maximum characters allowed is {nb}",
              "min": "The minimum characters required is {nb}"
            }
          },
          "submitted": {
            "title": "Thank you, your ticket has been sent",
            "message": "Our support specialist will contact you shortly."
          }
        },
        "content": {
          "contentPreviewModal": {
            "paging": "{0} of {1}"
          }
        },
        "comboboxTags": {
          "input": {
            "placeholder": "Search...",
            "legend": "Select the option that matches your settings.",
            "validation": {
              "max": "The requirement may not be longer than { characters } characters.",
              "min": "The requirement may not be less than { characters } characters."
            }
          }
        },
        "video": {
          "videoCloud": {
            "unsupported": "The video format is unsupported or the video can't be played."
          }
        },
        "filterBox": {
          "label": {
            "all": "All",
            "selectedOptions": "{count} options"
          }
        },
        "creatorSidebar": {
          "badge": {
            "collaborated": "collaborated"
          },
          "yourContent": {
            "title": "Your content",
            "collapse": "Collapse content",
            "expand": "Expand content",
            "noRecords": "No content yet"
          }
        }
      },
      "helpers": {
        "units": {
          "time": {
            "seconds": " {nb}s",
            "minutes": " {nb}m",
            "hours": " {nb}h",
            "days": " {nb}d",
            "months": " {nb}mo",
            "years": " {nb}yr",
            "fewSeconds": "few seconds",
            "endWord": {
              "left": "{text} left",
              "late": "{text} late"
            }
          }
        }
      }
    },
    "modules": {
      "account": {
        "register": {
          "meta": "Sign up",
          "title": {
            "text1": "Sign up",
            "text2": " as a Brand"
          },
          "form": {
            "email": {
              "label": "Enter your email",
              "placeholder": "name{'@'}example.com"
            },
            "password": {
              "label": "Password",
              "placeholder": "10 characters minimum"
            }
          },
          "text": {
            "password": "Your password must be minimum 10 characters long."
          },
          "button": {
            "label": "Continue"
          },
          "links": {
            "registerCreator": "Register as a creator",
            "account": "Already have an account?",
            "signIn": "Sign In"
          },
          "legend": {
            "text": "By signing up, you agree to the {0} and {1}"
          }
        },
        "login": {
          "meta": "Sign in",
          "h1": "Sign in as a Brand",
          "text": {
            "account": "Don't have an account?"
          },
          "link": {
            "forgot": "Forgot password?",
            "signUp": "Sign up here"
          },
          "verifyCode": {
            "message": "You have to be logged in to verify your account."
          }
        },
        "twoFactorAuthentication": {
          "meta": "Two-factor authentication",
          "h1": "New device detected",
          "title1": "We have sent a confirmation code to your email address",
          "title2": "The code has been sent successfully",
          "description": "For your security, we need to verify your identity. We've sent a verification code to your registered email address. Please check your email and enter the code below to continue.",
          "phoneDescription": "For your security, we need to verify your identity. We've sent a verification code to your mobile phone +{0}. Please check your phone and enter the code below to continue.",
          "button": {
            "codeNotReceived": "Didn't receive a code?",
            "waiting": "Resend in {time}s...",
            "verify": "Verify",
            "sendAgain": "Send again",
            "resending": "Resending...",
            "chooseMethod": "Choose another verification method"
          },
          "form": {
            "input": {
              "code": {
                "label": "Enter your code",
                "placeholder": "Enter the 6-digit code",
                "help": "It may take up to 5 minutes to receive your code. Haven't received it? Please wait {0} to resend the code.",
                "validation": {
                  "required": "The verification code is required."
                }
              }
            }
          },
          "flash": {
            "invalid": "The verification code is invalid or has expired.",
            "invalidPhone": "The verification code is invalid or has expired.",
            "resent": {
              "sms": "An SMS containing the verification code has been sent again.",
              "email": "An email containing the verification code has been sent again."
            }
          }
        },
        "logout": {
          "meta": "Logout",
          "h1": {
            "loggingOut": "Logging you out...",
            "loggedOut": "You have been logged out!"
          },
          "p": {
            "loggingOut": "We are logging you out from the system, please be patient...",
            "loggedOut": "You have been successfully logged out from the system."
          }
        },
        "forgotPassword": {
          "meta": "Forgot Password?",
          "h1": "Forgot password?",
          "button": {
            "label": "Reset Password",
            "loading": "Sending reset link..."
          },
          "text": "Already have a {project} account?",
          "link": {
            "login": "Sign in"
          }
        },
        "resetPassword": {
          "meta": "Reset Password",
          "h1": {
            "reset": "Reset your password",
            "updated": "Password reset successful"
          },
          "p": {
            "updated": "Your password has been successfully reset. Please click on the button below to login."
          },
          "label": "New Password",
          "text": "Already have a JoinBrands account?",
          "link": "Sign in"
        },
        "confirmEmail": {
          "meta": "Confirm your email",
          "h1": "Let's confirm your email"
        },
        "impersonate": {
          "meta": "Impersonate",
          "h1": {
            "impersonating": "Logging you in...",
            "impersonated": "You are now logged in as {name}"
          },
          "p": {
            "impersonating": "We are logging you into the system, please be patient...",
            "impersonated": "You have been successfully logged to the system."
          }
        },
        "verifyEmail": {
          "meta": "Verify your email address",
          "h1": {
            "title": "Please check your inbox",
            "wrongEmail": "Wrong email address?"
          },
          "p": {
            "sent": "We sent an email to {0}. In order to continue the sign-up process, please click the verification link in that email.",
            "spam": "If you did not receive the confirmation email, please check your spam folder and/or verify that you entered the correct email address above.",
            "mistypedEmail": "If you mistyped your email address, you can simply go through the register page again. Do you want to continue?"
          },
          "flash": {
            "resend": "We sent you a new verification link to your email.",
            "email": "We sent you a verification link to your email.",
            "emailChanged": "Your email has been updated successfully.",
            "phoneAdded": "Your phone number has been added successfully."
          },
          "button": {
            "emailNotReceived": "Didn't receive an email?",
            "wrongEmail": "Wrong email?",
            "waiting": "Resend in {time}s...",
            "cancel": "Cancel",
            "continue": "Continue"
          },
          "updateEmail": {
            "p": {
              "sent": "We sent an email to {0}. In order to continue the update process, please click the verification link in that email."
            }
          }
        },
        "verifyEmailCode": {
          "meta": "Verifying your email address",
          "h1": {
            "verifying": "Verifying...",
            "verified": "Email address verified!",
            "notVerified": "Email address not verified"
          },
          "h3": {
            "verifying": "We are verifying your email address, almost there...",
            "verified": "Your email address has been successfully verified."
          },
          "p": {
            "causes": "An error occurred when verifying your email address. This could occur because the code is expired or is incorrect.",
            "request": "You can request another verification code, which will be sent to your email address {0}.",
            "restart": "If you mistyped your email address, you can simply go through the register page again. Do you want to continue?"
          },
          "button": {
            "emailNotReceived": "Resend verification",
            "waiting": "Resend in {time}s...",
            "wrongEmail": "Wrong email?",
            "cancel": "Cancel",
            "continue": "Continue"
          }
        },
        "verificationMethods": {
          "meta": "Two-factor verification methods",
          "h1": "Choose a verification method to confirm your actions",
          "form": {
            "radio": {
              "email": "Email",
              "primary": "Primary phone number",
              "backup": "Backup phone number"
            },
            "button": {
              "back": "Back"
            }
          }
        }
      },
      "chat": {
        "sendMessage": "Write Message...",
        "offline": "offline"
      }
    }
  },
  "modules": {
    "campaign": {
      "selection": {
        "title": "Create a new campaign",
        "ugcType": {
          "button": "Start",
          "price": "from {price}",
          "scratch": {
            "title": "Start from Scratch",
            "message": "You create campaign with all the option available right now."
          },
          "image": {
            "title": "UGC Images: Content only",
            "message": "Order engaging UGC Images from thousands of UGC creators. Choose from 4 distinct types of images to suit your brand's needs.",
            "bundleTitle": "Image Blasts"
          },
          "video": {
            "title": "UGC Videos: Content Only",
            "message": "Elevate your brand's presence with engaging UGC videos sourced from our network of UGC creators. Select from a variety of video types tailored to your marketing objectives."
          },
          "tiktok": {
            "title": "TikToks",
            "bundleTitle": "TikTok Blasts",
            "message": "Our TikTok creators make TikTok videos, share them on their own TikTok pages, and you get the content and a spark code to help boost the video on TikTok."
          },
          "instagram": {
            "title": "Instagram Reels",
            "bundleTitle": "Instagram Blasts",
            "message": "Our Instagram creators make Instagram Reels for you and post them on their Instagram accounts. You own the content."
          },
          "youtube": {
            "title": "YouTube Shorts",
            "bundleTitle": "YouTube Shorts Blasts",
            "message": "Our YouTube creators make YouTube Shorts for you and share them on their YouTube channels. You own the content."
          },
          "bundle": {
            "title": "Blast: Bulk packages",
            "message": {
              "images": "Get a bulk of images tailored to your needs. This option focuses on delivering a high volume of content without any posting. Perfect for those who need a lot of visuals in a short time.",
              "video": "Detailed video tutorial to guide customers through all steps of using your product.",
              "tiktok": "Dive into the TikTok scene with a surge of videos. While we prioritize quantity, each video still holds the potential to go viral. Ideal for brands aiming for widespread TikTok presence.",
              "instagram": "Boost your Instagram presence with a multitude of Reels. With quantity as our main focus, these Reels can significantly increase your brand's visibility on the platform.",
              "youtube": "Get a wave of short YouTube videos to expose your brand on YouTube. While creators churn out content rapidly, each short can capture the audience's attention.",
              "amazon": "Elevate your Amazon listings with numerous shoppable videos. Prioritizing quantity, these videos can drive traffic and sales by showcasing products in quick, engaging clips."
            },
            "tiktok": "TikTok blast",
            "instagram": "Instagram Reel blast",
            "youtube": "YouTube Shorts blast",
            "amazon": "Amazon Shoppable blast",
            "selfie": "Selfie with product blast"
          },
          "amazon": {
            "title": "Amazon Shoppable Videos",
            "bundleTitle": "Amazon Shoppable Video Blasts",
            "message": "Our Amazon Influencers make videos for your brand and upload them through their Amazon Influencers' accounts. You own the content."
          }
        },
        "ugcTitle": "UGC (User-generated content) - Content Only",
        "socialTitle": "Influencer marketing",
        "blastTitle": "Blasts: Product Seeding with Quantity First, Quality Second"
      },
      "creation": {
        "modal": {
          "title": "Choose your campaign objective",
          "contentOnly": {
            "title": "Content Only",
            "image": {
              "title": "Images",
              "description": "Content only",
              "details": "from {cost}",
              "deets": {
                "title": "Content Only - Images",
                "description": "Creators will produce various types of images, such as selfies with the product, product demos, lifestyle, or product only."
              }
            },
            "video": {
              "title": "Videos",
              "description": "Content only",
              "details": "from {cost}",
              "deets": {
                "title": "Content Only - Videos",
                "description": "Creators will produce video content, such as how-to guides, unboxing, product reviews, or product demos."
              }
            }
          },
          "contentPosting": {
            "title": "Content + Posting",
            "amazon": {
              "title": "Amazon Shoppable videos",
              "description": "Content + Post",
              "details": "{cost} / per post",
              "deets": {
                "title": "Amazon",
                "description": "Amazon influencers produce videos and publish them as shoppable videos on their stores. You own the content."
              }
            },
            "tiktok": {
              "title": "TikTok videos",
              "description": "Content + Post",
              "details": "from {cost} / per post",
              "deets": {
                "title": "TikTok",
                "description": "TikTok creators produce videos and publish them as posts on their TikTok profiles. You own the content."
              }
            },
            "instagram": {
              "title": "Instagram Reels",
              "description": "Content + Post",
              "details": "{cost} / per post",
              "deets": {
                "title": "Instagram",
                "description": "Instagram creators produce videos and publish them as reels on their Instagram profiles. You own the content."
              }
            },
            "youtube": {
              "title": "YouTube Shorts",
              "description": "Content + Post",
              "details": "{cost} / per post",
              "deets": {
                "title": "YouTube",
                "description": "YouTube creators produce videos and publish them as shorts on their YouTube channel. You own the content."
              }
            },
            "tiktok_shop": {
              "title": "TikTok Shop videos",
              "description": "Content + Post",
              "details": "from {cost} / per post",
              "deets": {
                "title": "TikTok Shop video",
                "description": "TikTok creators produce videos and publish them as posts on their TikTok profiles. You own the content."
              }
            }
          },
          "socialBlast": {
            "title": "Social Blasts",
            "amazon": {
              "title": "Amazon Blast",
              "description": "10 - 100 Amazon videos",
              "details": "from {cost} / per post",
              "deets": {
                "title": "Amazon",
                "description": "Amazon influencers produce videos and publish them as shoppable videos on their stores. You own the content."
              }
            },
            "tiktok": {
              "title": "TikTok Blast",
              "description": "10 - 100 TikTok videos",
              "details": "from {cost} / per post",
              "deets": {
                "title": "TikTok",
                "description": "TikTok creators produce videos and publish them as posts on their TikTok profiles. You own the content."
              }
            },
            "instagram": {
              "title": "Instagram Blast",
              "description": "10 - 100 Instagram Reels",
              "details": "from {cost} / per post",
              "deets": {
                "title": "Instagram",
                "description": "Instagram creators produce videos and publish them as reels on their Instagram profiles. You own the content."
              }
            },
            "youtube": {
              "title": "YouTube Blast",
              "description": "10 - 100 YouTube Shorts",
              "details": "from {cost} / per post",
              "deets": {
                "title": "YouTube",
                "description": "YouTube creators produce videos and publish them as shorts on their YouTube channel. You own the content."
              }
            },
            "image": {
              "title": "Selfie Blast",
              "description": "10 - 100 Selfie images",
              "details": "from {cost} / per image",
              "deets": {
                "title": "Image",
                "description": "Creators will produce various types of images, such as selfies with the product, product demos, lifestyle, or product only."
              }
            },
            "tiktok_shop": {
              "title": "TikTok Shop Blast",
              "description": "10 - 100 TikTok Shop videos",
              "details": "from {cost} / per post",
              "deets": {
                "title": "TikTok Shop video",
                "description": "TikTok creators produce videos and publish them as posts on their TikTok profiles. You own the content."
              }
            },
            "video": {
              "title": "Video Blast",
              "description": "10 - 100 Videos",
              "details": "from {cost} / per video",
              "deets": {
                "title": "Video",
                "description": "Creators will produce various types of videos, such as how to video, unboxing, product review, or product demo."
              }
            }
          },
          "recurringCreators": {
            "title": "Recurring creators",
            "tiktok": {
              "title": "TikTok",
              "description": "Recurring TikTok creator",
              "details": "from {cost}/month",
              "deets": {
                "title": "TikTok",
                "description": "Hire experienced creators to constantly produce and post content for you. This option is perfect if you want your TikTok account to run on auto-pilot."
              }
            },
            "instagram": {
              "title": "Instagram",
              "description": "Recurring Instagram creator",
              "details": "from {cost}/month",
              "deets": {
                "title": "Instagram",
                "description": "Hire experienced creators to constantly produce and post content for you. This option is perfect if you want your Instagram account to run on auto-pilot."
              }
            },
            "tiktok_instagram": {
              "title": "TikTok + Instagram",
              "description": "Recurring TT + IG creator",
              "details": "from {cost}/month",
              "deets": {
                "title": "TikTok + Instagram",
                "description": "Hire experienced creators to constantly produce and post content for you. This option is perfect if you want your TikTok and Instagram accounts to run on auto-pilot."
              }
            },
            "tiktok_shop": {
              "title": "TikTok Shop",
              "description": "Recurring TikTok Shop creator",
              "details": "from {cost}/month",
              "deets": {
                "title": "TikTok",
                "description": "Hire experienced creators to constantly produce and post content for you. This option is perfect if you want your TikTok account to run on auto-pilot."
              }
            }
          }
        },
        "pendingDraftModal": {
          "title": "Resume editing?",
          "messageFirstPart": "You have an unfinished campaign {0}. {1}",
          "messageSecondPart": "Want to resume editing this campaign or create a new one?",
          "messageContinueSecondPart": "Want to resume editing this campaign or continue with the selected campaign?",
          "messageResubmitSecondPart": "Want to resume editing this campaign or resubmit the declined campaign?",
          "messageFromNewProductSecondPart": "Want to resume editing this campaign or create a new one with the selected product?",
          "messageRehire": "Want to resume editing this campaign or continue with create a new one?",
          "resumeButton": "Resume editing",
          "newButton": "Create new campaign",
          "resubmitButton": "Resubmit",
          "continueButton": "Continue",
          "cancelButton": "Cancel"
        },
        "leave": {
          "title": "Leave campaign creation?",
          "message": "Changes you made may not be saved.",
          "button": "Leave"
        },
        "brand": {
          "title": "Brand",
          "add": "Add your brand",
          "addFirst": "Add your first brand",
          "more": "More brands",
          "form": {
            "name": {
              "label": "Brand name",
              "placeholder": "Your brand name"
            },
            "website": {
              "label": "Website (optional)",
              "placeholder": "https://www.yourbrand.com"
            },
            "industry": {
              "label": "Industry"
            },
            "description": {
              "label": "Description (optional)",
              "placeholder": "Describe your brand"
            },
            "buttons": {
              "add": "Add brand",
              "cancel": "Cancel"
            }
          },
          "modal": {
            "title": "Choose your brand",
            "search": "Search by name brand"
          },
          "noBrands": "No brands available",
          "noBrandsLegend": "You don't have access to any brands, nor do you have access to add one, Please communicate with the person that made you a collaborator and request access."
        },
        "ugcType": {
          "title": "Type of campaign",
          "image": "UGC Images: Content only",
          "video": "UGC Videos: Content only",
          "tiktok": "TikTok videos",
          "tiktok_shop": "TikTok Shop videos",
          "instagram": "Instagram Reels",
          "youtube": "YouTube Shorts",
          "bundle": "Blast: Bulk packages",
          "amazon": "Amazon Shoppable Videos",
          "tiktokBlast": "TikTok Blast",
          "instagramBlast": "Instagram Reels Blast",
          "youtubeBlast": "YouTube Shorts Blast",
          "amazonBlast": "Amazon Shoppable Videos Blast",
          "imageBlast": "Image Blast",
          "bundles": {
            "9": "TikTok Blast",
            "10": "Instagram Blast",
            "11": "YouTube Blast",
            "12": "Amazon Blast",
            "6": "Selfie Blast",
            "13": "TikTok Shop Blast"
          }
        },
        "rehireInvitation": {
          "title": "Hire again",
          "message": "The invitation for {0} has been sent successfully",
          "legend": "After the creator accepts the invitation, your campaign will go live",
          "backToChat": "Back to chat",
          "backToCampaign": "Back to campaign"
        },
        "coupon": {
          "input": {
            "placeholder": "Enter a promo code"
          },
          "label": "Promo code",
          "invalid": "This coupon is not valid",
          "notification": {
            "title": "Congrats!",
            "message": "Promo code {code} successfully added"
          }
        },
        "recurringCampaigns": {
          "campaignName": "Recurring creators{0} {1}",
          "defaultCampaignName": "{date}, Recurring Creators, {ugcLabel}",
          "steps": {
            "info": "Campaign info",
            "brief": "Campaign Brief",
            "payment": "Payment"
          },
          "validation": {
            "name": "The campaign name is required",
            "image": "The cover image is required",
            "salary": {
              "required": "The monthly salary is required",
              "min": "The minimum monthly salary should be {amount}.",
              "max": "The maximum monthly salary should be {amount}."
            }
          },
          "cover": {
            "label": "Cover image",
            "description": "Please provide an image that best illustrates your product line and or services. This is what creators will see."
          },
          "prePostingContent": {
            "title": "Pre-posting content approval",
            "description": "Please check the box below if you wish to approve each video before the creator posts it. Note that this option demands a repeated commitment on your part. If you do not approve the content on time, it will be auto-approved within 24 hours. If you have sufficient trust in the creator to allow them to post content without prior approval, leave the box unchecked.",
            "label": "Content needs approval before posting"
          },
          "shipping": {
            "label": "Is shipping required for your product(s)?",
            "description": "You will negotiate the delivery details with the creator via chat. If product delivery is required, the subscription is activated when the creator receives the product(s). Creator needs to confirm the receipt of the product(s). If product delivery is not required, e.g. creator already has the product, or your product is digital, the subscription starts immediately."
          },
          "frequency": {
            "label": "Content frequency",
            "description": "How often (on average) do you want the creator to create and post the content?",
            "1": {
              "label": "Once a day",
              "details": "30 videos/month"
            },
            "2": {
              "label": "Twice a day",
              "details": "60 videos/month"
            },
            "3": {
              "label": "Once every 2 days",
              "details": "15 videos/month"
            },
            "4": {
              "label": "Once every 3 days",
              "details": "10 videos/month"
            },
            "input": {
              "placeholder": "Min {amount}",
              "label": "Monthly salary (offer)",
              "description": "Make an offer to your creator. The creator will be able to counter-offer, if he/she thinks the offer is not sufficient."
            }
          },
          "explain": {
            "label": "Explain the job",
            "description": "⚡ Explain creators how you want your content to be produced and posted. Describe your product and/or service. Give as much detail as possible. Please note that this job is limited by the parameters that you've picked above.",
            "brief": {
              "placeholder": "This form expands as you type. You can include links, e.g. GoogleDoc, if you need to provide more detail.",
              "label": "Creator brief",
              "characters": "Characters: {currentCharacters} / {totalCharacters}"
            },
            "reference": {
              "placeholder": {
                "tiktok": "https://tiktok.com/myvideo",
                "instagram": "https://instagram.com/reel/myvideo"
              },
              "label": "References (optional)",
              "description": "Provide links to videos or channels that you like and want the creator to take ideas from."
            }
          },
          "payment": {
            "tip": {
              "title": "You will not be charged before creator has accepted the offer",
              "message": "You will be charged from the moment when creator accepts the job."
            }
          },
          "sidebar": {
            "title": "Subject to offer acceptance",
            "frequency": "Content Frequency",
            "numberVideos": "Number of videos",
            "pricePerVideo": "Price per video",
            "monthlyPayment": "Monthly payment",
            "platformFee": "Platform Fee ({percent}%)",
            "expectedBudget": "Expected monthly budget"
          },
          "tiktokShopProduct": "TikTok Shop product"
        }
      },
      "details": {
        "step": "Campaign info",
        "meta": "New campaign",
        "title": "New campaign",
        "creation": "Campaign creation",
        "form": {
          "brand": {
            "label": "Brand",
            "button": "Add a new brand",
            "placeholder": "Select brand",
            "validation": {
              "required": "You must select a brand to continue."
            }
          },
          "product": {
            "label": "Product",
            "button": "Add a new product",
            "placeholder": "Select product",
            "validation": {
              "required": "The product is required."
            }
          },
          "name": {
            "label": "Campaign name (only you can see it)",
            "placeholder": "Enter the name of your campaign",
            "validation": {
              "max": "The campaign name may not be longer than { characters } characters.",
              "min": "The campaign name must be at least { characters } characters."
            }
          },
          "ugcType": {
            "validation": "You must select a campaign type to continue."
          }
        },
        "newProduct": {
          "physicalProduct": "Add a physical product",
          "digitalProduct": "Add a digital product"
        },
        "selectProductType": {
          "title": "Add a product",
          "physicalProduct": {
            "title": "Physical product",
            "text": "A product that requires delivery or a full refund"
          },
          "digitalProduct": {
            "title": "Digital product or online service",
            "text": "An online service that doesn't require delivery"
          }
        },
        "sidebar": {
          "campaignStatus": "Campaign status",
          "statusApplicants": "Applicants:",
          "statusApproved": "Approved:",
          "statusInvited": "Invited:",
          "statusCompleted": "Completed:",
          "statusInProgress": "In progress:",
          "needToFind": "Need to find",
          "totalJobs": "Total jobs",
          "jobsAvailable": "Jobs available",
          "jobsAvailableCount": "{available} of {total}",
          "creators": "{count} creator | {count} creators",
          "preApproved": "Pre-approved: ",
          "influencer": "{count} influencer | {count} influencers",
          "tiktok": {
            "views": "Total views",
            "comments": "Total comments"
          }
        }
      },
      "productInformation": {
        "step": "Product information",
        "meta": "Campaign details",
        "title": "Campaign details",
        "productInformation": "Product information",
        "productInfo": "Product info",
        "btnInvite": "Invite creators",
        "form": {
          "shipping": {
            "label": "Shipping cost (optional)",
            "validation": {
              "required": "The shipping cost is required.",
              "noNegative": "The shipping cost must be greater than or equal to 0.",
              "lessThan": "The shipping cost should be lower than 100.",
              "format": "The shipping cost should have only 2 decimals."
            }
          },
          "taxes": {
            "label": "Taxes (optional)",
            "validation": {
              "required": "The taxes cost is a required field.",
              "noNegative": "The taxes must be greater than or equal to 0.",
              "lessThan": "The taxes cost should be lower than 100.",
              "format": "The taxes cost should have only 2 decimals."
            }
          },
          "reimbursement": {
            "title": "Reimbursement",
            "description": "Creators purchase the product on the marketplace and the payment is refunded."
          },
          "delivered": {
            "title": "Delivered by me",
            "description": "You will deliver the product to each creator yourself."
          },
          "product": {
            "validation": "You must select a product to continue."
          }
        },
        "modal": {
          "title": {
            "edit": "Edit product"
          }
        },
        "tooltip": {
          "reimbursement": "Creators purchase the product on the marketplace and the payment is refunded.",
          "delivered": "You will deliver the product to each creator yourself."
        },
        "product": {
          "title": "Product",
          "add": "Add your product",
          "addFirst": "Add your first product",
          "legend": "⚡ This is how creators will see your product",
          "more": "More products",
          "modal": {
            "title": "Choose your product",
            "search": "Search by product name"
          },
          "form": {
            "productType": "Type of product",
            "add": "Add product"
          },
          "noProducts": "No products available",
          "noProductsLegend": "You don't have access to any products, nor do you have access to add one, Please communicate with the person that made you a collaborator and request access."
        },
        "deliveryTooltip": {
          "shipping": "If shipping within the {marketplace} is not provided for free, please include the cost in your payment; this amount will be reimbursed to the creator. Including shipping costs is optional but recommended to attract more creators to your job.",
          "taxes": "In the {marketplace}, creators often pay taxes on purchases, with rates varying by state. Please consider adding an average tax amount to your payment, using a suggested rate of 7% of the product price. While including this tax is optional, it's recommended to enhance the appeal of your job to creators."
        },
        "tiktokShop": {
          "cardTitle": "TikTok Shop product",
          "title": "TikTok Shop product link",
          "legend": "Please provide the TikTok Shop product link here, to ensuring the affiliate creator has the necessary information to correctly link the product.",
          "input": {
            "placeholder": "https://shop.tiktok.com/view/product/21354812185652135458",
            "validation": {
              "required": "The TikTok Shop link is required",
              "valid": "The URL should be a valid TikTok Shop URL. "
            }
          }
        },
        "affiliate": {
          "title": "TikTok Shop affiliate commission",
          "subtitle": "Commission is paid through your TikTok account. Check your commission rates {0}.",
          "link": "here",
          "legend": "Enter a value from 1 to 100",
          "input": {
            "validation": {
              "required": "The commission is required",
              "min-max": "The commission must be between 1 and 100"
            }
          }
        }
      },
      "settings": {
        "cancelCampaignCreation": "Cancel campaign creation",
        "meta": "Content and creators",
        "step": "Creators parameters",
        "title": "New campaign:  ",
        "titleInMobile": "New campaign",
        "ugcType": {
          "title": "Campaign type",
          "new": "New",
          "types": {
            "image": {
              "title": "Photo",
              "description": "Content only"
            },
            "video": {
              "title": "Video",
              "description": "Content only"
            },
            "tiktok": {
              "title": "TikTok",
              "description": "Content + Posting"
            },
            "bundle": {
              "title": "Blast",
              "description": "Bulk packages"
            },
            "social": {
              "title": "Social",
              "description": "Content + Posting"
            },
            "instagram": {
              "title": "Instagram",
              "description": "Content + Posting"
            },
            "youtube": {
              "title": "YouTube Shorts",
              "description": "Content + Posting"
            },
            "amazon": {
              "title": "Amazon Shoppable Videos",
              "description": "Content + Posting"
            }
          }
        },
        "CertificationType": {
          "title": "Content type"
        },
        "deliveryType": "Delivery",
        "imageHowTo": "How-to image examples",
        "videoHowTo": "How-to video examples",
        "creatorLevelExamples": "Creators level {creatorPrice} examples",
        "contentFormat": {
          "title": "Content format",
          "image": {
            "any": {
              "title": "Any",
              "legend": "Let creators choose"
            },
            "portrait": {
              "title": "Portrait",
              "legend": "Perfect for story image"
            },
            "landscape": {
              "title": "Landscape",
              "legend": "Full landscape horizontal image"
            },
            "square": {
              "title": "Square",
              "legend": "Perfect for feed image"
            }
          },
          "video": {
            "any": {
              "title": "Any",
              "legend": "The creator determines which format to use"
            },
            "portrait": {
              "title": "Portrait",
              "legend": "Perfect for Instagram reels, TikTok videos, YouTube shorts"
            },
            "landscape": {
              "title": "Landscape",
              "legend": "Full landscape horizontal video"
            },
            "square": {
              "title": "Square",
              "legend": "Perfect for video ads"
            },
            "vertical": {
              "title": "Vertical video",
              "legend": "Perfect for Instagram and Facebook feeds/posts"
            }
          },
          "formats": {
            "any": "Any",
            "portrait": "Portrait",
            "landscape": "Landscape",
            "square": "Square"
          },
          "aspectRatio": {
            "any": "Doesn't matter"
          }
        },
        "specialRequirementsKeywords": {
          "title": "Special requirements (optional)",
          "input": {
            "label": "Select other special requirements",
            "placeholder": "Type to search any requirement and hit \"Enter\" to add.",
            "caption": "Mention anything that creators must have in order to take this job."
          }
        },
        "specialRequirements": {
          "input": {
            "label": "Anything else?"
          }
        },
        "tagsProduct": {
          "title": "Target ASINs",
          "input": {
            "placeholder": "Enter ASINs one by one, comma separated",
            "caption": "Add ASINs (Amazon Standard Identification Number) to tag in the video. You can add ASIN and we will form a link",
            "validation": "One or more ASIN has an invalid format. 10 characters separated by a comma with no space"
          }
        },
        "tiktokPost": {
          "title": "TikTok post",
          "recommendations": {
            "legend": "Creators will shoot videos with your product and post them on their TikTok accounts.",
            "1": "You'll manually select the creators you want to work with and the videos you want to be posted.",
            "2": "The post will be active on TikTok for at least 7 days!"
          }
        },
        "InstagramPost": {
          "title": "Instagram Reel",
          "recommendations": {
            "legend": "Creators will shoot videos with your product and post them on their Instagram accounts.",
            "1": "You'll manually select the creators you want to work with and the videos you want to be posted.",
            "2": "The post will be active on Instagram for at least 7 days!"
          }
        },
        "youtubePost": {
          "title": "YouTube Short",
          "recommendations": {
            "legend": "Creators will shoot videos with your product and post them on their YouTube accounts.",
            "1": "You'll manually select the creators you want to work with and the videos you want to be posted.",
            "2": "The post will be active on YouTube for at least 7 days!"
          }
        },
        "amazonPost": {
          "title": "Amazon Shoppable Videos",
          "recommendations": {
            "legend": "Creators will shoot videos with your product and post them on their Amazon Influencer accounts.",
            "1": "You'll manually select the creators you want to work with and the videos you want to be posted",
            "2": "The post will be active on Amazon",
            "3": "Longer than 5 seconds and less than 60 minutes"
          }
        },
        "description": {
          "title": "Content brief",
          "legend": "⚡ Give creators more detail about the product or service you would like to promote. You can include a description with its main features as well as some links.",
          "input": {
            "description": {
              "label": "Description",
              "placeholder": "For example, I want the content in the kitchen to show only the hands",
              "recommendations": {
                "legend": "Recommendations on what to mention in this description:",
                "1": "Specify what exactly you want to see: face, hands, etc.",
                "2": "Specify the location in which the content should be filmed: outside, in the kitchen, in the house, etc."
              }
            },
            "caption": {
              "label": "Caption (optional)",
              "placeholder": "How to wear the {'@'}glowskin face mask #facemask #sheetmask #lifehack #skincare",
              "recommendations": {
                "1": "Keep it short for maximum effect.",
                "2": "What you can use: Emoji, hashtags, trends.",
                "3": "Separate tags with space & tag your brand profile."
              },
              "legend": "Describe your publication in short for maximum effect. Add emoji, hashtags, trends. Separate tags with space & tag your brand profile.",
              "examples": {
                "1": "#facemask",
                "2": "#sheetmask",
                "3": "#lifehack",
                "4": "#skincare",
                "5": "#face"
              }
            },
            "amazonCaption": {
              "label": "Title (optional)",
              "legend": "Describe what customers need to know about this video.",
              "placeholder": "For example, name of your product"
            },
            "socialTitle": {
              "label": "Video title (optional)",
              "placeholder": "How to wear the {'@'}glowskin face mask",
              "recommendations": {
                "1": "Keep it short for maximum effect.",
                "2": "What you can use: Emoji, #hashtags, {'@'}username."
              }
            },
            "socialDescription": {
              "label": "Video description (optional)",
              "legend": "If you need to add a description to the video, then add it in this section",
              "placeholder": "How to wear the {'@'}glowskin face mask #facemask #sheetmask #lifehack #skincare",
              "recommendations": {
                "1": "What you can use: Emoji, #hashtags, {'@'}username.",
                "2": "Separate tags with space & tag your brand profile."
              }
            },
            "references": {
              "label": "References (optional)",
              "tiktokLegend": "Add suggest links that you like from TikTok to give the creator some content ideas.",
              "instagramLegend": "Add suggest links that you like from Instagram to give the creator some content ideas.",
              "otherLegend": "Add suggested links that you like to give the creator some content ideas.",
              "youtubeLegend": "Add suggest links that you like from YouTube to give the creator some content ideas.",
              "tiktokPlaceholder": "https://tiktok.com/myvideo",
              "instagramPlaceholder": "https://instagram.com/reel/myreel",
              "youtubePlaceholder": "https://youtube.com/shorts/myshort",
              "otherPlaceholder": "https://siteurl.com",
              "addLink": "+ Add link",
              "validation": {
                "httpProtocol": "Your link should start with http:// or https://.",
                "url": "The reference link is invalid."
              },
              "tiktokTooltip": "Suggest links that you like from TikTok to give the creator some content ideas.",
              "instagramTooltip": "Suggest links that you like from Instagram to give the creator some content ideas.",
              "youtubeTooltip": "Suggest links that you like from YouTube to give the creator some content ideas."
            }
          },
          "examples": {
            "1": "I want the content",
            "2": "in the kitchen",
            "3": "in the bathroom",
            "4": "outside",
            "5": "face",
            "6": "hands"
          }
        },
        "tabs": {
          "image": {
            "name": "Photo",
            "contentType": {
              "title": "Content type"
            },
            "contentFormat": {
              "sizes": {
                "portrait": "Portrait",
                "landscape": "Landscape",
                "square": "Square",
                "any": "Any"
              }
            }
          },
          "video": {
            "name": "Video",
            "contentType": {
              "title": "Content type"
            },
            "duration": {
              "title": "Video duration",
              "15": "15 seconds",
              "30": "30 seconds",
              "60": "1-3 minutes",
              "120": "120 seconds",
              "180": "3-5 minutes",
              "300": "5-10 minutes",
              "600": "10+ minutes",
              "amazon": "Up to 60 min"
            },
            "contentFormat": {
              "sizes": {
                "portrait": "Portrait",
                "landscape": "Landscape",
                "any": "Any"
              }
            }
          }
        },
        "payForm": {
          "creators": "Matching creators",
          "influencers": "Matching influencers",
          "delivery": "Estimated content delivery",
          "cost": {
            "image": "Price per image",
            "video": "Price per video"
          },
          "costJob": "Cost per job",
          "priceProduct": "Reimbursement price per product",
          "costButton": {
            "name": "Details",
            "product": "Product price",
            "shipping": "Shipping",
            "taxes": "Taxes"
          },
          "numCreators": "Number of creators needed",
          "numInfluencers": "Number of influencers needed",
          "total": "Total budget",
          "budget": "Budget",
          "tiktokSidebarTooltip": "Minimum price per campaign (depends on the creator's average video views).",
          "creatorLevel": "Creator level adjustment",
          "videoDuration": "Video duration adjustment"
        },
        "creators": {
          "title": "Creators",
          "creatorLevel": "Creators level",
          "creatorsParameters": "Creators parameters",
          "ratings": "{number}  ({count} ratings) ",
          "levels": {
            "1": {
              "title": "Included",
              "legend": "Our newest joining creators on the platform. These creators may not have any completed jobs and that much experience."
            },
            "2": {
              "title": "{0}Creators with a rating of 4 or higher",
              "legend": "These creators have good reviews from brands, they will be able to make high-quality content"
            },
            "3": {
              "title": "{0}Creators with a rating of 4.3 or higher",
              "legend": "These creators are popular, they have a large number of ratings, they shoot excellent content"
            }
          },
          "tooltip": "There are three creator levels that reflect the quality and competence of content creation.",
          "description": {
            "placeholder": "Please describe in detail what you want the content to look like.",
            "validation": {
              "required": "The description is required."
            }
          },
          "quantity": {
            "validation": {
              "min": "The number of creators must be greater than or equal to {min}.",
              "max": "The number of creators must be less than or equal to {max}.",
              "format": "The number of creators must be an integer."
            }
          },
          "age": {
            "title": "Preferable age",
            "ageRanges": {
              "1": "18 – 24",
              "2": "25 – 34",
              "3": "35 – 44",
              "4": "45+"
            }
          },
          "nrCreators": {
            "legend": "Select the ideal number of Creators that you're aiming to hire for this campaign",
            "socialLegend": "Select the ideal number of Creators that you're aiming to hire for this campaign",
            "message": "In order for the advertising campaign to produce results, we recommend using at least {0}.",
            "message2": "And according to our statistics, if you use {0}, there will be a double increase in sales.",
            "creators": "{amount} creators",
            "influencers": "{amount} influencers"
          }
        },
        "approval": {
          "title": "Creators approval",
          "tabs": {
            "automatic": {
              "title": "Automatic approval",
              "text": "All creators who pick your job will be automatically approved."
            },
            "manual": {
              "title": "Manual approval",
              "text": "Invite creators or accept applications and manually approve them."
            }
          }
        },
        "calculationLog": {
          "numberCreators": "Number of creators",
          "numberInfluencers": "Number of influencers",
          "needToFind": "Find creators",
          "needToFindInfluencers": "Find Influencers",
          "costPerJob": "Cost per job",
          "totalBudget": "Total budget",
          "calculation": "{0} x {1} = {2}"
        },
        "bundle": {
          "tiktok": {
            "title": "TikTok blast"
          },
          "selfie": {
            "title": "Selfie blast"
          },
          "instagram": {
            "title": "Instagram blast"
          },
          "youtube": {
            "title": "YouTube blast"
          },
          "amazon": {
            "title": "Amazon blast"
          },
          "content": {
            "title": "Content type",
            "coming": "Coming soon",
            "type": {
              "9": "TikTok blast",
              "6": "Selfie blast",
              "10": "Instagram blast",
              "11": "YouTube blast",
              "12": "Amazon blast",
              "13": "TikTok Shop blast",
              "4": "Video blast"
            }
          },
          "bundles": {
            "title": "Pick a package",
            "posts": "{posts} posts",
            "images": "{images} images",
            "reels": "{reels} Reels",
            "shorts": "{shorts} Shorts",
            "videos": "{videos} Videos",
            "amount": {
              "4": "{amount} per Video",
              "9": "{amount} per TikTok",
              "6": "{amount} per job",
              "10": "{amount} per Reel",
              "11": "{amount} per Short",
              "12": "{amount} per Video",
              "13": "{amount} per TikTok"
            },
            "discount": "{discount}% Discount",
            "noDiscount": "No discount"
          },
          "tiktok_shop": {
            "title": "TikTok Shop blast"
          }
        },
        "social": {
          "title": "Social network",
          "subtitle": "Social type"
        }
      },
      "summary": {
        "meta": "Summary",
        "step": "Summary & Payment",
        "title": "New campaign: ",
        "id": "No. {id}",
        "product": {
          "title": "Product",
          "link": "Product link",
          "reimbursementBadge": "Creators buy the product, you refund the cost",
          "deliveredBadge": "You have to send the product to the creator",
          "badge": {
            "free": "Free product"
          },
          "shipment": {
            "free": "Will be shipped to you",
            "reimbursement": "Buy and get reimbursed"
          }
        },
        "content": {
          "title": "Content and creators",
          "contentType": "Content type and format",
          "contentTypeBundle": "Content type",
          "seconds": "sec",
          "demo": {
            "video": "Product demo video",
            "image": "Product demo image"
          },
          "description": "Description",
          "caption": "Caption",
          "social_title": "Video title",
          "social_description": "Video description",
          "references": "References",
          "creatorParameters": "Creator parameters",
          "level": "Level {count}",
          "asins": "Product ASINs"
        },
        "cost": {
          "label": "Cost",
          "priceVideo": "Price per video",
          "creatorLevel": "Creator level {level}",
          "durationPrice": "Duration price",
          "priceImage": "Price per image",
          "pricePost": "Price per post",
          "priceProduct": "Product price",
          "shipping": "Shipping",
          "taxes": "Taxes",
          "costJob": "Cost per job",
          "costCampaign": "SubTotal cost per campaign",
          "platformFee": "Platform Fee ({percentage}%)"
        },
        "numberCreators": {
          "labelCreators": "Number of creators",
          "labelInfluencers": "Number of influencers",
          "validation": {
            "required": "The number of creators is required.",
            "moreThan": "The number of creators should be higher than {min}.",
            "lessThan": "The number of creators should be lower or equal than {max}."
          }
        },
        "totalBudget": "Expected total budget",
        "total": "Total",
        "messagePayment": {
          "title": "You will NOT be charged full amount upfront.",
          "description": "You will be charged for each creator job individually, as soon as creators accept the job.",
          "description2": "You will be charged as soon as creator accept the job."
        },
        "messageBundle": {
          "title": "You will be charged full amount upfront for this campaign.",
          "description": "All unused funds will be refunded to your wallet."
        },
        "messageBundlePayment": {
          "title": "You will be charged full amount upfront.",
          "description": "You will be charged full amount upfront for this campaign. No refunds will be issued for partial use of blasts."
        },
        "tip": {
          "declined": {
            "title": "Campaign declined",
            "message": "Your campaign has been declined for the following reason: {reason}."
          }
        },
        "tabs": {
          "jobs": {
            "title": "Jobs"
          },
          "creators": {
            "title": "Creators ({applicants})"
          },
          "postedVideos": {
            "title": {
              "tiktok": "TikTok posts ({nbVideos})",
              "instagram": "Instagram Reels ({nbVideos})",
              "youtube": "YouTube Shorts ({nbVideos})",
              "amazon": "Amazon Shoppable videos ({nbVideos})",
              "tiktok_shop": "TikTok Shop posts ({nbVideos})"
            }
          },
          "content": {
            "title": "Content ({total})"
          },
          "delivery": {
            "title": "Delivery"
          },
          "details": {
            "title": "Campaign details"
          }
        },
        "flash": {
          "jobsAdded": "You successfully added one more job to your campaign. | You successfully added {nbJobs} more jobs to your campaign."
        },
        "emptyState": {
          "jobs": {
            "title": "It's a bit quiet in here!",
            "message": "All the creator who took your jobs will show up here. Meanwhile, you ca invite more creators to work tih you!"
          },
          "creators": {
            "title": "Someone is coming",
            "message": "Watch this space, and creators will come at any moment, Till then, go find some fresh talents!"
          },
          "content": {
            "title": "Stay tuned",
            "message": "Your content is heading your way! Till then, go find some fresh talents out here!"
          },
          "postedVideos": {
            "title": "Nope, not yet!",
            "message": "Not to much until your product will be on TikTok. Until then, why don't you go find some new creators to work with?"
          },
          "button": "Find Creators"
        },
        "jobDetails": {
          "title": "Job details",
          "description": "Switch your subscription to a different type, such as a monthly plan, annual plan, or student plan. And see a list of subscription plans that Flowbite offers.",
          "delivery": {
            "1": "Delivered by me",
            "2": "Reimbursement"
          },
          "orientation": {
            "any": "Any content",
            "portrait": "Portrait content",
            "landscape": "Landscape content",
            "square": "Square content",
            "vertical": "Vertical content"
          }
        }
      },
      "applicants": {
        "actions": {
          "portfolio": "See portfolio",
          "tiktokProfile": "TikTok profile",
          "instagramProfile": "Instagram profile",
          "youtubeProfile": "YouTube profile",
          "amazonProfile": "Amazon profile",
          "invite": "Invite",
          "sendOffer": "Send offer",
          "approve": "Approve",
          "hide": "Hide",
          "block": "Block forever",
          "unblock": "Unblock",
          "cancelInvite": "Cancel invite",
          "jobDetails": "Job details",
          "inviting": "Inviting...",
          "approving": "Approving...",
          "canceling": "Canceling...",
          "hiding": "Hiding...",
          "blocking": "Blocking...",
          "chat": "Chat with creator",
          "hidden": {
            "title": "Hidden for this campaign",
            "text": "All your other jobs will be visible to this creator except this one. Don't worry, we won't tell anyone!",
            "button": "Undo"
          },
          "blocked": {
            "title": "Blocked forever",
            "text": "This creator will never see your jobs again. Don’t worry, we won’t tell anyone!",
            "button": "Undo"
          },
          "blockedModal": {
            "title": "Do you want to block this creator?",
            "text": "This creator will never see your jobs again. Don’t worry, we won’t tell anyone!",
            "button": "Block"
          },
          "campaign": "Campaign details"
        },
        "applicant": "Applicants",
        "invited": "Invited",
        "declined": "Rejected",
        "paginationLegend": "creators",
        "noCreators": "No applicants to shortlist.",
        "tip": {
          "title": "We recommend inviting {nb} more creator | We recommend inviting {nb} more creators",
          "message": "Some creators might not respond right away therefore we suggest inviting more creators than you need."
        },
        "levels": {
          "level1": "Level 1",
          "level2": "Level 2",
          "level3": "Level 3",
          "level4": "Pro Level"
        },
        "denyModal": {
          "title": "Deny applicant?",
          "content": "Are you sure you want to dismiss this creator?"
        },
        "approveModal": {
          "title": "Approve applicant?",
          "content": "Are you sure you want to invite this creator?"
        },
        "blockModal": {
          "title": "Block applicant forever?",
          "content": "Are you sure you want to block this creator?"
        },
        "tiktok": {
          "tooltip": {
            "followers": "Followers",
            "avgViews": "Average views"
          }
        },
        "popover": {
          "rating": "({count} rating) | ({count} ratings)",
          "jobs": "Completed jobs: {0}",
          "deliveryTime": "Average delivery time: {0}",
          "deliveryDays": "{count} day | {count} days",
          "seen": "Last seen online: {0}",
          "seenTime": "{count} day ago | {count} days ago",
          "seenDate": "{time}",
          "seenToday": "Today",
          "buttons": {
            "profile": "See profile",
            "chat": "Chat"
          },
          "averageTime": {
            "years": "{count} year | {count} years",
            "months": "{count} month | {count} months",
            "days": "{count} day | {count} days",
            "hours": "{count} hour | {count} hours",
            "minutes": "{count} minute | {count} minutes",
            "seconds": "{count} second | {count} seconds"
          }
        },
        "badge": {
          "new": "New"
        },
        "sort": {
          "newest": "Newest",
          "oldest": "Oldest",
          "rating": "Rating",
          "highestRated": "Highest rated",
          "level": "Level",
          "levelHigh": "Level: high to low",
          "levelLow": "Level: low to high",
          "jobStatus": "Job status",
          "price": "Lowest price",
          "lowestPrice": "Price: low to high",
          "highestPrice": "Price: high to low",
          "followers": "Followers",
          "highestFollowers": "Highest followers count",
          "posts": "Highest post views",
          "avgViews": "Avg. views",
          "recently": "Recently applied",
          "lastSeen": "Last seen online",
          "completedJobs": "Completed jobs: high to low"
        },
        "tooltips": {
          "hide": "Don't show creator in this campaign. Creator won't see it.",
          "cancel": "If you revoke the invite, the creator will still be able to apply for this job.",
          "jobDetails": "Go to the job page"
        }
      },
      "postedVideos": {
        "noJobs": "No posted videos yet.",
        "banner": {
          "title": "Nope, not yet!",
          "tiktokSubtitle": "Very soon your video will be posted on TikTok. Until then, why don't you go find some new creators to work with?",
          "tiktokSubtitle2": "Very soon your video will be posted on TikTok.",
          "instagramSubtitle": "Very soon your video will be posted on Instagram. Until then, why don't you go find some new creators to work with?",
          "instagramSubtitle2": "Very soon your video will be posted on Instagram.",
          "youtubeSubtitle": "Very soon your video will be posted on YouTube. Until then, why don't you go find some new creators to work with?",
          "youtubeSubtitle2": "Very soon your video will be posted on YouTube.",
          "amazonSubtitle": "Very soon your video will be posted on Amazon. Until then, why don't you go find some new creators to work with?",
          "amazonSubtitle2": "Very soon your video will be posted on Amazon."
        }
      },
      "payment": {
        "meta": "Payment",
        "step": "Payment",
        "title": "Payment",
        "info": "Additional 3% fee for Credit/Debit Card payment",
        "tooltip": {
          "chargeWallet": "If you are making a purchase, any wallet funds will be spent first.",
          "budget": "The amount of money you’re expected to spend on this campaign."
        },
        "wallet": {
          "title": "My wallet",
          "subtitle": "Below you can select another payment method for your campaign.",
          "subtitle2": "Choose another payment method in case of insufficient funds in your wallet.",
          "message": "Choose another payment method in case of insufficient funds in your wallet.",
          "update": "Charge my wallet first",
          "loading": "Just a sec...",
          "cards": {
            "title": "Ending in ••••",
            "subtitle": "Expires {month}/{year}",
            "default": "Make default",
            "defaultCard": "Default",
            "current": "Current",
            "newCard": "Add new card"
          },
          "tooltip": "If you want to avoid a credit card fee, please fund your wallet with ACH/Wire transfer."
        },
        "cardModal": "Add a credit/debit card",
        "replaceModal": {
          "title": "Replace a credit/debit card",
          "legend": "The credit card {0} will be replaced by the card provided below. All campaigns currently using the old card will start using the new card immediately."
        },
        "payForm": {
          "delivery": "Estimated content delivery",
          "cost": "Cost per job",
          "costButton": "Details",
          "creators": "Number of creators needed",
          "influencers": "Number of influencers needed",
          "verify": "Verification payment",
          "expected": "Expected budget",
          "loading": "Payment is being processed",
          "success": "Your campaign {0} has been submitted for approval",
          "text": "If approved it will go live within 24-48 hrs.",
          "error": {
            "title": "Failed to process payment",
            "notify": "Please come back to modify the information.",
            "notifyCard": "Please try a different payment method"
          },
          "buttons": {
            "change": "Change payment method",
            "backModify": "Change information",
            "backHome": "Back to home page",
            "campaigns": "My campaigns",
            "anotherCampaign": "Create another campaign",
            "backDashboard": "Back to dashboard"
          }
        },
        "contextMenu": {
          "default": "Make default",
          "edit": "Edit",
          "delete": "Delete",
          "replace": "Replace",
          "retry": "Retry"
        },
        "deletePaymentModal": {
          "meta": "Delete payment method",
          "title": "Delete payment method",
          "description": "Are you sure you want to delete the selected payment method?"
        },
        "retryPaymentModal": {
          "title": "Retry this credit card",
          "description": {
            "1": "We were unable to process your transaction because your credit card issuer declined the charge. This often occurs when the bank has concerns about the frequency of transactions with JoinBrands.",
            "2": "To resolve this issue, please contact your bank to authorize charges from JoinBrands. After ensuring that charges will be allowed, click the \"Retry\" button below to attempt the transaction again.",
            "3": "Should the issue persist, consider adding a larger sum to your JoinBrands wallet or opting for a wire transfer as an alternative payment method."
          },
          "confirm": "Retry"
        },
        "flash": {
          "paymentDeleted": "The payment method has been deleted."
        }
      },
      "created": {
        "meta": "Creating campaign..."
      },
      "cancel": {
        "meta": "Cancel",
        "modal": {
          "title": "Cancel campaign",
          "description": "Are you sure you want to cancel this campaign? The information provided during the creation flow will be lost."
        },
        "h1": {
          "canceling": "Canceling the campaign...",
          "canceled": "The campaign has been discarded successfully!"
        },
        "p": {
          "canceling": "Please hold while the information is being discarded.",
          "canceled": "The campaign creation has been canceled. You can create a new campaign at any moment."
        }
      },
      "information": {
        "meta": "Campaign information",
        "timer": {
          "left": "{text} left"
        },
        "jobs": {
          "datatable": {
            "columns": {
              "creator": "Creator",
              "date": "Date",
              "messages": "Messages",
              "noData": "No creators yet.",
              "legend": "job | jobs"
            },
            "noData": "No jobs yet.",
            "legend": "job | jobs",
            "banner": {
              "title": "It's a bit quiet in here!",
              "subtitle": "All the creators who took your jobs will show up here. Meanwhile, you can invite more creators to work with you!",
              "subtitle2": "All the creators who took your jobs will show up here."
            },
            "menu": {
              "goToJob": "Go to job",
              "extraContent": "Get extra content",
              "addTip": "Add tips",
              "leaveReview": "Leave a review",
              "addTracking": "Add tracking #",
              "editTracking": "Edit tracking #",
              "checkPost": "Check the post",
              "cancelJob": "Cancel job",
              "rehire": "Hire again",
              "extendDeadline": "Extend deadline"
            }
          }
        },
        "buttons": {
          "addTrackingNumber": "Add tracking #",
          "goToJob": "Go to job",
          "pickContent": "Pick content",
          "extraContent": "Extra content",
          "refundDelivery": "Refund delivery",
          "cancelJob": "Cancel job",
          "editTrackingNumber": "Edit tracking number",
          "approve": "Approve",
          "closeCampaign": "Close campaign",
          "openChat": "Chat",
          "try": "Try"
        },
        "deliveryNote": {
          "1": "You have to send the product to the creator",
          "2": "The creator buys the product, you refund the cost"
        },
        "productInfo": {
          "deliveryType": {
            "1": "Shipped by me",
            "2": "Reimbursement"
          },
          "video": "Product demo video",
          "image": "Lifestyle shots",
          "price": "Price per job",
          "from": "From {price}",
          "tooltip": {
            "tiktok": "Final price may vary depending on the creator's TikTok account popularity"
          },
          "statusModal": {
            "pause": "Pause campaign",
            "unpause": "Unpause campaign",
            "pauseBody": "By pausing the campaign, new creators will not be able to see your job. The creators who have already begun the job will continue to work.",
            "unpauseBody": "If you unpause the campaign, new creators will be able to see your work. The creators who have already begun to work on this project will continue its implementation."
          },
          "flash": {
            "success": {
              "paused": "The campaign has been paused.",
              "unpause": "The campaign is back online."
            }
          }
        },
        "modal": {
          "editTrackingNumber": {
            "title": "Enter tracking number",
            "editTitle": "Edit tracking number",
            "newCarrierName": "Add the new carrier name",
            "shippingCarrier": "Shipping carrier",
            "trackingNumber": {
              "label": "Tracking number",
              "placeholder": "Enter the tracking number"
            },
            "placeholderShippingCarrier": "Select a shipping carrier"
          },
          "cancelJob": {
            "title": "Cancel job",
            "description": "Are you sure you want to cancel this job? The information provided during the creation flow will be lost."
          }
        },
        "form": {
          "validation": {
            "alphanumeric": "Only alphanumeric characters are allowed.",
            "trackingNumber": {
              "max": "The tracking number must be at most 100 characters.",
              "min": "The tracking number must have at least 3 characters.",
              "required": "The tracking number is required."
            },
            "carrierName": {
              "max": "The shipping carrier must be at most 30 characters.",
              "min": "The shipping carrier name must have at least 3 characters.",
              "required": "The shipping carrier name is required."
            }
          },
          "inputs": {
            "select": {
              "active": "Active",
              "placeholder": "All statuses",
              "buyProduct": "Pending purchase",
              "pendingShipment": "Pending shipping",
              "productSent": "Pending delivery",
              "contentCreation": "Content creation",
              "contentReady": "Content ready",
              "contentAccepted": "Content approved",
              "contentPublished": "Content posted",
              "canceled": "Canceled",
              "completed": "Completed",
              "shipped": "Shipped",
              "delivered": "Delivered",
              "closed": "Closed",
              "videoPosted": "Video posted"
            }
          }
        },
        "creator": {
          "getFor": "Get for {amount}"
        },
        "creators": {
          "noCreators": {
            "title": "Someone is coming...",
            "subtitle": "Watch this space, and creators will come at any moment. Till then, go find some fresh talents!"
          },
          "noApplicants": {
            "title": "Looks empty here",
            "subtitle": "Looks like creators haven't applied to your jobs yet. Go find them yourself on the marketplace!"
          },
          "noInvited": {
            "title": "Time to start inviting creators",
            "subtitle": "You haven't invited any creators yet. Check the Applicants tab or visit our creator's marketplace to invite some!"
          }
        },
        "content": {
          "noContent": "No content yet.",
          "filterContent": {
            "all": "All content",
            "awaiting": "Awaiting approval",
            "purchased": "Purchased",
            "extra": "Extra content"
          },
          "buttons": {
            "extra": "Get for {price}"
          },
          "paginationLegend": "content",
          "banner": {
            "title": "Stay tuned",
            "subtitle": "Your content is heading your way! Till then, go find some fresh talents out there!",
            "subtitle2": "Your content is heading your way!"
          }
        },
        "details": {
          "name": "Campaign name",
          "campaignType": {
            "1": "Product delivery",
            "2": "Product reimbursement",
            "title": "Campaign type"
          },
          "orientation": {
            "video": {
              "portrait": "Portrait video",
              "landscape": "Landscape video",
              "square": "Square video",
              "any": "Any video"
            },
            "image": {
              "portrait": "Portrait image",
              "landscape": "Landscape image",
              "square": "Square image",
              "any": "Any image"
            },
            "note": "(portrait, landscape or square)"
          },
          "product": "Product",
          "productLink": "Product link",
          "tiktokShopLink": "TikTok Shop link",
          "tiktokShopProduct": "TikTok Shop product",
          "requiredContent": "Required content",
          "examples": "Show examples",
          "vertical": "Vertical video",
          "duration": "Video duration: {time} sec",
          "description": "Content brief",
          "brand": "Brand",
          "paymentMethod": "Payment method",
          "last4": "{brand} ending in {last4}",
          "changePaymentMethod": "Change payment method",
          "creatorCriteria": {
            "title": "Creator criteria",
            "creatorLevel": "Creator level: {level} or higher",
            "gender": "Gender:",
            "age": "Age:",
            "ethnicity": "Ethnicity:"
          },
          "findCreators": "Find creators",
          "specialRequirements": {
            "title": "Special requirements"
          },
          "affiliateCommission": "Affiliate commission",
          "affiliateCommissionLabel": "Affiliate commission {amount}%"
        },
        "editTrackingNumber": {
          "amountMustBe": "The refund amount must be {amount}",
          "amountMessage": "Your current refund amount is {amount}"
        },
        "campaignStatus": {
          "created": "Pending approval",
          "rejected": "Declined",
          "active": "Active",
          "completed": "Completed",
          "close": "Closed",
          "paused": "Paused",
          "pendingEditApproval": "Pending approval edition"
        },
        "tabsName": {
          "creators": "Creators",
          "content": "Content",
          "delivery": "Delivery",
          "campaignDetails": "Campaign details",
          "costBreakdown": "Cost breakdown"
        },
        "costBreakdown": {
          "costsOfCampaign": {
            "title": "Campaign",
            "pricePerImage": "Price per image",
            "pricePerVideo": "Price per video",
            "productCostReimbursement": "Product cost reimbursement",
            "pricePerJob": "Price per job",
            "numberOfJobs": "Number of jobs",
            "x": "× {quantity}",
            "subtotalCostOfCampaign": "Subtotal cost of campaign",
            "platformFee": "Platform fee",
            "subtotalCampaignCost": "Subtotal campaign cost",
            "refunds": "Refunds"
          },
          "costsAdditionalExpenses": {
            "title": "Additional expenses",
            "averageCostPerExtraImage": "Average cost per extra image",
            "averageCostPerExtraVideo": "Average cost per extra video",
            "numberOfImages": "Number of images",
            "numberOfVideos": "Number of videos",
            "x": "× {quantity}",
            "subtotalExtraContentCost": "Subtotal extra content cost",
            "tips": "Tips",
            "subtotal": "Subtotal",
            "platformFee": "Platform fee",
            "additionalExpenses": "Additional expenses",
            "refunds": "Refunds",
            "tiktokAdBudget": "TikTok ad budget",
            "tiktokAdFee": "TikTok ad fee",
            "tiktokAdRefund": "TikTok ad refunds"
          },
          "total": "Total"
        }
      },
      "campaigns": {
        "meta": {
          "title": "Campaigns"
        },
        "title": "My campaigns",
        "tabs": {
          "all": "All ({count})",
          "created": "Pending approval ({count})",
          "active": "Active ({count})",
          "paused": "Paused ({count})",
          "rejected": "Declined ({count})",
          "completed": "Completed ({count})",
          "pendingEditApproval": "Pending edition ({count})"
        },
        "datatable": {
          "campaign": "Campaign",
          "legend": "campaign | campaigns",
          "certification": "Certification",
          "creators": "Creators",
          "jobsStats": "Jobs actions",
          "notFound": "No campaigns found.",
          "stats": {
            "available": "Available: ",
            "progress": "In progress: ",
            "completed": "Completed: "
          },
          "actions": {
            "send": "Send product: ",
            "approve": "Approve content: ",
            "finished": "Finished: ",
            "cancelled": "Cancelled: ",
            "bought": "Content bought: ",
            "received": "Product received: ",
            "accepted": "Job accepted: ",
            "approved": "Content approved: "
          },
          "status": {
            "active": "Active",
            "paused": "Paused",
            "created": "Pending approval",
            "rejected": "Declined",
            "completed": "Completed",
            "pending_edit_approval": "Pending approval edition"
          }
        },
        "edition": {
          "sidebarTitle": "Edit campaign",
          "importantNotice": "Before you edit the campaign, please note that it will be paused until our team reviews and approves or denies your changes. Jobs already assigned to creators and job invites sent will remain unchanged.",
          "cancel": "Cancel",
          "submit": "Submit",
          "modal": {
            "title": "Changes under review",
            "message": "Your changes have been submitted. Our team will review them and either approve or deny the request. Please note that any jobs already assigned to creators, as well as any job invites sent to creators, will remain unchanged, even if your changes are approved. In the meantime, the campaign will be paused until your changes are reviewed and a decision is made."
          },
          "openEditionButton": "Edit campaign"
        },
        "filters": {
          "sort": {
            "earning": "Earning",
            "newest": "Newest"
          },
          "sidebar": {
            "title": "Filters",
            "label": {
              "socialType": "Social type",
              "imageType": "Image type",
              "videoType": "Video type",
              "deliveryType": "Delivery type",
              "clear": "Clear",
              "seeAll": "See all"
            },
            "deliveryType": {
              "1": "Free product",
              "2": "Reimbursement"
            }
          },
          "form": {
            "input": {
              "search_phrase": {
                "validation": {
                  "min": "The search term must contain at least 3 characters.",
                  "max": "The search term must be lower than 64 characters.",
                  "required": "The search term is required."
                }
              }
            }
          }
        },
        "button": {
          "details": "See details",
          "filters": "Filters",
          "showMore": "Show more",
          "hideMore": "Show less"
        },
        "select": {
          "sort": "Sort by",
          "brand": {
            "label": "Brand",
            "placeholder": "All brands"
          }
        }
      },
      "digitalProduct": {
        "title": "Digital product",
        "legend": "Online service without delivery",
        "subtitle": "How to access the digital product?",
        "link": "Link to access your digital product {0}",
        "visible": "(only visible to approved creators)",
        "access": "Product access instructions",
        "login": "Login: {user}",
        "password": "Password: {password}"
      },
      "marketplace": {
        "meta": "Campaign Creator Marketplace",
        "recurring": {
          "meta": "Recurring Campaign Marketplace"
        }
      }
    },
    "brand": {
      "creation": {
        "addBrand": "Add brand",
        "firstBrand": "Add your first brand",
        "tipMessage": "Get started by creating your first brand and then proceed to create your first campaign.",
        "form": {
          "input": {
            "name": {
              "label": "Brand name",
              "placeholder": "Brand name",
              "validation": {
                "required": "The brand name is required."
              }
            },
            "website": {
              "label": "Website (optional)",
              "placeholder": "https://example.com",
              "validation": {
                "format": "The website URL is invalid."
              }
            },
            "industry": {
              "label": "Industry",
              "placeholder": "Select an industry",
              "validation": {
                "required": "The industry is required."
              }
            },
            "description": {
              "label": "Description (optional)",
              "placeholder": "Describe your brand",
              "legend": "Help our team get a better sense of who you are as a Brand. What product do you sell? What makes it unique? Who is your target audience?",
              "validation": {
                "required": "The brand description is required."
              },
              "characters": "Characters: {currentCharacters} / {characters}"
            }
          },
          "buttonAddBrand": "Add brand"
        }
      },
      "marketplaces": {
        "us": "United States brands",
        "ca": "Canada brands",
        "uk": "United kingdom brands",
        "au": "Australia brands"
      }
    },
    "onboarding": {
      "companyInformation": {
        "meta": "Company information",
        "step": "Company information",
        "title": "Enter your company information",
        "text": "Enter your main brand’s information. If you want to create listings under multiple brands, you can do so later.",
        "form": {
          "input": {
            "brand": {
              "label": "Brand name",
              "placeholder": "The official name of your brand",
              "validation": {
                "required": "The brand name is required."
              }
            },
            "marketplace": {
              "validation": {
                "required": "You must select at least one country"
              }
            }
          },
          "button": "Continue"
        },
        "legend": {
          "termsAndPrivacy": {
            "text": "{0} ({1} {2} {3})",
            "textFirstPart": "By submitting your phone number you consent to receive marketing TEXT messages (e.g. promos or campaign reminders) from JoinBrands. Consent is not a condition of purchase. Message & data rates may apply. Message frequency varies. Unsubscribe at any time by replying STOP or clicking the unsubscribe link (where available).",
            "textSecondPart": "Terms & Conditions",
            "textThirdPart": "and",
            "textFourthPart": "Privacy Policy"
          }
        },
        "marketplace": {
          "title": "Select the countries you work for",
          "legend": "You can create representative offices for several countries to reach even more audiences",
          "placeholder": "Countries",
          "selectOption": "Select country"
        }
      }
    },
    "product": {
      "input": {
        "image": {
          "validation": {
            "required": "The product image is required."
          }
        },
        "name": {
          "label": "Name (visible to creators)",
          "placeholder": "The name of the product",
          "validation": {
            "min": "The name must have at least 3 characters."
          }
        },
        "price": {
          "digitalLabel": "Price (optional)",
          "physicalLabel": "Price",
          "validation": {
            "digitalMin": "The price must be greater than or equal to 0.",
            "min": "The price must be greater than or equal to 1 and with only 2 decimals.",
            "max": "The price must be less than or equal to 100000.",
            "format": "The price must be greater than or equal to 1 and with only 2 decimals."
          }
        },
        "link": {
          "validation": {
            "min": "The product link must have at least 8 characters.",
            "max": "The product link should not exceed {max} characters.",
            "url": "The product link is invalid.",
            "httpProtocol": "Your link should start with http:// or https://.",
            "required": "The product link is required."
          }
        },
        "physicalProductLink": {
          "label": "External link",
          "placeholder": "https://marketplace.com/product"
        },
        "digitalProductLink": {
          "label": "External link",
          "placeholder": "https://shop.com/product"
        },
        "digitalProductAccess": {
          "label": "How to access to your digital product? (optional & only visible to approved creators)",
          "placeholder": "Sign up instructions, download links, user name, password, or access code.",
          "validation": {
            "max": "The maximum number of characters allowed is 1200."
          }
        },
        "maxShippingTime": {
          "label": "Max. shipping time (days)",
          "validation": {
            "min": "The maximum shipping time should be greater or equal to 7 days.",
            "max": "The maximum shipping time must be at most {days} days."
          }
        },
        "maxHandlingTime": {
          "label": "Max. handling time (days)",
          "validation": {
            "min": "The minimum handling time should be greater or equal to 1 day.",
            "max": "The maximum handling time must be at most {days} days."
          }
        }
      },
      "select": {
        "industry": {
          "label": "Industry",
          "placeholder": "Select an industry",
          "validation": {
            "required": "The industry is required."
          }
        },
        "brand": {
          "label": "Brand",
          "placeholder": "Select a brand",
          "validation": {
            "required": "The brand is required."
          }
        }
      },
      "products": {
        "meta": {
          "title": "Products"
        },
        "title": "My products",
        "datatable": {
          "product": "Product",
          "legend": "product | products",
          "noRecords": "No products yet.",
          "brand": "Brand",
          "content": "My content",
          "postedVideos": "Posted videos",
          "socialPosts": "Social posts",
          "extraContent": "Extra content",
          "stats": {
            "active": "Active campaigns: {count}"
          },
          "dropdown": {
            "details": "See details",
            "edit": "Edit product",
            "duplicate": "Duplicate product",
            "delete": "Delete product",
            "archive": "Archive product"
          },
          "contentType": {
            "image": " image",
            "video": " video"
          },
          "buttons": {
            "download": "Downloading...",
            "getFor": "Get for {amount}"
          }
        },
        "filters": {
          "sort": {
            "status": "Status",
            "newest": "Newest",
            "oldest": "Oldest"
          },
          "content": {
            "image": "Only images",
            "video": "Only videos"
          },
          "form": {
            "input": {
              "search_phrase": {
                "validation": {
                  "min": "The search term must contain at least 3 characters.",
                  "max": "The search term must be lower than 64 characters.",
                  "required": "The search term is required."
                }
              }
            }
          }
        },
        "button": {
          "details": "See details",
          "new": "Add new product",
          "create": "Create campaign"
        },
        "select": {
          "sort": "Sort by",
          "brand": {
            "label": "Brand",
            "placeholder": "All brands"
          },
          "contentType": {
            "label": "Content type",
            "placeholder": "All content types"
          }
        },
        "details": {
          "active": "Active campaigns",
          "paused": "Paused campaigns",
          "completed": "Completed campaigns",
          "information": {
            "label": {
              "shipping": "Max. shipping time: {days}",
              "handling": "Max. handling time: {days}"
            }
          },
          "tabs": {
            "content": {
              "mine": "My content ({count})",
              "extra": "Extra content ({count})",
              "pagination": "content",
              "noRecords": {
                "jobType": "Nothing was found for {jobType}.",
                "mediaType": "No {mediaType} found.",
                "all": "Nothing was found."
              }
            },
            "socialPosts": {
              "title": "Social posts ({count})"
            }
          }
        },
        "flash": {
          "created": "The product was successfully created.",
          "edited": "The product was successfully edited.",
          "duplicated": "The product was successfully duplicated.",
          "deleted": "The product was successfully deleted."
        },
        "modal": {
          "delete": {
            "title": "Delete the product?",
            "description": "Are you sure you want to delete the product? By doing so, all the information related to the product will be lost."
          },
          "duplicate": {
            "title": "Duplicate the product?",
            "description": "Are you sure you want to duplicate this product?"
          }
        }
      }
    },
    "job": {
      "closeCreatorModal": {
        "title": "Close the job",
        "description": "Are you sure you want to close the job? By doing so, the creator won't be able to upload additional content anymore.",
        "success": "The job has been successfully closed."
      },
      "deadLine": {
        "years": "Years",
        "months": "Months",
        "days": "Days",
        "hours": "Hours",
        "minutes": "Minutes",
        "seconds": "Seconds"
      },
      "cancelJob": "Cancel this job",
      "extendDeadline": {
        "button": "Extend deadline",
        "flash": {
          "success": "The deadline has been successfully extended."
        }
      },
      "statusTitle": "Job status",
      "tabs": {
        "delivery": "Delivery info",
        "info": "Job info",
        "content": "Content",
        "influencerInfo": {
          "label": "Influencer info",
          "stats": {
            "main": {
              "followers": "{0} followers",
              "country": "Country or region {0}",
              "gender": {
                "label": "Gender {0}"
              },
              "age": {
                "label": "Age {0}",
                "range": {
                  "1": "18 - 24",
                  "2": "25 - 34",
                  "3": "35+"
                }
              }
            },
            "other": {
              "averageViews": {
                "label": "Average views"
              },
              "engagementRate": {
                "label": "Engagement rate"
              },
              "completionRate": {
                "label": "Completion rate"
              },
              "followersGrowthRate": {
                "label": "Followers growth rate"
              },
              "averageShares": {
                "label": "Average shares"
              },
              "averageLikes": {
                "label": "Average likes"
              },
              "averageComments": {
                "label": "Average comments"
              }
            }
          },
          "chart": {
            "gender": {
              "h3": "Gender",
              "legend": "{gender} {percentage}%"
            },
            "age": {
              "h3": "Age"
            }
          }
        },
        "chat": "Chat",
        "postedVideos": "Posted videos"
      },
      "deliveryInfo": {
        "firstName": "First name:",
        "lastName": "Last name:",
        "street": "Address line 1:",
        "street2": "Address line 2:",
        "city": "City:",
        "state": "State:",
        "zipCode": "ZIP code:",
        "buttonTitle": "Copy full address",
        "shippingProvider": "Shipping provider:",
        "trackingNumber": "Tracking number:"
      },
      "jobInfo": {
        "review": "Job review",
        "name": "Campaign name",
        "campaignType": {
          "1": "Product delivery",
          "2": "Product reimbursement",
          "title": "Campaign type"
        },
        "product": "Product",
        "productLink": "Product link",
        "requiredContent": "Required content",
        "examples": "Show examples",
        "vertical": "Vertical video",
        "duration": "Video duration: {time} sec",
        "description": "Description",
        "orientation": {
          "video": {
            "portrait": "Portrait video",
            "landscape": "Landscape video",
            "square": "Square video",
            "any": "Any video"
          },
          "image": {
            "portrait": "Portrait image",
            "landscape": "Landscape image",
            "square": "Square image",
            "any": "Any image"
          },
          "note": "(single {contentType} or more)"
        },
        "button": {
          "review": "Leave review"
        },
        "modal": {
          "firstTitle": "How did {creator} do?",
          "secondTitle": "Creator review",
          "submit": "Submit review",
          "label": {
            "comment": "Anything else you want to share with us? (optional)",
            "rating": "Rating"
          },
          "placeholder": {
            "comment": "Share some feedback about this creator!"
          },
          "helper": "Characters: {stringLength} / 255"
        },
        "productOrderID": "Product order ID",
        "noProductOrderID": "The creator has not provided an order number yet."
      },
      "sideBar": {
        "title": {
          "sendProduct": "Left to send product"
        },
        "moreJobs": {
          "label": {
            "creators": "Do you want to get more jobs from different creators?",
            "influencers": "Want more posts from different influencers?"
          },
          "button": "Add more jobs",
          "modal": {
            "title": "Add more jobs",
            "message": "You have {count} approved creator and no jobs available. You can add more jobs to get more content. | You have {count} approved creators and no jobs available. You can add more jobs to get more content.",
            "label": "The more jobs you have, the more content you get.",
            "label2": "You can add more jobs to work with more creators!",
            "jobsAvailable": "Currently available jobs:",
            "jobsTaken": "Jobs taken:",
            "currentAvailableJobs": {
              "message": "{0} {1}",
              "messageFirstPart": "Currently available jobs:",
              "messageSecondPart": "{available} of {current}"
            },
            "tip": {
              "message": "{0} {1} {2}",
              "1": "You will not be able to reduce the number of created jobs.",
              "2": "Please note",
              "3": "that once a job is added, this action cannot be reversed."
            },
            "submit": "Add {count} job | Add {count} jobs",
            "validation": {
              "required": "The number of jobs is required.",
              "min": "The minimum number of jobs required is {min}.",
              "max": "The maximum number of jobs allowed is {max}."
            }
          }
        }
      },
      "jobTimeLine": {
        "title": "Job timeline",
        "status": {
          "creator_accepted": "Job started",
          "purchased_sent": {
            "reimbursement": "Product purchased",
            "shipIt": "Product sent"
          },
          "product_received": "Product delivered",
          "content_delivered": "Content uploaded",
          "canceled": "Job canceled",
          "finished": "Job completed",
          "completed": "Job completed",
          "underReview": "Under review",
          "content_accepted": "Content accepted",
          "creator_waiting_for_publish_date": "Video posted"
        }
      },
      "jobStatusTime": {
        "status": {
          "creator_accepted": {
            "shipIt": {
              "late": "Late to send product",
              "left": "Left to send product"
            },
            "reimbursement": {
              "late": "Late to order the product",
              "left": "Left to order the product"
            }
          },
          "purchased_sent": {
            "arriving": "Arriving by",
            "awaiting": "Awaiting",
            "shipIt": {
              "late": "Late to send product"
            },
            "reimbursement": {
              "late": "Late to receive product"
            }
          },
          "product_received": {
            "late": "Late to get content",
            "left": "Left to get content"
          },
          "content_delivered": "Job status",
          "content_admin_approved": {
            "late": "Late to accept content",
            "left": "Left to accept content"
          },
          "content_accepted": {
            "late": "Late to post video",
            "left": "Left to post video"
          },
          "completed": "Job status",
          "finished": "Job status",
          "canceled": "Job status",
          "activeContent": "Post must be active for",
          "creator_waiting_for_publish_date": "Post must be active for"
        },
        "showFirst": "Show First",
        "trackingButton": "Enter tracking number",
        "closeCreatorButton": {
          "label": "Close for this creator",
          "loading": "Closing Job..."
        },
        "timer": {
          "minutes": "Minutes",
          "hours": "Hours",
          "days": "Days"
        },
        "default": {
          "firstCounter": "00",
          "secondCounter": "00",
          "firstCounterLabel": "days",
          "secondCounterLabel": "hours"
        }
      },
      "jobBalanceSheet": {
        "pricePerJob": "Price per job",
        "extraContent": "Extra { type }",
        "tipsCreator": "Tips amount",
        "reimbursements": "Reimbursements",
        "platformFee": "Platform Fee",
        "totalPayment": "Total payment for this job"
      },
      "jobStatus": {
        "creator_accepted": "Job accepted",
        "purchased_sent": {
          "1": "Product sent",
          "2": "Product purchased"
        },
        "product_received": "Product received",
        "content_delivered": "Under review",
        "canceled": "Canceled",
        "completed": "Completed",
        "finished": "Closed",
        "content_admin_approved": {
          "late": "Late to accept content",
          "left": "Left to accept content"
        }
      },
      "content": {
        "noUploaded": {
          "status": {
            "creator_accepted": {
              "1": {
                "highlight": "Please send your product and provide a tracking number!",
                "informativeNote": "The creator will start creating content only after receiving your product."
              },
              "2": {
                "highlight": "The creator should order your product soon and provide their order ID!",
                "informativeNote": "The creator will start creating content only after receiving your product."
              }
            },
            "purchased_sent": {
              "1": {
                "highlight": "Your product is on the way.",
                "informativeNote": "The creator will start creating content only after receiving your product."
              },
              "2": {
                "highlight": "The creator ordered your product and provided an order ID.",
                "informativeNote": "The creator will start creating content only after receiving your product."
              }
            },
            "product_received": {
              "highlight": "Your content is being created.",
              "informativeNote": "Please wait for the content to be delivered."
            },
            "content_delivered": {
              "highlight": "The content is pending approval.",
              "informativeNote": "Please wait for the content to be approved."
            },
            "canceled": {
              "highlight": "The job has been canceled.",
              "informativeNote": "You won't be able to get content for that job."
            },
            "rejected_content": {
              "highlight": "You have rejected all content.",
              "informativeNote": "Please wait for the creators to upload new content."
            }
          },
          "message": "Nothing has been uploaded yet."
        },
        "uploaded": {
          "download": "Download",
          "expireMessage": "Kindly review and approve any of the following content pieces. In case you choose not to approve or reject all, the system will automatically grant approval to one of the content pieces on {date}."
        },
        "approveModal": {
          "button": "Approve",
          "title": "Approve this content",
          "message": "This content will be considered your final choice and the job will be completed.",
          "tiktokMessage": "This video will be considered as your final choice and will be posted on TikTok.",
          "continue": "Continue",
          "try": "Try",
          "buyFor": "Buy for"
        },
        "picked": {
          "pickMore": {
            "highlight": "Pick more — pay less!",
            "note": "Get {percent}% off any extra image you purchase! "
          }
        },
        "showFirst": "Show first",
        "purchaseContent": "Purchased content",
        "extra": "Get extra content",
        "modalPreview": {
          "title": "Purchased preview"
        },
        "rejectAll": "Reject all",
        "shareContent": "Share content",
        "rejectAllModal": {
          "title": "Reject the content from the creator",
          "tip": {
            "title": "You're about to reject the content provided by the creator",
            "description": "We will notify the creator your reasons so the content can be created as you wish."
          },
          "textarea": {
            "label": "Explanations/reasons to reject the content",
            "placeholder": "Please explain why you decided to reject the content. Keep in mind your explanation will be provided to the creator,  so please be polite and professional.",
            "characters": "Characters {stringLength}/{max}",
            "validation": {
              "max": "The explanations can't have more than {max} characters.",
              "min": "The explanations can't have less than {min} characters."
            }
          },
          "buttons": {
            "cancel": "Cancel",
            "rejectAll": "Reject all"
          }
        },
        "shareContentModal": {
          "title": "Share content",
          "h2": "Your link to the content is ready",
          "h3": "Send the link for approval to your client",
          "linkCopied": "The link has been copied to the clipboard.",
          "buttons": {
            "close": "Close",
            "copyLink": "Copy link"
          }
        },
        "accepted": {
          "title": "Accepted content",
          "badge": "Accepted"
        },
        "rejected": {
          "title": "Rejected content",
          "badge": "Rejected"
        },
        "favored": {
          "title": "Favored content",
          "badge": "Favored"
        },
        "queued": {
          "message": "This video is being processed, please wait until we finish."
        }
      },
      "upsell": {
        "meta": "Job upsell",
        "title": "You can also select more content",
        "extra": "Extra {type}",
        "creatorTip": "Tips for creator",
        "platformFee": "Platform fee",
        "totalPayment": "Total payment for this job",
        "totalPaymentForExtraContent": "Total for extra content",
        "continueButton": "Continue",
        "purchasedContent": {
          "title": "Your content"
        },
        "extraContent": {
          "title": "Buy more content for less!"
        },
        "creatorTipsModal": {
          "title": "Add a tip for {creator}",
          "titleSubtotal": "Subtotal",
          "payment": "Payment",
          "extraContent": "Extra content",
          "maxAmountTips": "Max. {amount}",
          "totalPayment": "Total payment including tips",
          "platformFee": "Platform fee",
          "other": "Other",
          "errorValidation": {
            "number": "The value must be a number.",
            "min": "The minimum tip amount is {minTip}",
            "max": "The maximum tip amount allowed is {maxTip}"
          },
          "buttons": {
            "cancel": "Cancel",
            "close": "Close"
          },
          "success": {
            "title": "Payment successful!",
            "message": "Your payment was successfully completed."
          }
        },
        "upsellAction": {
          "contentSelected": {
            "image": "{count} image selected | {count} images selected",
            "video": "{count} video selected | {count} videos selected"
          }
        },
        "upsellValidation": {
          "error": {
            "messageError": "Failed to access upsell",
            "descriptionError": "You must first select a content, before entering upsell."
          },
          "buttons": {
            "backToJob": "Back to Job"
          }
        }
      },
      "upsellPayment": {
        "meta": "Job upsell payment",
        "title": "Payment for {name}",
        "button": "Make a payment",
        "cancelModal": {
          "title": "Cancel job upsell",
          "description": "Are you sure you want to cancel this job upsell? The information provided will be lost."
        }
      },
      "upsellCompleted": {
        "meta": "Job upsell completed",
        "messages": {
          "success": {
            "title": "Payment successful!",
            "subtitle": "Your payment was successfully completed.",
            "button": "Back to job"
          },
          "error": {
            "title": "Your payment failed!",
            "subtitle": "We couldn't process your payment. Please try again."
          }
        },
        "button": "Update payment method"
      },
      "postedVideos": {
        "labels": {
          "approved": "Video approved",
          "posted": "Posted video",
          "required": "Required posting",
          "link": "Link to video",
          "code": "Spark code",
          "copy": "Your spark code has been copied",
          "waiting": "Waiting for posting",
          "seePost": "See post",
          "views": "Views",
          "comments": "Comments",
          "postedOn": "Posted on",
          "menu": {
            "jobDetails": "See job details",
            "chat": "Chat with creator",
            "download": "Download video"
          }
        },
        "tips": {
          "contentAccepted": {
            "tiktok": {
              "title": "Awaiting posting",
              "message": "The creator has 2 days to post the video on TikTok. You'll get notified when the post is active, and have 7 days to check the video on TikTok."
            },
            "instagram": {
              "title": "Awaiting posting",
              "message": "The creator has 2 days to post the video on Instagram. You'll get notified when the post is active, and have 7 days to check the video on Instagram."
            },
            "youtube": {
              "title": "Awaiting posting",
              "message": "The creator has 2 days to post the video on YouTube. You'll get notified when the post is active, and have 7 days to check the video on YouTube."
            },
            "amazon": {
              "title": "Awaiting posting",
              "message": "The creator has 2 days to post the video on Amazon. You'll get notified when the post is active, and have 7 days to check the video on Amazon."
            },
            "tiktok_shop": {
              "title": "Awaiting posting",
              "message": "The creator has 2 days to post the video on TikTok. You'll get notified when the post is active, and have 7 days to check the video on TikTok."
            }
          },
          "waitingPublished": {
            "tiktok": {
              "title": "Check the post on TikTok",
              "message": "You can verify the content within 7 days after the posted date. Chat with the creator or support if you face any problems."
            },
            "instagram": {
              "title": "Check the post on Instagram",
              "message": "You can verify the content within 7 days after the posted date. Chat with the creator or support if you face any problems."
            },
            "youtube": {
              "title": "Check the post on YouTube",
              "message": "You can verify the content within 7 days after the posted date. Chat with the creator or support if you face any problems."
            },
            "amazon": {
              "title": "Check the post on Amazon",
              "message": "You can verify the content within 7 days after the posted date. Chat with the creator or support if you face any problems."
            },
            "tiktok_shop": {
              "title": "Check the post on TikTok",
              "message": "You can verify the content within 7 days after the posted date. Chat with the creator or support if you face any problems."
            }
          }
        }
      },
      "jobs": {
        "meta": {
          "title": "Jobs"
        },
        "title": "My jobs",
        "tabs": {
          "active": "Active",
          "completed": "Completed"
        },
        "datatable": {
          "legend": "job | jobs",
          "columns": {
            "creatorName": "Name",
            "status": "Status",
            "date": "Date",
            "messages": "Messages",
            "actions": "Actions"
          }
        },
        "searchNoMatch": {
          "title": "No jobs found",
          "legend": "Sorry, but nothing matched your search parameters. Please change your filtering selections and try again."
        },
        "empty": {
          "title": "No active jobs",
          "legend": "You do not have any jobs in progress. Please create a new campaign and invite creators."
        },
        "form": {
          "filtersDialog": {
            "label": "Filters",
            "brandMultiselect": {
              "label": "Brand",
              "selected": "{nb} brands",
              "all": "All brands"
            },
            "campaignMultiselect": {
              "label": "Campaign",
              "selected": "{nb} selected",
              "all": "All campaigns"
            },
            "contentTypeMultiselect": {
              "label": "Content type",
              "selected": "{nb} selected",
              "all": "All content"
            },
            "deliveryTypeMultiselect": {
              "label": "Delivery type",
              "selected": "{nb} selected",
              "all": "All deliveries"
            }
          },
          "filterStatus": {
            "placeholder": "All ({total})",
            "creator_accepted": "Pending shipping ({total})",
            "purchased_sent": "Pending delivery ({total})",
            "content_delivered": "Content creation ({total})",
            "content_admin_approved": "Content ready ({total})",
            "content_accepted": "Content approved ({total})",
            "creator_waiting_for_publish_date": "Content posted ({total})",
            "finished": "Closed ({total})",
            "completed": "Completed ({total})",
            "canceled": "Canceled ({total})"
          },
          "sort": {
            "label": "Sort by",
            "newest": "Newest",
            "status": "Status"
          }
        },
        "status": {
          "creatorAccepted": {
            "shipIt": {
              "text": "Pending shipping",
              "tooltip": "You have to send the product to this creator for free. The faster you ship it, the faster you’ll receive your content.",
              "date": {
                "text": "Send the product before:",
                "deadline": "Extend the deadline or chat with the creator. Shipping deadline expired on:",
                "deadlineNoExtending": "Chat with the creator. Shipping deadline expired on:"
              }
            },
            "reimbursement": {
              "text": "Pending purchase",
              "tooltip": "The creator must purchase your product. After the job is done, you'll be charged for the creator's reimbursement.",
              "date": {
                "text": "Creator needs to purchase the product before:",
                "deadline": "Extend the deadline or chat with the creator. Purchase deadline expired on:",
                "deadlineNoExtending": "Chat with the creator. Purchase deadline expired on:"
              }
            }
          },
          "purchasedSent": {
            "shipIt": {
              "text": "Pending delivery",
              "date": {
                "text": "Deliver the product by:",
                "deadline": "Extend the deadline or chat with the creator. Delivery deadline expired on:",
                "deadlineNoExtending": "Chat with the creator. Delivery deadline expired on:"
              }
            },
            "reimbursement": {
              "text": "Product purchased",
              "date": {
                "text": "Delivery estimate:",
                "deadline": "Extend the deadline or chat with the creator. Delivery deadline expired on:",
                "deadlineNoExtending": "Chat with the creator. Delivery deadline expired on:"
              }
            },
            "tooltip": "Your product is on its way to this creator. You will be notified once it is delivered."
          },
          "productReceived": {
            "text": "Content creation",
            "tooltip": {
              "base": "The creator is currently shooting content. Once it is ready, you’ll get to choose your favorites. Stay tuned!",
              "tiktok": "The creator is currently shooting content. Once it is ready, you’ll get to choose the video the creator will post on TikTok or reject it for revisions.",
              "instagram": "The creator is currently shooting content. Once it is ready, you’ll get to choose the video the creator will post on Instagram or reject it for revisions.",
              "youtube": "The creator is currently shooting content. Once it is ready, you’ll get to choose the video the creator will post on YouTube or reject it for revisions.",
              "amazon": "The creator is currently shooting content. Once it is ready, you’ll get to choose the video the creator will post on Amazon or reject it for revisions."
            },
            "date": {
              "text": "Content creation deadline:",
              "deadline": "Extend the deadline or chat with the creator. Content creation deadline expired on:",
              "deadlineNoExtending": "Chat with the creator. Content creation deadline expired on:"
            }
          },
          "contentDelivered": {
            "text": "Content review",
            "tooltip": "The creator has delivered the content. It is under review by our team.",
            "date": {
              "text": "Content is under review by support. It can take a few days."
            }
          },
          "contentAdminApproved": {
            "text": "Content ready",
            "tooltip": {
              "base": "Your content is waiting for you! From all the content the creator has uploaded, select your favorite one and get it.",
              "tiktok": "The content is waiting for you to review! From all the content the creator has uploaded, select the video the creator will post on TikTok.",
              "instagram": "The content is waiting for you to review! From all the content the creator has uploaded, select the video the creator will post on Instagram.",
              "youtube": "The content is waiting for you to review! From all the content the creator has uploaded, select the video the creator will post on YouTube.",
              "amazon": "The content is waiting for you to review! From all the content the creator has uploaded, select the video the creator will post on Amazon."
            },
            "date": {
              "text": "Approve the content before:",
              "deadline": "Content is about to be auto-approved. Approval deadline expired on:"
            }
          },
          "contentAccepted": {
            "text": "Content approved",
            "tooltip": {
              "tiktok": "The content is waiting for the creator to post it on TikTok.",
              "instagram": "The content is waiting for the creator to post on Instagram.",
              "youtube": "The content is waiting for the creator to post on YouTube.",
              "amazon": "The content is waiting for the creator to post on Amazon."
            },
            "date": {
              "text": "Creator needs to post the content before:",
              "deadline": "Tell creator to post the content and submit the link. Content posting deadline expired on:"
            }
          },
          "waitingPublished": {
            "text": "Content posted",
            "tooltip": {
              "tiktok": "The creator posted the video to TikTok. You now have 7 days to check the video online before the creator is paid.",
              "instagram": "The creator posted the video to Instagram. You now have 7 days to check the video online before the creator is paid.",
              "youtube": "The creator posted the video to YouTube. You now have 7 days to check the video online before the creator is paid.",
              "amazon": "The creator posted the video to Amazon. You now have 7 days to check the video online before the creator is paid."
            },
            "date": {
              "text": "Verify if the content is posted before:"
            }
          },
          "completed": {
            "text": "Completed",
            "tooltip": {
              "base": "Now all the licensing rights for the content are yours! Pro Tip: Get extra content for less if creators have uploaded more.",
              "social": "Now all the licensing rights for the content are yours!"
            },
            "date": {
              "text": "The job has been completed on:"
            }
          },
          "finished": {
            "text": "Closed",
            "tooltip": "That's all! All content belongs to you and this job is closed!",
            "date": {
              "text": "The job has been closed on:"
            }
          },
          "canceled": {
            "text": "Canceled",
            "tooltip": "This job has been canceled! If you have any questions regarding this, please contact our customer service.",
            "date": {
              "text": "The job has been cancelled on:"
            }
          }
        },
        "inlinePreviewContent": {
          "button": "Show all"
        },
        "approveContentSidebar": {
          "title": "Pick content"
        }
      }
    },
    "notification": {
      "h1": "Notifications",
      "emptyMessage": {
        "heading": "No notifications yet",
        "description": "When you get notifications, they'll show up here."
      },
      "clearModal": {
        "heading": "Clear all notifications",
        "description": "Are you sure you want to clear all your notifications?"
      },
      "pagination": {
        "showing": "Showing",
        "notifications": "notifications",
        "to": "to",
        "of": "of"
      }
    },
    "settings": {
      "sidebarSettings": {
        "memberSince": "Member since {signUpDate}"
      },
      "account": {
        "meta": "Account",
        "title": "Account",
        "sidebarText": "Account",
        "formProfileInformation": {
          "title": "Profile information",
          "fullName": {
            "label": "Full name"
          },
          "flash": {
            "personal": "Your personal information has been successfully updated.",
            "email": "Your email has been successfully updated, please check your inbox for verification.",
            "password": "Your password has been successfully updated.",
            "privacy": "The content privacy has been successfully updated."
          }
        },
        "formEmail": {
          "title": "Login settings",
          "2fa": {
            "enabled": "2FA enabled",
            "disabled": "2FA disabled"
          },
          "tooltip": "Confirmation with a phone number",
          "legend": "For security reasons, two-factor authentication is enabled. If you do not have a primary phone number, the confirmation code will be sent to your email address",
          "email": {
            "label": "Your email",
            "legend": "Before changing the e-mail, we will send the confirmation code (OTP) to the current e-mail",
            "validation": {
              "required": "The email address is required.",
              "invalid": "The email address is invalid."
            }
          },
          "phone": {
            "label": {
              "primary": "Primary phone number",
              "backup": "Backup phone number",
              "setPrimary": "Set primary"
            },
            "add": {
              "primary": "Add a primary phone number",
              "backup": "Add a backup phone number"
            },
            "legend": "* Add the primary phone number and you will be able to add a backup phone number",
            "flash": {
              "primary": "The primary phone number has been successfully updated.",
              "deleted": "The phone number has been successfully deleted."
            }
          },
          "modal": {
            "title": {
              "primary": "Add a primary phone number",
              "backup": "Add a backup phone number",
              "email": "Confirm your action"
            },
            "legend": "Quickly sign-in, easily recover passwords, and receive security notifications with this mobile number",
            "confirm": {
              "phone": {
                "title": "Confirm this phone number",
                "flash": "The code has been sent successfully.",
                "message": "We have sent a confirmation code to the mobile phone {0}. Please enter it in the field below.",
                "button": "Add phone number"
              },
              "email": {
                "title": "Confirm your action",
                "message1": "For security reasons, we have sent a One-Time Password (OTP) to the email address {0}. Please enter it in the field below.",
                "message2": "If you haven't received the code, please check your spam folder or request a resend.",
                "message3": "We have sent the code (OTP) to the email address {0}. Please enter it in the field below."
              }
            },
            "backup": {
              "legend": {
                "1": "With this number, if necessary, we will help you login and contact you if we notice suspicious actions in your account.",
                "2": "Your carrier may charge a fee for the delivery of messages."
              },
              "button": "Add phone number"
            },
            "methods": {
              "message": "Choose a verification method to confirm your actions"
            },
            "email": {
              "title": "Change your email address",
              "title2": "Confirm this email address",
              "current": "Current email address: {0}",
              "message": "Enter the new email address you would like to associate with your account below. We will send a One-Time Password (OTP) to that address.",
              "input": {
                "label": "New email address",
                "placeholder": "Enter new email address",
                "validation": {
                  "required": "The email address is required.",
                  "invalid": "The email address is invalid."
                }
              }
            },
            "flash": {
              "codeSent": "The code has been sent successfully.",
              "exists": "The phone number is already registered."
            },
            "delete": {
              "title": "Delete this phone number",
              "message": "Do you want to delete this phone number?"
            },
            "setPrimary": {
              "title": "Do you want to set a new primary phone number?",
              "message": "Your primary phone number {0} will change to the phone number {1}."
            }
          }
        },
        "formPassword": {
          "title": "Password",
          "yourPassword": {
            "label": "Your password"
          },
          "flash": {
            "success": "Your password has been changed.",
            "password": "Your password has been changed."
          }
        },
        "privacy": {
          "title": "Privacy",
          "switch": "Remove my content from creators' profiles",
          "message": "If you don't want your content to be displayed in the creator's profile, enable this"
        },
        "deleteAccount": {
          "title": "Delete account",
          "subtitle": "Delete my brand account",
          "message": "No longer want to use our service? You can delete your account here. This action is not reversible. All information related to this account will be deleted permanently.",
          "buttonOpenModal": "Delete account",
          "modal": {
            "title": "Delete account",
            "text": "Are you sure you want to delete your account? All of your data will be permanently removed from our servers forever. This action cannot be undone.",
            "submit": "Yes, delete account"
          }
        },
        "notification": {
          "primary": {
            "title": "Your mobile phone has been successfully added."
          },
          "backup": {
            "title": "Your backup phone number has been successfully added."
          },
          "email": {
            "title": "Your email address has been successfully changed."
          }
        },
        "marketplace": {
          "title": "Default marketplace",
          "update": "Update default marketplace",
          "list": {
            "us": "United States",
            "ca": "Canada",
            "au": "Australia",
            "uk": "United Kingdom"
          },
          "flash": {
            "updated": "Your default marketplace has been updated"
          }
        }
      },
      "notifications": {
        "meta": "Notifications settings",
        "title": "Notifications settings",
        "sidebarText": "Notifications",
        "browserNotifications": {
          "granted": {
            "message": "You have enabled notifications for this browser. You will have to open your browser preferences to block notifications."
          },
          "denied": {
            "message": "You have blocked notifications for this browser. You’ll have to to open your browser preferences to allow them again."
          },
          "supportBrowser": {
            "message": "This browser does not support notifications."
          },
          "message": {
            "addToken": "Permissions have been allowed",
            "deleteToken": "Permissions disabled"
          },
          "switch": {
            "label": "Allow notifications for this browser"
          }
        },
        "channels": {
          "title": "Channels",
          "instructions": "Select the chanel where you want to receive notifications.",
          "website": {
            "title": "Website"
          },
          "mobile": {
            "title": "Mobile"
          },
          "email": {
            "title": "Email"
          },
          "sms": {
            "title": "SMS"
          },
          "browser": {
            "title": "Browser"
          }
        },
        "options": {
          "campaign": {
            "title": "Campaign",
            "notificationTypes": {
              "CompanyNotificationCampaignApprove": "Campaign approved",
              "CompanyNotificationCampaignRejected": "Campaign declined",
              "CompanyNotificationCampaignSubmitted": "Campaign submitted",
              "CompanyNotificationCampaignOutOfFunds": "Campaign out of funds",
              "CompanyNotificationCampaignCompleted": "Campaign completed",
              "CompanyNotificationCampaignInvitationDeclined": "Campaign invitation declined",
              "CompanyNotificationTikTokAdDelivering": "TikTok Ad approved",
              "CompanyNotificationTikTokAdRejected": "TikTok Ad rejected",
              "CompanyNotificationTikTokAdCompleted": "TikTok Ad completed"
            }
          },
          "account": {
            "title": "Account",
            "notificationTypes": {
              "CompanyNotificationInvoicesBulkReady": "Bulk invoices ready",
              "CompanyNotificationLostPassword": "Lost password",
              "CompanyNotificationWireTransferProcessed": "Wire transfer processed",
              "CompanyNotificationTodoSummary": "Todo summary",
              "CompanyNotificationReferrerByPayout": "Referrer by payout",
              "CompanyNotificationReferrerToPayout": "Referrer to payout",
              "CompanyNotificationAffiliatePayout": "Affiliate payout",
              "CompanyNotificationPayoutReceived": "Payout received",
              "CompanyNotificationPaypalEmailChanged": "Paypal email changed",
              "CompanyNotificationWalletTransactionReceived": "Wallet transaction received",
              "CompanyNotificationStripeSubscriptionCreated": "Subscription",
              "CompanyNotification2FACode": "Two factor authentication",
              "CompanyNotificationWhitelabelHostSuccess": "Custom domain success",
              "CompanyNotificationWhitelabelHostDisconnected": "Custom domain disconnected",
              "CompanyNotificationNew2FANumberAdded": "Two factor number added",
              "CompanyNotificationInviteCollaborator": "Invite collaborator"
            }
          },
          "job": {
            "title": "Job",
            "notificationTypes": {
              "CompanyNotificationJobAutoApproveContent": "Content auto-approved",
              "CompanyNotificationJobContentDelivered": "Job content delivered",
              "CompanyNotificationJobDeliveryTimeout": "Job delivery timeout",
              "CompanyNotificationJobExtraContentAdded": "Extra content added",
              "CompanyNotificationJobSelectContentReminder": "Select content reminder",
              "CompanyNotificationJobAcceptedReimbursement": "Job accepted (reimbursement)",
              "CompanyNotificationJobCancelledByCreatorIShipIt": "Canceled by creator (shipped by me)",
              "CompanyNotificationJobAcceptedIShipIt": "Job accepted (shipped by me)",
              "CompanyNotificationJobCancelledByCreatorReimbursement": "Canceled by creator (reimbursement)",
              "CompanyNotificationJobProductArrived": "Product arrived",
              "CompanyNotificationJobProductPurchased": "Product purchased",
              "CompanyNotificationJobProductSent": "Product sent",
              "CompanyNotificationJobClosed": "Job closed",
              "CompanyNotificationJobContentPicked": "Content approved",
              "CompanyNotificationJobShipmentFirstWarning": "Shipment first warning",
              "CompanyNotificationJobShipmentFinalWarning": "Shipment final warning",
              "CompanyNotificationJobCreationTimeout": "Content creation timeout",
              "CompanyNotificationNewJobApplicant": "New job application",
              "CompanyNotificationChatMessagePending": "New message",
              "CompanyNotificationJobUpsellPurchased": "Job upsell purchased",
              "CompanyNotificationNewJobApplicantList": "New job application list",
              "CompanyNotificationTiktokJobContentPublished": "TikTok job content published",
              "CompanyNotificationInstagramJobContentPublished": "Instagram job content published",
              "CompanyNotificationYoutubeJobContentPublished": "YouTube job content published",
              "CompanyNotificationAmazonJobContentPublished": "Amazon job content published",
              "CompanyNotificationWhitelabelHostSuccess": "Custom domain success",
              "CompanyNotificationWhitelabelHostDisconnected": "Custom domain disconnected"
            }
          }
        },
        "flash": {
          "checkedNotification": "The notification settings have been saved."
        }
      },
      "paymentMethods": {
        "meta": "Payment methods",
        "title": "Payment methods",
        "sidebarText": "Payment methods",
        "errorTip": {
          "title": "Your payment failed!",
          "message": "Your campaign has been paused due to an inability to make a payment. {0}.",
          "boldMessage": "Please update your payment method and try again"
        }
      },
      "wallet": {
        "meta": "Wallet",
        "walletBalance": "Wallet balance",
        "sidebarText": "Wallet",
        "noData": "No transactions found.",
        "transactions": "transaction | transactions",
        "button": {
          "wire": "Wire/ACH",
          "withdraw": "Withdraw",
          "funds": "Top up",
          "withdrawOptions": "Withdraw options"
        },
        "addMoney": {
          "title": "Add money",
          "description": "Use your credit card or make a wire transfer."
        },
        "wireMoneyModal": {
          "title": "Bank transfer",
          "description1": "Please send your wire transfer to the bank account details indicated below.",
          "description2": "Make sure to correctly provide the reference.",
          "note": "Wire transfers CAN'T BE REFUNDED. We will process your wire transfer and credit your main wallet automatically. However if the money is not credited after 5 business days, please contact our support at support{'@'}joinbrands.com",
          "us": {
            "label": {
              "bankName": "Bank name",
              "bankAccountName": "Bank account name",
              "accountNumber": "Account number",
              "achRoutingNumber": "ACH routing number",
              "aba": "ABA",
              "address": "Address",
              "swift": "SWIFT",
              "reference": "Reference"
            },
            "info": {
              "bankName": "JPMorgan Chase Bank",
              "bankAccountName": "Join Brands",
              "accountNumber": "790299751",
              "achRoutingNumber": "322271627",
              "aba": "021000021",
              "address1": "1621 Central Ave",
              "address2": "Cheyenne, WY 82001, USA",
              "swift": "CHASUS33"
            }
          },
          "ca": {
            "label": {
              "bankAccountName": "Bank account name",
              "accountNumber": "Account number",
              "address": "Address",
              "swift": "Swift/BIC",
              "reference": "Reference"
            },
            "info": {
              "bankAccountName": "Join Brands Inc",
              "accountNumber": "200116235345",
              "swift": "TRWICAW1XXX",
              "reference": "Reference",
              "address1": "99 Bank Street, Suite 1420",
              "address2": "Ottawa ON K1P 1H4, Canada"
            }
          },
          "uk": {
            "label": {
              "bankAccountName": "Bank account name",
              "address": "Address",
              "swift": "Swift/BIC",
              "reference": "Reference",
              "iban": "IBAN"
            },
            "info": {
              "bankAccountName": "Join Brands Inc",
              "swift": "TRWIGB2LXXX",
              "reference": "Reference",
              "iban": "GB23 TRWI 2308 0112 2761 70",
              "address1": "56 Shoreditch High Street",
              "address2": "London E1 6JJ, United Kingdom"
            }
          },
          "au": {
            "label": {
              "bankAccountName": "Bank account name",
              "accountNumber": "Account number",
              "bsbCode": "BSB Code",
              "reference": "Reference"
            },
            "info": {
              "bankAccountName": "Join Brands Inc",
              "accountNumber": "220940613",
              "bsbCode": "774001",
              "reference": "Reference"
            }
          }
        },
        "creditMoneyModal": {
          "title": "Add money to the wallet",
          "form": {
            "label": "Amount (minimum {min})",
            "placeholder": "Please enter the amount",
            "button": "Proceed to payment"
          },
          "flashNotifier": {
            "success": "{amount} were added to your wallet."
          },
          "validation": {
            "typeError": "The amount must be greater than {min} and less than {max}.",
            "min": "The minimum amount is {min}.",
            "max": "The maximum amount is {max}."
          }
        },
        "withdrawModal": {
          "title": "Withdraw money",
          "form": {
            "label": "Amount (minimum {min})",
            "placeholder": "Please enter the amount",
            "button": "Proceed to withdraw money"
          },
          "flashNotifier": {
            "success": "{amount} withdrawn from the wallet"
          },
          "validation": {
            "typeError": "The amount is required and must be a number.",
            "min": "The minimum amount is {min}.",
            "max": "The maximum amount is {max}."
          }
        },
        "topUp": {
          "button": "Proceed to add funds",
          "title": "Select a Credit Card to add funds to your Wallet"
        },
        "withdrawOptions": {
          "meta": "Withdraw options",
          "title": "Withdraw options",
          "sidebarText": "Withdraw options",
          "paypal": {
            "description": "Withdraw money from your wallet directly to your PayPal account.",
            "tooltip": "Update email address of PayPal account.",
            "form": {
              "placeholder": "Enter PayPal email"
            }
          }
        }
      },
      "billing": {
        "meta": "Billing",
        "title": "Billing",
        "buttonOpenCalendar": "mm/dd/yyyy - mm/dd/yyyy",
        "dateRange": "{start} to {end}",
        "sidebarText": "Billing",
        "refunded": "Refunded",
        "partiallyRefunded": "Partially refunded",
        "search": {
          "placeholder": "Transaction description"
        },
        "select": {
          "dateRange": {
            "placeholder": "All time",
            "options": {
              "today": "Today",
              "thisWeek": "This week",
              "lastWeek": "Last week",
              "thisMonth": "This month",
              "lastMont": "Last month",
              "thisYear": "This year"
            }
          },
          "paymentMethod": {
            "placeholder": "All payment methods",
            "options": {
              "creditCard": "Credit card",
              "wireTransfer": "Wire transfer"
            }
          }
        },
        "tabs": {
          "billingHistory": {
            "label": "Billing history",
            "paymentMethod": "{ccProvider} ending in {last4} ",
            "columns": {
              "date": "Date",
              "desc": "Description",
              "operation": "Operation",
              "method": "Payment method",
              "total": "Total",
              "pdf": "PDF",
              "credit_card": "Credit card",
              "debit_card": "Debit card",
              "wire_transfer": "Wire transfer"
            },
            "transactions": "transaction | transactions",
            "noData": "No transactions found.",
            "operation": {
              "credit": "Credit",
              "debit": "Debit"
            },
            "exportPDF": "Export PDF statement",
            "exportCSV": "Export CSV statement"
          },
          "billingInfo": {
            "label": "Billing info",
            "card": {
              "title": "Billing information",
              "resume": {
                "companyName": "Company name",
                "addressLine1": "Address line 1",
                "taxId": "Tax ID",
                "notProvided": "Not provided"
              },
              "form": {
                "input": {
                  "name": {
                    "label": "Full name",
                    "validation": {
                      "required": "The name is required."
                    }
                  },
                  "companyName": {
                    "label": "Company name",
                    "validation": {
                      "required": "The company name is required."
                    }
                  },
                  "street": {
                    "label": "Address line 1",
                    "validation": {
                      "required": "The Address line 1 is required."
                    }
                  },
                  "street2": {
                    "label": "Address line 2"
                  },
                  "city": {
                    "label": "City/Town",
                    "validation": {
                      "required": "The city or town is required."
                    }
                  },
                  "taxId": {
                    "label": "Tax ID",
                    "validation": {
                      "format": "The TaxID is not valid."
                    }
                  }
                },
                "select": {
                  "state": {
                    "label": "State",
                    "placeholder": "Select a state",
                    "validation": {
                      "required": "The state is required."
                    }
                  },
                  "country": {
                    "label": "Country",
                    "placeholder": "Select a country",
                    "validation": {
                      "required": "The country is required."
                    }
                  }
                },
                "flash": {
                  "success": "Your billing info have been saved."
                }
              }
            }
          },
          "billingSubscription": {
            "label": "Subscription",
            "columns": {
              "start_billing_date": "Start billing date",
              "next_billing_date": "Next billing date",
              "desc": "Description",
              "amount": "Amount",
              "amount_refunded": "Amount refunded",
              "stripe_receipt_url": "Receipt"
            },
            "subscriptions": "subscription | subscriptions",
            "noData": "No subscriptions found.",
            "search": "Subscription description",
            "status": {
              "succeeded": "Succeeded",
              "failed": "Error"
            },
            "link": "View receipt"
          }
        },
        "flash": {
          "message": "Your billing report will be emailed to you in a few minutes."
        }
      },
      "brands": {
        "meta": "Brands",
        "title": "Brands",
        "sidebarText": "Brands",
        "products": "Products: {count}",
        "table": {
          "legend": "brand | brands",
          "noData": "No brands yet.",
          "columns": {
            "brand": "Brand",
            "campaigns": "Campaigns",
            "content": "Submitted content"
          }
        },
        "actions": {
          "edit": "Edit brand"
        },
        "modalEdit": {
          "title": "Edit brand",
          "btn": "Save brand"
        },
        "status": {
          "active": "Active",
          "created": "Pending approval",
          "paused": "Paused",
          "image": "Photo",
          "video": "Video",
          "pending_edit_approval": "Pending edit approval"
        }
      },
      "affiliate": {
        "meta": "Affiliate program",
        "title": "Affiliate program",
        "sidebarText": "Affiliate program",
        "banner": {
          "title": "Invite Brands or Creators. Earn referral commission.",
          "text": "Earn {amountPerBrand} per brand, {amountPerCreator} per creator active sign-up, and get {commissionPercent}% commission from each service fee we receive from your Brand referrals.",
          "link": {
            "copy": "Copy",
            "copied": "Your affiliate link has been copied"
          },
          "template": {
            "twitter": "I just love getting paid to create UGC content for brands. Use my link to signup and we both get extra $$ {link}",
            "facebook": "I just love getting paid to create UGC content for brands. Use my link to signup and we both get extra $$ {link}",
            "email": {
              "subject": "You should check out JoinBrands",
              "body1": "I just wanted to invite you to this amazing platform that I work with called JoinBrands.",
              "body2": "I get to create content for brands that need influencers and content creators to make authentic images and videos.",
              "body3": "Want to know the best part? They send me free products and I get paid to take pictures and videos using them.",
              "body4": "It's so fun and you only need a cell phone!",
              "body5": "Use my link to signup and we both get extra $$ {link}"
            }
          }
        },
        "stats": {
          "referrals": "Referral | Referrals",
          "payment": "Payment | Payments",
          "commission": "Commission | Commissions"
        },
        "datatable": {
          "title": "My commissions",
          "legend": "commission | commissions",
          "noData": "No commissions yet.",
          "input": {
            "placeholder": "Search"
          },
          "columns": {
            "date": "Date",
            "name": "Name",
            "type": "Type",
            "amount": "Amount",
            "commission": "Commission",
            "status": "Status"
          },
          "status": {
            "pending": "Pending",
            "paid": "Paid",
            "cancelled": "Cancelled",
            "refunded": "Refunded"
          }
        },
        "howWorks": {
          "title": "How it works?",
          "steps": {
            "1": {
              "title": "Send invitation",
              "text": "Send your friends to JoinBrands using affiliate URL."
            },
            "2": {
              "title": "Registration",
              "text": "Let them register to JoinBrands using your referral link."
            },
            "3": {
              "title": "Earn {commissionPercent}%",
              "text": "Earn {commissionPercent}% from service fees we receive from your Brand referrals for 1 year."
            }
          }
        },
        "faq": {
          "title": "FAQ",
          "questions": {
            "1": {
              "title": "How does it work?",
              "steps": {
                "1": "Send your friends to JoinBrands using affiliate URL.",
                "2": "Each client you refer to JoinBrands will be automatically linked to your affiliate account.",
                "3": "Earn {amount} per brand, {amountPerCreator} per creator active sign-up, and get {commissionPercent}% commission from each service fee we receive from your Brand referrals.",
                "4": {
                  "text": "{0} {1} {2}",
                  "textFirstPart": "Earned commission will be added to your",
                  "textSecondPart": "wallet",
                  "textThirdPart": "{days} days after the payment."
                }
              }
            },
            "2": {
              "title": "How long to wait for the money?",
              "text": "{0} {1} {2}",
              "textFirstPart": "The commission will be added to your",
              "textSecondPart": "wallet",
              "textThirdPart": "{days} days after payment by the partner."
            },
            "3": {
              "title": "How to withdraw money?",
              "text": "{0} {1} {2}",
              "textFirstPart": "Go to your",
              "textSecondPart": "wallet",
              "textThirdPart": "and click on Withdraw."
            },
            "4": {
              "title": "Can I get paid out in another currency?",
              "text": "The affiliate payout is in USD only, any commission earned in other currencies will be converted to USD."
            }
          }
        }
      },
      "contact": {
        "sidebarText": "Contact support"
      },
      "subscription": {
        "meta": "Subscription",
        "sidebarText": "Subscription",
        "currentSubscription": {
          "title": "Subscription",
          "plan": {
            "planName": {
              "0": "PAY-AS-YOU-GO",
              "1": "STARTUP",
              "2": "BRAND",
              "3": "AGENCY"
            },
            "renewed": "Your subscription will renew on",
            "upgrade": "Change plan",
            "downgraded": "The subscription will be updated to {0} plan, on {1}",
            "cancelled": "Upgrade & save! more features, lower fees.",
            "renew": "Renew subscription",
            "reactivate": "Reactivate",
            "failed": "We were unable to charge your credit card, your subscription has been cancelled",
            "previousPlan": "Previous plan: ",
            "flash": {
              "cancel": "Your subscription has been cancelled. You can use the {plan} subscription until {date}.",
              "updated": "Your subscription has been updated to {plan}.",
              "reactivated": "Your subscription has been reactivated to {plan}.",
              "subscribed": "Thank you for subscribing! Get ready to receive the benefits!",
              "paymentUpdated": "Your payment method has been updated."
            }
          },
          "subscribed": {
            "title": "You have been subscribed!",
            "message": "Thank you for subscribing! Get ready to receive the benefits!",
            "back": "Go to dashboard",
            "next": "Go to subscription"
          },
          "cancelled": {
            "title": "You have cancelled your subscription!",
            "message": "If you ever decide to reactivate your subscription or have any questions, please don't hesitate to reach out. We're here to assist you.",
            "back": "Go to dashboard",
            "next": "Go to subscription"
          },
          "billing": {
            "title": "Billing",
            "visa": "Visa ending in {card}",
            "amex": "American Express ending in {card}",
            "unionpay": "UnionPay ending in {card}",
            "mastercard": "Mastercard ending in {card}",
            "dinersclub": "Diners Club ending in {card}",
            "jcb": "JCB ending in {card}",
            "discover": "Discover ending in {card}",
            "button": "Change payment method"
          },
          "cancellation": {
            "title": "Cancellation",
            "switch": "After cancellation, your current plan will remain active until {0}, at which point it will automatically switch to the {1} plan.",
            "plan": "PAY-AS-YOU-GO",
            "link": "Cancel subscription",
            "modal": {
              "title": "Cancel your subscription?",
              "message": "Your subscription will be downgraded to the {0} plan. Do you really want to cancel your subscription?",
              "free": "PAY-AS-YOU-GO",
              "placeholder": "Please explain why you decided to cancel the subscription.",
              "maxStringChars": "The maximum number of characters allowed is {max}."
            }
          }
        },
        "subscriptionPlans": {
          "title": "Select Your {0}",
          "title2": "Ideal Plan",
          "legend": "JoinBrands streamlines brand collaborations with over 25,000 creators. Secure cost-effective and  ready-to-showcase UGC assets, and team up with influencers for genuine promotions and whitelisted ads.",
          "monthly": "Monthly billing",
          "annual": "Annual billing",
          "daily": "Daily billing",
          "extraOff": "EXTRA {amount}% OFF",
          "plans": {
            "0": "PAY-AS-YOU-GO",
            "1": "STARTUP",
            "2": "BRAND",
            "3": "AGENCY",
            "badgeFree": "No discount on fees",
            "badgeDiscount": "discount on fees",
            "month": "per/month, billed monthly",
            "year": "per/month, billed annually",
            "day": "per/month, billed daily",
            "resolution": "Max video resolution - {0}",
            "revisions": "Revision request - {count} | Revision requests - {count}",
            "fullHD": "FULL HD",
            "features": {
              "title": "Features",
              "contentCredit": {
                "title": "{credit} Content credit",
                "legendPayAsYouGo": "There is no content credit for this plan option. Please upgrade to receive the credit",
                "legend": "50% of your first month payment is fully returned as a credit to fund your content creation"
              },
              "contentTitle": "Content",
              "campaigns": "Unlimited campaigns",
              "creators": "Unlimited creators",
              "content": "Unlimited content",
              "deadlines": "Extend deadlines",
              "chat": "Chat with creators",
              "portfolio": "Creator portfolios",
              "tip": "Tip creators",
              "watermark": "Custom watermarks",
              "shareable_links": "White label shareable links",
              "fee": "Platform fee",
              "ugc": "UGC Images, UGC Videos, TikTok Posts, Instagram Reels, YouTube Shorts, Amazon Videos",
              "extraOff": "{extra}% OFF",
              "noCollaborators": "No collaborators",
              "collaborators": "{count} collaborator | {count} collaborators",
              "unlimitedCreatorInvites": "Unlimited creator invites",
              "creatorInvites": "{amount} creator invites",
              "massInvites": "Mass favorite invites"
            },
            "button1": "Your current plan",
            "button2": "Upgrade",
            "button3": "Downgrade"
          },
          "about": {
            "title": "Want to learn more about JoinBrands?",
            "legend": "Whether you're a brand owner, agency, a seller, or simply interested in what we offer, we're delighted to provide a full demo!",
            "button": "Book a demo"
          },
          "faq": {
            "q1": "What makes JoinBrands unique?",
            "r1": "We connect brands with real content creators to source the most authentic content, without any headache. It’s all on us: from finding the right creators to ensuring all your content is tailored to your needs and delivered on time.",
            "q2": "Why is UGC so important?",
            "r2": "User-generated content helps influence engagement, increase conversions and build customer trust. UGC acts as authentic social proof that your product is worthy of buying.",
            "q3": "How fast can I get my content?",
            "r3": "Once the creator receives your product, it takes around 5 days for creators to upload content. Based on our experience, about 80% of tasks are completed within a week.",
            "q4": "Do creators post content on their social media?",
            "r4": "Yes! If you create a TikTok campaign, our influencers will post your videos to their TikTok accounts and keep the post active for a set time.",
            "q5": "Can this work for my agency?",
            "r5": "Absolutely! If you own a marketing agency, then JoinBrands is the perfect solution when it comes to getting content. Add all your brands and products and get content for all of them - from just one account!",
            "q6": "Can I communicate with creators?",
            "r6": "Yes, you can chat directly with your creators at any stage of the job."
          }
        },
        "upgradeModal": {
          "reviews": {
            "title": "Unlock more revision requests",
            "description": "Want more revision requests? It's possible! With our subscription plans, you get the opportunity to unlock additional review requests. Upgrade now!"
          },
          "watermark": {
            "title": "Unlock custom watermark",
            "description": "Want to customize the watermark? It's possible! With our subscription plans, you get the opportunity to unlock custom watermark. Upgrade now!"
          },
          "domain": {
            "title": "Unlock custom domain",
            "description": "Want to customize the domain? It's possible! With our subscription plans, you get the opportunity to unlock custom domain. Upgrade now!"
          },
          "extendDeadline": {
            "title": "Unlock unlimited deadline extensions",
            "description": "Want to give more time to the creator? It's possible! With our subscription plans, you get the opportunity to unlock unlimited deadline extensions!"
          },
          "collaborators": {
            "title": "Unlock collaborators",
            "description": "Do you want to add a collaborator? It's possible! With ours plans subscription, you get the opportunity to unlock collaborators. Upgrade now!"
          },
          "inviteCreators": {
            "title": "Invite more creators",
            "description": "You've used {current}/{limit} of your campaign invites. Please upgrade your plan to be able to invite more.",
            "info": "You've used {current}/{limit} of your campaign invites"
          },
          "button": {
            "upgrade": "Upgrade",
            "later": "Maybe later"
          }
        },
        "connectDomainModal": {
          "title": "Connect a domain or subdomain",
          "description": {
            "text": "{0} {1} {2}",
            "textFirstPart": "You should connect a domain or subdomain to share your content. You can connect it in the",
            "textSecondPart": "White labeling",
            "textThirdPart": "section."
          },
          "button": {
            "connect": "Connect",
            "later": "Maybe later"
          }
        }
      },
      "whiteLabeling": {
        "meta": "White labeling",
        "title": "White labeling",
        "sidebarText": "White labeling",
        "customWatermark": {
          "title": "Custom watermark",
          "description": "Add your own watermarks to video and image previews. This is great for agencies that use JoinBrands for content for their clients and want to show it with their own branding.",
          "button": {
            "add": "Add watermark",
            "edit": "Edit watermark"
          },
          "deleteModal": {
            "title": "Do you want to remove the watermark?",
            "description": "After removing your logo, the watermark will become the default from JoinBrands",
            "tip": {
              "label": "Important: {0}",
              "content": "Old content can't be updated with a new watermark; it will only update future content after a new watermark is accepted by the platform."
            }
          },
          "flash": {
            "deleteSuccess": "The watermark has ben removed",
            "pending": "Your logo is being processed",
            "approved": "Your watermark is active and will be used on all new uploaded content",
            "rejected": "We were unable to create a watermark from the logo you have uploaded, please contact support",
            "notAllowed": "Your actual subscription doesn't allow the use of custom watermark, please contact support"
          }
        },
        "aboutModal": {
          "label": "About",
          "header": "About White labeling",
          "content": {
            "text": "{0} {1} {2}",
            "textFirstPart": "If you operate an agency and are interested in whitelabeling preview images and videos, as well as utilizing custom-branded URLs, this module is designed specifically for your needs. To access this feature, an upgrade to our",
            "textSecondPart": "AGENCY PLAN",
            "textThirdPart": "is required."
          }
        },
        "customDomain": {
          "title": "Custom domain or subdomain",
          "description": "Personalize the web addresses of your image and video previews by using your own domain or subdomain. This feature is handy for agencies that wish to share preview images and videos with their clients using unique URLs.",
          "button": "Add domain",
          "table": {
            "columns": {
              "domain": "Domain or subdomain",
              "status": "Status",
              "created": "Created",
              "actions": "Actions"
            },
            "status": {
              "active": "Active",
              "inactive": "Inactive",
              "pending_domain_config": "Pending verification",
              "pending_ssl_config": "Pending SSL configuration"
            }
          },
          "deleteModal": {
            "title": "Delete a domain?",
            "description": "Are you sure you want to delete this domain? All your shared links linked to the domain will not be available",
            "flash": {
              "success": "The domain or subdomain has been deleted successfully."
            }
          }
        },
        "connectDomain": {
          "meta": "Connect your domain",
          "title": "Connect your domain",
          "form": {
            "domain": {
              "label": "Enter domain or subdomain name",
              "placeholder": "subdomain.domain.com",
              "prefix": "https://",
              "validation": {
                "required": "The domain or subdomain is required.",
                "match": "Enter a valid domain or subdomain."
              }
            },
            "confirmDomain": {
              "label": "I confirm I do not have any critical data or content on this domain.",
              "validation": {
                "required": "You must confirm that you do not have any critical data or content on this domain."
              }
            },
            "warning": {
              "message": "{0} {1}",
              "messageFirstPart": "Warning:",
              "messageSecondPart": "A domain cannot point to two places at the same time. If you connect this domain to brand your JoinBrands links, the domain will no longer be associated with your current website. Any existing content or data will be inaccessible."
            },
            "submit": "Add domain"
          }
        },
        "setupDomain": {
          "meta": "Setup your domain",
          "title": "Setup your domain",
          "subtitle": "How to connect your custom domain",
          "description": "To connect your domain or subdomain, you will need to update your domain's DNS settings. This is managed in your domain provider, not in JoinBrands. Follow the step-by-step instructions below to get started.",
          "instructions": {
            "title": "Instructions",
            "step1": "In a new window, log in to your domain provider.",
            "step2": "Locate the DNS settings. This might be under DNS Manager, Manage Domains, Domain Manager, or something similar.",
            "step3": {
              "p": "Once you locate the DNS settings, you'll want to look for CNAME records. If CNAME records already exist, you can update them to match the information below. If they don't, you'll need to create a CNAME record to match these.",
              "record": "Record type",
              "hostname": "Hostname",
              "pointsTo": "Points to",
              "copied": "The hostname to point to has been copied."
            }
          },
          "verify": "Verify",
          "verifying": "Verifying...",
          "flash": {
            "verified": "Your domain or subdomain is now verified.",
            "unverified": "We couldn't verify the DNS settings for {hostname}. Please ensure your DNS is set up according to the below instructions. Keep in mind: DNS updates may take time to propagate."
          },
          "hostnameNotFound": "The domain or subdomain is invalid or can't be found."
        }
      },
      "collaborators": {
        "meta": "Collaborators",
        "title": "Collaborators",
        "sidebarText": "Collaborators",
        "add": {
          "title": "You haven't added anyone to the list yet.",
          "message": "You haven't any user permissions yet. if you want to invite member and share access, press the button below.",
          "button": "Invite member"
        },
        "modal": {
          "title": {
            "invite": "Invite a collaborator",
            "edit": "Edit {name}"
          },
          "sendInvitation": "Send invitation",
          "form": {
            "title": "Member",
            "firstName": {
              "label": "First name",
              "validation": {
                "required": "The first name is required",
                "min": "The minimum number of chars is {min}.",
                "max": "The maximum number of chars is {max}."
              }
            },
            "lastName": {
              "label": "Last name",
              "validation": {
                "required": "The last name is required",
                "min": "The minimum number of chars is {min}.",
                "max": "The maximum number of chars is {max}."
              }
            },
            "phone": {
              "label": "Phone number (optional)",
              "placeholder": "Enter phone number"
            },
            "email": "Email address",
            "brands": {
              "label": "Choose a brand",
              "labelSelect": "Select brand",
              "info": "You can choose one or more brands that will be available to the collaborator.",
              "allLabel": "Select all brands",
              "selected": "Selected {count} brand | Selected {count} brands"
            }
          },
          "invitation": {
            "title": "Now you are a collaborator of {name}",
            "subtitle": "The limited functionality of the system is available to you. You can find out more about this at the link below",
            "link": "Learn more about the features"
          },
          "permissions": {
            "title": "Permissions",
            "tooltip": "Permissions allow you to fine-tune to what areas your collaborator has access to. Some permissions have dependencies, and they will be automatically selected when required.",
            "table": {
              "columns": {
                "type": "Type",
                "no": "None",
                "view": "View Only",
                "full": "Full access"
              },
              "cell": {
                "campaigns": {
                  "title": "Campaigns",
                  "description": "Create, view, leave a review"
                },
                "tt_ad_center": {
                  "title": "Ad center",
                  "description": "Create, view boosts"
                },
                "products": {
                  "title": "Products",
                  "description": "Сreate, view, delete products"
                },
                "content": {
                  "title": "Content",
                  "description": "Approve, buy extra content, add tips"
                },
                "brands": {
                  "title": "Brands",
                  "description": "Сreate, view brands"
                },
                "chat": {
                  "title": "Chat",
                  "description": "View, reply to messages"
                },
                "wallet": {
                  "title": "Wallet",
                  "description": "Top up, withdraw",
                  "tooltip": "The collaborator will be able to withdraw, top up and change the payment method here"
                },
                "billing": {
                  "title": "Billing",
                  "description": "View and edit billing information"
                },
                "payment_methods": {
                  "title": "Payment methods",
                  "description": "Add and delete a credit card"
                },
                "my_subscription": {
                  "title": "Subscription",
                  "description": "Subscription Management"
                },
                "white_labeling": {
                  "title": "White labeling",
                  "description": "Add and delete custom watermark, domain, and subdomain"
                },
                "affiliate_program": {
                  "title": "Affiliate Program",
                  "description": "Invite referrals"
                },
                "recurring_campaigns": {
                  "title": "Recurring campaigns",
                  "description": "Create, view campaigns"
                }
              },
              "button": {
                "selectAll": "Select All"
              },
              "tooltip": {
                "remove": "Other permissions depend on this permission, they will disabled when you disable this permission.",
                "require": "This permission has dependencies, they will be automatically enabled when you enable this permission."
              }
            }
          }
        },
        "dashboard": {
          "noPermissions": {
            "title": "There are no permissions",
            "subtitle": {
              "1": "Unfortunately, the dashboard data is not available.",
              "2": "Contact your administrator",
              "3": "to grant you permissions."
            }
          }
        },
        "list": {
          "titleLimit": "Usage",
          "count": "{total} out of {totalAll}",
          "buttonUpgrade": "Upgrade plan",
          "title": "Collaborator",
          "buttonInvite": "Invite Collaborator",
          "datatable": {
            "columns": {
              "fullName": "Full name",
              "email": "Email adress",
              "brand": "Brand",
              "phone": "Phone number",
              "status": "Status",
              "actions": "Actions"
            },
            "status": {
              "invited": "Request sent",
              "accepted": "Active"
            },
            "dropdown": {
              "edit": "Edit",
              "delete": "Delete",
              "resend": "Resend invitation",
              "link": "Copy invitation link"
            }
          }
        },
        "deleteModal": {
          "title": "Delete collaborator",
          "message": "Do you want to delete this collaborator?"
        },
        "flash": {
          "link": "link",
          "deleted": "The collaborator has been deleted.",
          "updated": "The collaborator has been updated.",
          "created": "The collaborator has been invited by email, you can also send this invitation {0} to your collaborator.",
          "resent": "The invitation has been resent to the collaborator"
        },
        "notification": {
          "copied": "The link has been copied to the clipboard."
        }
      }
    },
    "dashboard": {
      "noCampaigns": {
        "h3": "Want the same content with your product?",
        "p": "Start now and hire your first UGC creator!",
        "review": {
          "title": "Hire lots of UGC creators ready to work with you!",
          "ratings": "ratings",
          "creatorsCount": "10K",
          "creators": "Creators"
        },
        "video": {
          "title": "Creating your first campaign is super easy!",
          "modalTitle": "How to get started"
        },
        "steps": {
          "1": {
            "title": "Add your",
            "link": "first product"
          },
          "2": {
            "title": "with this product",
            "link": "Create a campaign"
          },
          "3": {
            "title": "Get your UGC in 7-14 days"
          }
        },
        "tabs": {
          "title": "What kind of content can you expect?",
          "subtitle": "Videos start at as low as {video} and photos start at {photo}.",
          "videos": "Video",
          "images": "Photo"
        }
      },
      "onboarding": {
        "title": {
          "1": "Congrats! You just created your first campaign!",
          "2": "Here’s what’s going to happen next."
        },
        "subtitle": {
          "1": "Here’s what’s going to happen next:",
          "2": " Need help?"
        },
        "slider": {
          "point": {
            "1": "1. Creators apply to your job",
            "2": "2. You approve creators",
            "3": "3. Creators start working",
            "4": "4. You get your content"
          },
          "content": {
            "1": "Once your campaign is live, creators will begin to apply! Just invite the ones you like the most.",
            "2": "Approve more creators, as some may not confirm the job offer. The first creators to confirm will take it.",
            "3": "Creators who confirm the job will start working. From now on, you can monitor your job's progress at any stage. ",
            "4": "Approve the content you like the most, and even get more content if creators upload extras."
          }
        }
      },
      "noTodos": {
        "title": "No active jobs",
        "subtitle": "Post a job to the marketplace and let the creators come to you.",
        "button": "Post a job"
      },
      "accountInfo": {
        "label": {
          "myImages": "My images",
          "myVideos": "My videos",
          "myPosts": "My posts",
          "myBoosts": "My boosts"
        }
      },
      "campaigns": {
        "tab": {
          "todo": "To-do's",
          "active": "Active campaigns"
        },
        "filters": {
          "select": {
            "brand": {
              "label": "Brand",
              "placeholder": "All brands"
            }
          }
        },
        "card": {
          "jobsCompleted": "Jobs completed",
          "stats": "{from} of {to}",
          "label": {
            "noActiveCampaigns": "No active campaigns yet.",
            "noPausedCampaigns": "No paused campaigns yet.",
            "noPendingApproval": "No pending approval campaigns yet.",
            "noDeclined": "No declined campaigns yet.",
            "campaignLink": "Your new authentic content could be here!",
            "available": "Available",
            "inProgress": "In progress",
            "completed": "Completed",
            "canceled": "Canceled",
            "sendProduct": "Send product",
            "noActionRequired": "No actions required",
            "preApproval": "Pre-approval",
            "approveContent": "Approve content",
            "updatePaymentMethod": "Update payment method",
            "applicants": "Applicants",
            "noActionsRequired": "No actions required",
            "status": {
              "paused": "Paused"
            },
            "jobs": {
              "completed": "Jobs completed",
              "nb": "{completed} of {jobs}"
            }
          },
          "link": {
            "seeDetails": "See details",
            "createNewCampaign": "Create a new campaign",
            "details": "Details",
            "pauseCampaign": "Pause campaign",
            "unpauseCampaign": "Unpause campaign",
            "duplicate": "Duplicate campaign",
            "resubmit": "Resubmit",
            "action": "Action"
          }
        },
        "pagination": {
          "campaigns": "campaigns"
        },
        "todo": {
          "actions": {
            "show": "Show {records} more tasks",
            "hide": "Show less"
          }
        }
      },
      "extraContent": {
        "title": "Get a discount on any extra content"
      },
      "newContent": {
        "title": "Fresh new content"
      },
      "additional": {
        "h3": "Additional content",
        "card": {
          "btn": {
            "approveContent": "Approve content",
            "seeContent": "See content",
            "seeContentType": "See {number} {contentType}",
            "videos": "video | videos",
            "photos": "photo | photos"
          }
        },
        "notFound": "Additional content not found."
      },
      "todos": {
        "message": {
          "CompanyTodoJobSendProduct": {
            "message": "{0} {1}",
            "messageFirstPart": "Send product",
            "messageSecondPart": " to {creatorName}"
          },
          "CompanyTodoJobPickContent": {
            "message": "{0} {1}",
            "messageFirstPart": "Your content is ready"
          },
          "CompanyTodoJobContentTimeout": {
            "message": "{0} {1}",
            "messageFirstPart": "The creator is late with delivering your content"
          },
          "CompanyTodoJobPurchaseTimeout": {
            "message": "The creator has {0} {1}",
            "messageFirstPart": " not purchased the product",
            "messageSecondPart": " on time"
          },
          "CompanyTodoJobDeliveryTime": {
            "message": "The creator has {0} {1}",
            "messageFirstPart": " not received the product",
            "messageSecondPart": " on time"
          },
          "CompanyTodoCampaignPickCreators": {
            "message": "{0} {1}",
            "messageFirstPart": "Select the creators",
            "messageSecondPart": " who can work on your campaign"
          },
          "CompanyTodoCampaignOutOfFunds": {
            "message": "{0} {1}",
            "messageFirstPart": "Unable to charge your credit card, your campaign has been paused"
          },
          "CompanyTodoJobContentCheckPostedVideo": {
            "message": "{0} {1}",
            "messageFirstPart": "Video is posted on {creatorName} {socialUgc} account."
          },
          "CompanyTodoJobAskForReview": {
            "message": "{0} {1}",
            "messageFirstPart": "Loved working with {creatorName} ? Leave a review!",
            "messageSecondPart": "Share your experience to help creators improve their content!"
          },
          "CompanyTodoCampaignAddMoreJobs": {
            "message": "{0} {1}",
            "messageFirstPart": "Congrats! All your jobs are in progress!",
            "messageSecondPart": "Get even more content from other talented creators!"
          },
          "CompanyTodoCampaignInviteMoreCreators": {
            "message": "{0} {1}",
            "messageFirstPart": "We recommend inviting {suggested_creators} more creators for this campaign"
          }
        },
        "actions": {
          "CompanyTodoJobSendProduct": "Add tracking #",
          "CompanyTodoJobPickContent": "Approve content",
          "CompanyTodoJobContentTimeout": "Open resolution center",
          "CompanyTodoJobPurchaseTimeout": "Open resolution center",
          "CompanyTodoJobDeliveryTime": "Open resolution center",
          "CompanyTodoCampaignPickCreators": "Approve creators",
          "CompanyTodoCampaignOutOfFunds": "Update payment method",
          "CompanyTodoJobContentCheckPostedVideo": "Check video",
          "CompanyTodoJobAskForReview": "Leave a review",
          "CompanyTodoCampaignAddMoreJobs": "Add more jobs",
          "CompanyTodoCampaignInviteMoreCreators": "Invite more creators"
        },
        "complement": {
          "CompanyTodoJobSendProduct": {
            "left": "Left to add tracking #",
            "late": "Late to add tracking #"
          },
          "CompanyTodoJobPickContent": {
            "left": "Left to approve content",
            "late": "Late to approve content"
          },
          "CompanyTodoJobContentCheckPostedVideo": {
            "left": "Active post on {socialUgc}"
          }
        }
      },
      "boost": {
        "title": "You can boost {count} post via Spark Ads | You can boost {count} posts via Spark Ads",
        "text": "Boost your TikTok posts to reach millions of interested viewers & potential buyers.",
        "button": "Boost post",
        "modal": {
          "title": "Boost your posts on TikTok",
          "text": "from {0} per day",
          "analytics": {
            "cpc": "CPC",
            "ctr": "CTR",
            "clicks": "Clicks"
          }
        }
      }
    },
    "content": {
      "meta": {
        "title": "Content"
      },
      "selectUgc": {
        "title": "Select the content type",
        "about": "About",
        "ugcType": {
          "image": "Images",
          "video": "Videos",
          "tiktok": "TikTok videos",
          "tiktok_shop": "TikTok Shop videos",
          "amazon": "Amazon videos",
          "instagram": "Instagram Reels",
          "youtube": "YouTube Shorts",
          "about": {
            "image": "Images",
            "video": "Videos",
            "tiktok": "TikTok videos",
            "tiktok_shop": "TikTok Shop videos",
            "amazon": "Amazon Shoppable videos",
            "instagram": "Instagram Reels",
            "youtube": "YouTube Shorts",
            "custom": "Custom Jobs"
          },
          "description": {
            "image": "Take a UGC image that aligns with the brand's requirements and vision.",
            "video": "Make a UGC video ensuring it resonates with the brand's specific requirements and vision.",
            "tiktok": "Create and share an authentic TikTok video that reflects the brand's essence and guidelines, ensuring organic integration into your TikTok channel.",
            "tiktok_shop": "Create and share TikTok Videos as a TikTok Shop Affiliate Creator, link your videos to the brand's TikTok shop and earn affiliate commissions.",
            "amazon": "Produce and share an Amazon Shoppable Video to your Amazon Influencer account, ensuring it follows the brand's requirements.",
            "instagram": "Create and share an authentic Instagram Reel that follows the brand's guidelines, ensuring organic integration into your Instagram feed.",
            "youtube": "Create and share an authentic YouTube Short that follows the brand's guidelines, ensuring organic integration into your YouTube channel.",
            "custom": "Create a custom piece of content tailored to the brand's unique needs and vision, whether it's an image, video, or a social media post, ensuring alignment with specific guidelines and creative direction."
          }
        }
      },
      "ugcTypesModal": {
        "title": "Content type"
      },
      "form": {
        "filters": {
          "title": "Filters",
          "label": {
            "selectContentType": "Content type",
            "selectCreatorLevel": "Creator level",
            "selectDeliveryType": "Delivery type",
            "selectProduct": "Product",
            "allContentType": "All content",
            "allCreatorLevel": "All levels",
            "allDeliveryType": "All deliveries",
            "allProduct": "All products"
          },
          "contentType": {
            "selected": "{nb} selected"
          },
          "creatorLevel": {
            "1": "Level 1",
            "2": "Level 2",
            "3": "Level 3",
            "selected": "{nb} selected"
          },
          "product": {
            "selected": "{nb} products"
          }
        },
        "selectBrand": {
          "label": "Brand",
          "placeholder": "Select brand"
        },
        "sortBy": {
          "label": "Sort by",
          "options": {
            "newest": "Newest",
            "oldest": "Oldest"
          }
        }
      },
      "tabs": {
        "myContent": "My content",
        "approveContent": "Approve content",
        "extraContent": "Extra content"
      },
      "views": {
        "content": {
          "title": "Available content"
        }
      },
      "details": {
        "h1": "Jobs you may be interested in",
        "filter": {
          "title": "Filters",
          "label": {
            "imageType": "Image type",
            "videoType": "Video type",
            "seeAll": "See all"
          }
        },
        "searchResult": {
          "noContent": "No content",
          "notFound": "Sorry, but nothing matched your search terms. Please change your filtering selections and try again.",
          "validation": "The minimum number of characters required is 3.",
          "request": "Search by request",
          "found": "Found",
          "jobs": "jobs",
          "clear": "Clear results"
        },
        "pagination": {
          "results": "results"
        }
      },
      "links": {
        "getFor": "Get for {price}"
      }
    },
    "share": {
      "contentPreview": {
        "alt": "Content"
      }
    },
    "portfolio": {
      "meta": "Portfolio",
      "creatorInfo": {
        "jobs": "{count} job | {count} jobs",
        "ratings": "({count} rating) | ({count} ratings)",
        "info": "Member since {signUpDate} Completed {count} job | Member since {signUpDate} Completed {count} jobs",
        "memberSince": "Member since {signUpDate}",
        "completedJobs": "Completed {count} job | Completed {count} jobs",
        "completed": "Completed",
        "lastSeenOnline": "Last seen online",
        "labelTooltip": {
          "profile": "Open profile in a new window",
          "cancelInvite": "If you revoke the invite, the creator will still be able to apply for this job."
        },
        "invite": "Invite",
        "approve": "Approve",
        "cancelInvite": "Cancel invite",
        "creatorBio": "Creator's bio",
        "socialNetworks": "Social networks",
        "hiddenCampaign": {
          "title": "Hidden for this campaign",
          "subtitle": "All your other jobs will be visible to this creator except this one. Don't worry, we won't tell anyone!",
          "btn": "Undo"
        },
        "profileBlocked": {
          "title": "Blocked forever",
          "subtitle": "This creator will never see your jobs again. Don’t worry, we won’t tell anyone!",
          "btn": "Undo"
        },
        "empty": {
          "newTalent": {
            "title": "New talent",
            "subtitle": "Be the first one to get content from this fresh creator!",
            "btn": "Let’s do it!"
          },
          "oldCreator": {
            "title": "Looking for something new?",
            "subtitle": "Be the first one to get content from this creator!",
            "btn": "Let’s do it!"
          }
        }
      },
      "tabs": {
        "portfolio": "Portfolio",
        "reviews": "Reviews",
        "recent": "Recent jobs",
        "tiktok": "TikTok"
      },
      "content": {
        "title": "{firstName}'s portfolio",
        "noContent": {
          "title": "Looking for something new?",
          "subtitle": "This creator has not yet updated the portfolio."
        },
        "noContentNew": {
          "title": "Just joined",
          "subtitle": "Fresh new creator on JoinBrands."
        },
        "showAll": "Show all portfolio",
        "collapsePortfolio": "Collapse portfolio"
      },
      "reviews": {
        "title": "Latest reviews",
        "anyReviews": "{name} doesn't have any reviews yet.",
        "showMore": "Show more",
        "hideMore": "Collapse reviews",
        "modal": {
          "title": "Reviews",
          "button": "See more"
        }
      },
      "recentJobs": {
        "title": "Recent jobs"
      },
      "tiktok": {
        "title": "TikTok",
        "followers": "{0} Followers",
        "views": "{0} Avg. views",
        "button": "TikTok profile",
        "postPrice": "Price per post"
      },
      "instagram": {
        "title": "Instagram",
        "button": "Instagram profile",
        "postPrice": "Price per post"
      },
      "youtube": {
        "title": "YouTube",
        "followers": "{0} Followers",
        "views": "{0} Avg. views",
        "button": "YouTube profile",
        "postPrice": "Price per short"
      },
      "amazon": {
        "title": "Amazon",
        "button": "Amazon profile",
        "postPrice": "Price per video"
      }
    },
    "creators": {
      "sort": "Sort by",
      "sortOptions": {
        "lastSeen": "Last seen online",
        "newest": "Newest creators",
        "levelHigh": "Level: high to low",
        "levelLow": "Level: low to high",
        "highRate": "Highest rated",
        "completedJobs": "Completed jobs: high to low",
        "priceHigh": "Price: high to low",
        "priceLow": "Price: low to high",
        "posts": "Highest video views",
        "followers": "Highest followers count"
      },
      "details": {
        "tiktok": {
          "followers": "Followers",
          "views": "Avg. views",
          "posts": "TikTok post"
        },
        "youtube": {
          "short": "YouTube Short"
        }
      },
      "modals": {
        "invite": {
          "title": "Congrats!",
          "subtitle": "Your campaign is submitted and will be live within 24 h.",
          "message": "In the meantime, you can invite your favorite creators so they can start working as soon as they accept the invitation and take the job!",
          "button": "Start inviting creators"
        },
        "more": {
          "title": "Make room for more!",
          "message": "You've got creators for all your jobs!",
          "message2": "Wanna invite more creators? Then add some new jobs!",
          "invited": "Invited: {0}",
          "taken": "Job taken: {0}",
          "of": "{no} of {total}",
          "buttons": {
            "cancel": "Cancel",
            "add": "Add {count} job | Add {count} jobs"
          }
        }
      },
      "search": {
        "placeholder": "Search creators",
        "noResults": {
          "title": "Looks empty here",
          "subtitle": "Looks like no creators match your search. Update your search filters."
        }
      },
      "filters": "Filters",
      "sidebar": {
        "worked": {
          "title": "Creator collaboration status",
          "exclude_worked_with": "New to me",
          "any": "Any",
          "only_worked_with": "Worked for me"
        },
        "jobsCompleted": {
          "title": "Jobs completed",
          "0": "Any",
          "1": "Under 5 jobs",
          "2": "6 - 10 jobs",
          "3": "More than 10 jobs"
        },
        "deliveryTime": {
          "title": "Average delivery time",
          "0": "Any",
          "1": "Under 5 days",
          "2": "5 - 10 days",
          "3": "More than 10 days"
        },
        "levels": {
          "title": "Creator level",
          "1": "Level 1",
          "2": "Level 2",
          "3": "Level 3"
        },
        "states": {
          "label": "State",
          "placeholder": "State"
        },
        "zip": {
          "label": "Near ZIP code",
          "placeholder": "Enter a ZIP code",
          "validation": {
            "invalid": "The ZIP code is invalid.",
            "regex": "The ZIP code must be 5 digits long."
          },
          "tag": "within {distance} {distance_unit} of ZIP code {zip_code}"
        },
        "distance": {
          "label": "Distance",
          "placeholder": "Enter a distance",
          "description": "(needs to be between 10 and 6399)",
          "validation": {
            "required": "The distance is required.",
            "min": "The distance must be at least {min}.",
            "max": "The distance must not exceed {max}."
          }
        },
        "categories": {
          "label": "Category",
          "placeholder": "Category"
        }
      },
      "favorite": {
        "add": "Add to favorites",
        "remove": "Remove from favorites",
        "inviteAll": "Invite all",
        "confirmModal": {
          "title": "Invite all creators",
          "message": "Are you sure you want to invite all creators? it's going to take some time",
          "button": "Invite all"
        },
        "upgradeModal": {
          "title": "Upgrade your subscription",
          "message": "The Mass invite feature is only available on the Agency subscription",
          "link": "Learn more about subscriptions",
          "button": "Upgrade subscription"
        }
      },
      "tags": {
        "jobsCompleted": {
          "1": "Under 5 jobs completed",
          "2": "6 - 10 jobs completed",
          "3": "More than 10 jobs completed"
        },
        "deliveryTime": {
          "1": "Under 5 days delivered",
          "2": "5 - 10 days delivered",
          "3": "More than 10 days delivered"
        },
        "worked": {
          "exclude_worked_with": "New to me",
          "any": "Any",
          "only_worked_with": "Worked for me"
        }
      }
    },
    "adCenter": {
      "meta": "Ad center",
      "title": "Ad center",
      "emptyBoost": {
        "title": "Expand your reach by boosting your TikTok videos!",
        "message": "Use JoinBrands' integration with TikTok to enhance the visibility of creators' posts through Spark ads, without the need for a separate TikTok Ads account! {0}",
        "readMore": "Read more",
        "boosts": {
          "tiktok": {
            "title": "Select TikTok",
            "subtitle": "From your TikTok videos"
          },
          "spark": {
            "title": "Add TikTok",
            "subtitle": "Using spark code"
          },
          "content": {
            "title": "Get content",
            "subtitle": "for TikTok Ads"
          }
        }
      },
      "tabs": {
        "all": "All ads ({total})",
        "tiktok": "TikTok videos ({total})"
      },
      "adsTable": {
        "noRecords": "No ads found",
        "legend": "ad | ads",
        "columns": {
          "adName": "Ad name",
          "product": "Product",
          "status": "Status",
          "dailyBudget": "Daily budget",
          "impressions": "Impressions",
          "reach": "Reach",
          "clicks": "Clicks",
          "cpc": "CPC",
          "ctr": "CTR",
          "cpm": "CPM",
          "spend": "Total spent"
        },
        "footer": {
          "totalAds": "Total {count} ad | Total {count} ads"
        },
        "status": {
          "draft": "Draft",
          "pendingCreation": "Pending Creation",
          "paymentFailed": "Payment Failed",
          "pendingTiktokApproval": "Pending TikTok Approval",
          "requestedRefund": "Refund Requested",
          "refundProcessed": "Refund Processed",
          "paused": "Paused",
          "delivering": "Delivering",
          "completed": "Completed",
          "rejected": "Rejected by TikTok",
          "canceled": "Canceled",
          "scheduled": "Scheduled",
          "notExist": "Not Exists",
          "adminPendingApproval": "Pending approval",
          "adminRejected": "Rejected"
        },
        "menu": {
          "duplicate": "Duplicate",
          "chat": "Chat with creator",
          "link": "See post",
          "details": "Boost details",
          "delete": "Delete ad"
        }
      },
      "tiktokVideos": {
        "notFound": "Sorry, but nothing matched your search terms. Please change your filtering selections and try again."
      },
      "button": {
        "create": "Create boost",
        "filters": "Filters",
        "search": "Search by name or product"
      },
      "form": {
        "input": {
          "search_phrase": {
            "validation": {
              "max": "The search term must be lower than 64 characters.",
              "required": "The search term is required."
            }
          }
        }
      },
      "detailsSidebar": {
        "title": "Ad details",
        "switch": {
          "label": "Boost",
          "on": "On",
          "off": "Off"
        }
      },
      "adStats": {
        "menu": {
          "resume": "Resume",
          "pause": "Pause",
          "edit": "Edit",
          "duplicate": "Duplicate",
          "delete": "Delete ad"
        },
        "label": {
          "dailyBudget": "Daily budget",
          "totalBudget": "Total budget",
          "totalSpent": "Amount Spent",
          "impressions": "Impressions",
          "clicks": "Clicks",
          "spent": "Amount spent",
          "cpc": "CPC",
          "cpm": "CPM",
          "ctr": "CTR"
        }
      },
      "adSummary": {
        "title": "Ad Summary",
        "link": "Link to post",
        "website": "Promoted website",
        "cta": "Call to action",
        "schedule": "Schedule",
        "location": "Location",
        "gender": "Gender",
        "age": "Age",
        "device": "Device",
        "interests": "Interests",
        "hashtags": "Hashtag interactions"
      },
      "editionSidebar": {
        "title": "Edit ad",
        "h1": {
          "details": "Details",
          "budget": "Budget & Schedule",
          "audience": "Audience"
        },
        "label": {
          "adType": "Type of boost",
          "addTiktok": "3rd Party Content",
          "selectTiktok": "Content From JoinBrands",
          "resume": "{0} {1}",
          "total": "Total budget: {amount}",
          "fee": "(plus {fee} platform fee)"
        },
        "tooltip": {
          "scheduleDisabled": "The date cannot be edited once the ad has started running."
        }
      },
      "filtersSidebar": {
        "title": "Filters",
        "label": {
          "dates": "Start and end date",
          "dateRange": "{start} to {end}",
          "brand": "Brand",
          "product": "Product",
          "status": "Ad status",
          "all": "All",
          "selected": "{count} products"
        },
        "placeholder": {
          "dates": "mm/dd/yyyy - mm/dd/yyyy"
        }
      },
      "modalDeleteAd": {
        "title": "Do you want to delete the ad?",
        "description": "All unused funds will be returned back to your wallet.",
        "button": "Delete"
      }
    },
    "boost": {
      "meta": "Create boost",
      "title": "New boost",
      "name": "New boost: {0}",
      "create": "Create boost",
      "details": {
        "meta": "Boost details",
        "step": "Details",
        "title": "Details",
        "form": {
          "name": {
            "label": "Ad name",
            "placeholder": "Boost name",
            "validation": {
              "required": "The name is required.",
              "min": "The name must have at least {characters} characters.",
              "max": "The name should not exceed {characters} characters."
            }
          },
          "boosts": "Type of boost",
          "tiktokVideo": {
            "title": "TikTok video",
            "label": "Select TikTok video",
            "change": "Change TikTok video",
            "legend": "The 365-day permission from the creator to advertise the video on TikTok. {0}",
            "readMore": "Read more",
            "sparkCode": "Spark code: {code}",
            "validation": {
              "required": "You have to select the TikTok video."
            }
          },
          "website": {
            "label": "Promoted website",
            "placeholder": "https://www.example-url.com",
            "legend": "The landing page or website you want people to go to when they click your ad.",
            "validation": {
              "format": "Make sure the URL is valid. Enter an URL starting with https://",
              "required": "The promoted website is required."
            }
          },
          "action": {
            "label": "Call to action",
            "placeholder": "Select action",
            "legend": "The action you want TikTok users to take when they see your ad.",
            "validation": {
              "required": "The action is required."
            }
          },
          "sparkCode": {
            "label": "Spark code",
            "placeholder": "nikjuVYnbN2VINaqiurniC3unbaiBAvISo2BOO",
            "validation": {
              "invalidCode": "The spark code is invalid or expired. Ask the creator to provide a new 365-day spark code.",
              "required": "You have to provide the spark code."
            }
          }
        },
        "modal": {
          "title": "Choose your TikTok video",
          "search": "Search by name or product",
          "result": "Showing {posts} TikTok posts",
          "video": {
            "views": "Video views",
            "comments": "Comments"
          },
          "noRecords": {
            "title": "Looks empty here",
            "message": "Looks like no videos match your search. Update your search filters"
          }
        }
      },
      "schedule": {
        "meta": "Boost schedule",
        "step": "Budget & Schedule",
        "title": "Budget & Schedule",
        "form": {
          "budget": {
            "label": "Daily budget",
            "legend": "The minimum daily budget is {budget} + a {fee}% platform fee.",
            "validation": {
              "min": "The minimum amount is {amount}.",
              "max": "The maximum amount allowed is {amount}.",
              "required": "The daily budget field is required."
            }
          },
          "startDate": {
            "label": "Start date",
            "validation": {
              "required": "The start date is required.",
              "valid": "You have to select a valid date",
              "min": "The start date can't be lower than tomorrow."
            }
          },
          "endDate": {
            "label": "End date",
            "validation": {
              "required": "The end date is required.",
              "valid": "You have to select a valid date.",
              "min": "The end date can't be lower or equal than the start date."
            }
          }
        }
      },
      "audience": {
        "meta": "Boost audience",
        "step": "Audience",
        "title": "Audience",
        "basic": {
          "title": "Basic",
          "subtitle": "We will automatically find an audience"
        },
        "advance": {
          "title": "Advanced",
          "subtitle": "You can customize the audience yourself"
        },
        "gender": {
          "label": "Gender",
          "GENDER_UNLIMITED": "All",
          "GENDER_MALE": "Male",
          "GENDER_FEMALE": "Female"
        },
        "age": {
          "label": "Age",
          "ALL_AGES": "All",
          "AGE_13_17": "13-17",
          "AGE_18_24": "18-24",
          "AGE_25_34": "25-34",
          "AGE_35_44": "35-44",
          "AGE_45_54": "45-54",
          "AGE_55_100": "55+",
          "validation": {
            "required": "You must select at least one option"
          }
        },
        "device": {
          "label": "Device",
          "ALL": "All",
          "ANDROID": "Android",
          "IOS": "iOS",
          "validation": {
            "required": "You must select at least one option"
          }
        },
        "size": {
          "title": "Audience size",
          "fairlyBroad": "Fairly broad",
          "balanced": "Balanced",
          "narrow": "Narrow",
          "tooNarrow": "Too narrow",
          "tooNarrowMessage": "Not enough audience for this ad, please adjust the settings.",
          "validation": {
            "required": "The audience is too narrow"
          }
        },
        "location": {
          "label": "Location",
          "placeholder": "Type a country",
          "legend": "Target location where you want to show your ad.",
          "comboboxLegend": "Select the location that matches your audience settings.",
          "validation": {
            "required": "You have to select at least one location"
          }
        },
        "interests": {
          "label": "Interests (optional)",
          "placeholder": "kids, dogs, cats",
          "legend": "Target people with any of the following interests.",
          "comboboxLegend": "Select the interest that matches your audience settings."
        },
        "interactions": {
          "label": "Hashtag interactions (optional)",
          "placeholder": "Outdoor, Sport",
          "legend": "Target people who've watched videos with the following hashtags.",
          "comboboxLegend": "Select the hashtags that matches your audience settings."
        }
      },
      "payment": {
        "meta": "Boost payment",
        "step": "Summary & Payment",
        "summary": {
          "title": "Ad details",
          "name": "Ad name",
          "schedule": "Schedule",
          "action": "Call to action",
          "website": "Promoted website"
        },
        "payment": {
          "title": "Payment",
          "subtitle": "Payment summary",
          "budget": "Daily budget",
          "fee": "Platform fee {feePercent}%",
          "totalDaily": "Total daily budget",
          "totalAd": "Total ad budget",
          "totalAdBudget": "{amount} a day x {days} days",
          "method": "Choose a payment method.",
          "addCard": "Add new card",
          "tip": {
            "title": "You will be charged {amount} daily for {days} days.",
            "message": "You can pause or stop the ad at any time and return the remaining funds."
          }
        }
      },
      "campaignsTip": {
        "title": "You can boost {count} post via SparkAds|You can boost {count} posts via SparkAds",
        "text": "Boost your TikTok posts to reach millions of interested viewers & potential buyers.",
        "button": "Boost post"
      },
      "navbar": {
        "cancel": "Cancel boost creation",
        "modal": {
          "title": "Are you sure you want to cancel this ad?",
          "message": "The information provided during the creation flow will be lost."
        }
      },
      "created": {
        "meta": "Boost created",
        "title": "The ad has been submitted and it will go live soon.",
        "buttons": {
          "back": "Back to dashboard",
          "create": "Create another ad"
        }
      },
      "draft": {
        "modal": {
          "title": {
            "resume": "Resume editing?",
            "discard": "Discard unfinished ad?"
          },
          "message": "You have an unfinished ad. Want to discard the unfinished ad and create a new one?",
          "message2": "You have an unfinished ad. Want to discard the unfinished ad and continue with the selected ad?",
          "message3": "You have an unfinished ad. Want to discard the unfinished ad and continue with the selected content?",
          "message4": "You have an unfinished ad. Want to resume editing this boost or create a new one?",
          "buttons": {
            "new": "Create new boost",
            "resume": "Resume editing",
            "cancel": "Cancel",
            "continue": "Continue"
          }
        }
      },
      "leaving": {
        "title": "Leave boost creation?",
        "message": "Changes you made may not be saved."
      }
    },
    "recurringCampaigns": {
      "meta": {
        "title": "My recurring campaigns"
      },
      "link": "How it works?",
      "datatable": {
        "noRecords": "No recurring campaigns found",
        "legend": "recurring campaigns",
        "columns": {
          "campaign": "Campaign",
          "numberCreators": "Number of creators",
          "content": "Content",
          "monthlyPay": "Monthly pay"
        },
        "cell": {
          "content": {
            "withContent": "{total} new content",
            "withoutContent": "No new content"
          },
          "options": {
            "campaignDetails": "Campaign details",
            "content": "Content",
            "changePaymentMethod": "Change payment method",
            "archive": "Archive campaign"
          }
        }
      },
      "agreementModal": {
        "title": "Content Creation Agreement",
        "resume": "This Content Creation Agreement (\"Agreement\") is made effective as of {0}, by and between {1}, hereby referred to as \"Company\", and {2}, hereby referred to as \"Creator\". Both parties are users (\"Users\") of the JoinBrands.com platform (\"Platform\"), which is owned and operated by JoinBrands (\"JoinBrands\"). This Agreement is subject to the JoinBrands {3} (\"TOS\") and {4}.",
        "tos": "TOS",
        "termsOfService": "Terms of Service",
        "privacyPolicy": "Privacy Policy",
        "1": {
          "title": "1. Platform Use and Purpose. {0}",
          "description": "The Company engages the Creator to produce content for social media platforms such as TikTok, Instagram, or both under the terms specified within the Platform."
        },
        "2": {
          "title": "2. Compensation. {0}",
          "description": "The Creator will be compensated via micropayments for each posted content, funded by the Company’s monthly escrow held on the Platform, ensuring immediate disbursement upon posting confirmation."
        },
        "3": {
          "title": "3. Content Creation and Posting Regularity. {0}",
          "description": "The Creator agrees to post content with regularity appropriate for the selected platforms, as detailed within the Platform's system."
        },
        "4": {
          "title": "4. Communication. {0}",
          "description": "All communications between the Company and the Creator must occur within the Platform's provided chat system."
        },
        "5": {
          "title": "5. Exclusivity and Non-Circumvention. {0}",
          "description": "Both parties agree not to engage in any activities that would circumvent the Platform's intended use, including direct engagements outside the Platform."
        },
        "6": {
          "title": "6. Ownership, License, and Use of Content. {0}",
          "description": "Upon publication, all content created by the Creator for the Company under this Agreement becomes the property of the Company. JoinBrands retains the right to use the content for marketing purposes, as per the TOS."
        },
        "7": {
          "title": "7. Termination. {0}",
          "description": "This Agreement can be terminated by either party at any time."
        },
        "8": {
          "title": "8. No Liability. {0}",
          "description": "JoinBrands is not liable for any disputes arising from the content's quality, posting frequency, or any other aspect related to the content created under this Agreement."
        },
        "legend": "Clicking \"I Agree\" confirms you've read and agree to this Agreement, the {0}, and {1}, serving as your electronic signature.",
        "button": {
          "agree": "I Agree"
        }
      },
      "chat": {
        "dropdown": {
          "profile": "See profile",
          "campaign": "Go to campaign",
          "contract": "Go to job",
          "cancel": "Cancel offer",
          "deny": "Deny offer"
        }
      },
      "cancelOfferModal": {
        "title": "Do you really want to cancel your offer?",
        "message": "You are about to withdraw your offer from {creator}. Are you sure?",
        "cancelJob": {
          "title": "Do you really want to cancel the job?",
          "message": "You are about to cancel the job for {creator}. Are you sure?"
        }
      },
      "denyCounterofferModal": {
        "title": "Do you want to deny the offer?",
        "message": "You are about to deny the offer from {creator}. Are you sure?"
      },
      "resend": {
        "title": "Resend offer",
        "frequency": {
          "title": "Content frequency",
          "legend": "How often (on average) do you want the creator to create and post the content?",
          "cards": {
            "daily": {
              "title": "Once a day",
              "legend": "30 videos/month"
            },
            "twiceADay": {
              "title": "Twice a day",
              "legend": "60 videos/month"
            },
            "everyTwoDays": {
              "title": "Once every 2 days",
              "legend": "15 videos/month"
            },
            "everyThreeDays": {
              "title": "Once every 3 day",
              "legend": "10 videos/month"
            }
          }
        },
        "offer": {
          "title": "Monthly pay",
          "legend": "Make an offer to the creator for the price",
          "validation": {
            "required": "The monthly pay is required.",
            "min": "The monthly pay can not be less than {amount}.",
            "max": "The monthly pay can not be greater than {amount}."
          }
        },
        "brief": {
          "title": "Create an offer for influencers",
          "message": "Give creators more details about the product or service you would like to promote. You can include a description with this its main feature as well as some links.",
          "input": {
            "title": "Creator brief",
            "placeholder": "",
            "characters": "Characters {current} / {max}"
          }
        },
        "references": {
          "title": "References (optional)",
          "tiktok": {
            "legend": "Add suggest links that you like from Instagram Reels to give the creator some content ideas",
            "placeholder": "https://tiktok.com/{'@'}creator/video"
          },
          "instagram": {
            "legend": "Add suggest links that you like from TikTok Posts to give the creator some content ideas",
            "placeholder": "https://instagram.com/reel/myvideo"
          }
        }
      },
      "filter": {
        "status": {
          "active": "Active ({total})",
          "archive": "Archive ({total})"
        },
        "sort": {
          "newest": "Newest",
          "quantity": "Quantity",
          "score": "Score"
        },
        "form": {
          "filters": {
            "label": "Filters"
          }
        }
      }
    },
    "contracts": {
      "meta": {
        "title": "Recurring jobs"
      },
      "sidebar": {
        "title": "Job details",
        "brand": {
          "title": "Brand"
        },
        "buttons": {
          "approve": "Approve offer",
          "deny": "Deny offer",
          "counteroffer": "Counter offer",
          "cancel": "Cancel",
          "send": "Send offer"
        },
        "content": {
          "title": "Required content",
          "creators": "Recurring creators",
          "payment": {
            "monthly": "Monthly payment",
            "weekly": "Weekly payment"
          }
        },
        "creator": {
          "title": "Creator brief",
          "legend": "TODO pending text"
        },
        "references": "References",
        "counteroffer": {
          "title": "Counteroffer",
          "frequency": {
            "title": "Content frequency",
            "legend": "How often (on average) do you want to create and post the content",
            "cards": {
              "daily": {
                "title": "Once a day",
                "legend": "30 videos/month"
              },
              "twiceADay": {
                "title": "Twice a day",
                "legend": "60 videos/month"
              },
              "everyTwoDays": {
                "title": "Once every 2 days",
                "legend": "15 videos/month"
              },
              "everyThreeDays": {
                "title": "Once every 3 day",
                "legend": "10 videos/month"
              }
            }
          },
          "offer": {
            "title": "Monthly pay",
            "legend": "Make an offer to the creator for the price",
            "validation": {
              "required": "The monthly pay is required.",
              "min": "The monthly pay can not be less than {amount}.",
              "max": "The monthly pay can not be greater than {amount}."
            }
          }
        },
        "earnings": {
          "title": "Earnings",
          "earn": {
            "title": "Earn on content",
            "amount": "{amount} /per month"
          },
          "frequency": {
            "title": "Content frequency",
            "daily": "Daily",
            "twice_a_day": "Twice a day",
            "every_other_day": "Every other day",
            "every_third_day": "Every third day"
          },
          "videos": "Number of videos",
          "total": "Total value"
        }
      }
    },
    "chat": {
      "meta": "Chat",
      "jobProduct": {
        "price": "Job price"
      },
      "card": {
        "title": "Chat with {0}",
        "issue": "Escalate issue",
        "sendMessage": "Write message...",
        "noMessages": "No messages yet!",
        "startConversation": "Start your conversation.",
        "endedConversation": "The chat is closed.",
        "status": {
          "canceled": "canceled",
          "finished": "finished"
        },
        "buttons": {
          "send": "Send",
          "back": "Back to Job",
          "backToCampaign": "Back to campaign",
          "goToCampaign": "Go to campaign",
          "go": "Go to Job",
          "extend": "Extend deadline",
          "cancel": "Cancel job",
          "tip": "Send a tip",
          "rehire": "Hire again",
          "unread": "Mark unread"
        },
        "rehireModal": {
          "title": "Rehire this creator?",
          "message": "A new campaign will auto-assign this creator, providing options for the type of content this creator will create.",
          "confirm": "Create a new job"
        },
        "successModal": {
          "message": "Your review has been sent. Thank you!"
        }
      },
      "modal": {
        "title": "Cancel this job",
        "form": {
          "label": "Description",
          "placeholder": "Please explain why you decided to cancel this job.",
          "maxChars": "Maximum 200 characters",
          "textArea": {
            "validation": {
              "required": "The description is required.",
              "min": "The reason must be greater than {min} characters.",
              "max": "The reason should not exceed {max} characters."
            }
          }
        },
        "buttons": {
          "cancel": "Cancel",
          "send": "Send"
        },
        "type": {
          "mediation": {
            "title": "This Job will be reviewed!",
            "message": "We will review your issue and we will send the resolution."
          },
          "reimbursement": {
            "title": "Product refund!",
            "message": "If you cancel the job right now, you will receive a full refund for the cost of the product."
          },
          "earnings": {
            "title": "Job refund!",
            "message": "If you cancel the job right now, you will receive a full refund for the cost of the job."
          }
        }
      },
      "noChats": {
        "h3": "All good!",
        "p": "Looks like everything is going smoothly.",
        "button": "Go to dashboard"
      },
      "flash": {
        "cancelled": "This job has been cancelled!"
      },
      "filter": {
        "all": "All chats",
        "job": "Jobs",
        "contract": "Recurring jobs"
      },
      "bot": {
        "companyIsRequestedForReview": {
          "title": "The job has been successfully completed!",
          "text": "Loved working with {creatorName}? Leave a review! Share your experience to help creators improve their content!"
        },
        "creatorAcceptedJobInvitation": {
          "text": "{0} has accepted the job offer. New campaign is created."
        },
        "creatorDeclinedJobInvitation": {
          "text": "{0} has rejected the job offer. Sorry about that."
        },
        "companySubmittedReview": {
          "text": "Thank you for submitting a review!"
        },
        "companySentTip": {
          "text": "Your generous tip of {0} has been successfully sent to {1}. Thank you!"
        },
        "companySentJobInvitation": {
          "text": "You have successfully sent the invitation. Wait for the creator to accept it."
        },
        "usedForbiddenWord": {
          "text": " Your message has not been delivered because it violates our {0}."
        },
        "chatCreated": {
          "firstMessage": "Welcome to the JoinBrands chat! This is your dedicated space to communicate with {0}. Dive into discussions, ask questions, and clarify any project details here.",
          "secondMessage": {
            "text": "{0} {1} {2} {3}",
            "secondMessageFirstPart": "Please note:",
            "secondMessageSecondPartBeforeLink": "This chat is the exclusive channel for your interactions with the creator. Requesting or sharing personal contact details such as emails, phone numbers, or any other means of off-platform communication is strictly prohibited and violates our",
            "secondMessageSecondPartAfterLink": ". Keep all interactions within this chat to maintain transparency and adhere to our guidelines."
          }
        },
        "recurringCampaign": {
          "recurringJobOffer": "Recurring job offer",
          "contentFrequency": "Content frequency: {0}",
          "videosPerMonth": "Videos per month: {0}",
          "monthlyPay": "Monthly pay: {0}",
          "companyCreateOfferNotification": {
            "text": "A recurring creator job offer has been successfully sent to {0}. Waiting for response, {0} is required to accept or counter the offer within 3 days, failing which the offer will expire."
          },
          "companyAcceptOffer": {
            "text": "You have accepted an offer from {0}."
          },
          "companyOfferExpired": {
            "text": "Your offer expired due to no response from the creator within the allotted time."
          },
          "companyStartTimeout": {
            "text": "The contract has expired because it was not started in the allotted time."
          },
          "companyRejectOffer": {
            "text": "You have rejected the counteroffer."
          },
          "companyCancelContract": {
            "title": "Offer canceled",
            "text": "Your offer has been withdrawn, and {0} has been informed of this decision. If this was done in error, you can resend the offer."
          },
          "companyApprovedContent": {
            "text": "You have approved the content."
          },
          "companyRejectedContent": {
            "text": "You have rejected the content."
          },
          "companyAcceptPauseContract": {
            "text": "The contract will be paused from {startDate} to {endDate}."
          },
          "companyRejectPauseContract": {
            "text": "You have rejected the pause request."
          },
          "companyUnpauseContract": {
            "text": "The contract has been resumed."
          },
          "companyFinishedContract": {
            "text": "You have finished the contract. Once all the pending work has completed or expired we will issue a refund for the remaining pending content."
          },
          "companyRechargeWarningSent": {
            "text": "A friendly reminder that this recurring contract will continue until canceled. You will be charged for the next month in a few days."
          },
          "creatorCancelContract": {
            "title": "Counteroffer canceled",
            "text": "{0} has withdrawn their counteroffer."
          },
          "creatorAcceptOffer": {
            "title": "{0 } has accepted the job offer",
            "features": {
              "monthlyPay": "MonthlyPay: {0}",
              "contentFrequency": "Content frequency: {0}"
            },
            "details": {
              "text": "You must start the job within 30 days, or it will be automatically canceled. To confirm the campaign and payment, please click the {0} button."
            }
          },
          "creatorCounterOfferNotification": {
            "text": "{0} offers you a counteroffer. See the job details."
          },
          "creatorRequestContentApproval": {
            "text1": "The creator has sent you content for approval.",
            "text2": "Content is done. Confirm it or send it for revision.",
            "button": {
              "approve": "Approve"
            }
          },
          "creatorRejectOffer": {
            "text": "{0} has rejected the job offer. Sorry about that."
          },
          "creatorOfferExpired": {
            "title": "Counteroffer expired",
            "text": "The counteroffer from {0} has expired due to no response within the allotted time."
          },
          "creatorRequestPauseContract": {
            "title": "{creatorName} requests a pause from {startDate} to {endDate}.",
            "text": "The reason for the pause is \"{reason}\".",
            "button": {
              "accept": "Accept",
              "reject": "Reject"
            }
          },
          "creatorRequestPauseContractExpired": {
            "text": "The pause request has been expired."
          },
          "creatorFinishedContract": {
            "text": "{0} has finished the contract."
          },
          "paymentFailed": {
            "text": "We were unable to charge your Credit card. Please update your payment method."
          },
          "shippingStart": {
            "text": "Congratulations on starting the job! Please proceed to send the product to the creator's address"
          },
          "shippingTracking": {
            "title": "Your tracking number",
            "text": "You can track your package with the following tracking number:"
          },
          "shippingTrackingNotification": {
            "title": "Your tracking number"
          },
          "shippingReceived": {
            "text": "Product received"
          },
          "noShippingRequired": {
            "text": "The contract is now active. No shipping is required."
          },
          "slotExpired": {
            "text": "The content for the {date} was not provided on time. The creator will not be paid for this day."
          }
        }
      }
    },
    "recurringCampaign": {
      "meta": {
        "title": "Recurring campaign"
      },
      "header": {
        "approvalRequired": "Approval required",
        "amount": {
          "label": "Monthly pay",
          "value": "{0}/month"
        },
        "action": {
          "cancel": "Cancel campaign"
        }
      },
      "tabs": {
        "recurringJobs": {
          "title": "Jobs"
        },
        "stalledJobs": {
          "title": "Stalled jobs"
        }
      },
      "contracts": {
        "status": {
          "all": "All",
          "active": "Active",
          "invite": "Invite",
          "finished": "Finished"
        },
        "creator": {
          "status": {
            "draft": "Draft",
            "negotiation": "Negotiation",
            "pending_shipping": "Pending shipping",
            "creator_accepted": "Creator accepted",
            "active": "Active",
            "payment_failed": "Payment failed",
            "canceled": "Canceled",
            "paused": "Paused"
          }
        },
        "datatable": {
          "noRecords": "No jobs found",
          "legend": "jobs",
          "columns": {
            "creator": "Creator",
            "status": "Status",
            "numberVideos": "Number of videos",
            "monthlyPay": "Monthly pay",
            "actions": "Actions"
          }
        }
      },
      "status": {
        "active": "Active",
        "archived": "Archived",
        "draft": "Draft"
      },
      "creatorsConfirmed": {
        "title": "Creator(s) confirmed",
        "button": {
          "start": "Start"
        }
      }
    },
    "recurringJob": {
      "tabs": {
        "deliveryInfo": "Delivery info",
        "contractInfo": "Contract info",
        "content": "Content",
        "chat": "Chat"
      },
      "contract": {
        "creatorLabel": "Creator"
      },
      "creatorCard": {
        "contractDate": "Contract began: {date}",
        "videos": "Number of videos",
        "pay": "Monthly pay",
        "amount": "{amount}/month",
        "tooltip": "The creator will be compensated via micropayments for each posted content, funded by the company's monthly escrow held on the platform, ensuring immediate disbursement upon posting confirmation.",
        "dropdown": {
          "details": "Job details",
          "cancel": "End contract"
        }
      },
      "calendar": {
        "link": "Back to campaign «{name}»",
        "plan": {
          "title": "Content plan",
          "monthRadio": "Per month",
          "yearRadio": "Per year",
          "deadline": "{0}/{1} deadline",
          "no_events": "There are no events",
          "pending_delivery": "Pending delivery",
          "product_received": "Product is received",
          "paused": "Paused",
          "completed": "Completed",
          "paid_out": "Paid out",
          "video_required": "Video required",
          "video_pending_approval": "Video pending approval",
          "video_denied": "Video has been denied",
          "pending_post_url": "Pending post URL",
          "expired": "Expired",
          "days": {
            "0": "Sun",
            "1": "Mon",
            "2": "Tue",
            "3": "Wed",
            "4": "Thu",
            "5": "Fri",
            "6": "Sat",
            "7": "Sun"
          },
          "months": {
            "0": "JANUARY",
            "1": "FEBRUARY",
            "2": "MARCH",
            "3": "APRIL",
            "4": "MAY",
            "5": "JUNE",
            "6": "JULY",
            "7": "AUGUST",
            "8": "SEPTEMBER",
            "9": "OCTOBER",
            "10": "NOVEMBER",
            "11": "DECEMBER"
          },
          "yearly": {
            "draft": "There are no events",
            "pending": "Video required",
            "completed": "Completed"
          }
        }
      },
      "content": {
        "link": "Back to campaign «{name}»",
        "title": "Uploaded content",
        "dateLabel": "{startDate} to {endDate}",
        "table": {
          "date": "Date",
          "preview": "Preview",
          "link": "Link",
          "sparkCode": "Spark code",
          "action": "Action",
          "actions": {
            "details": "See details",
            "post": "Post content",
            "reUpload": "Re-upload",
            "goApproval": "Go to approval"
          },
          "notFound": "No content found",
          "legend": "content",
          "status": {
            "title": "Status",
            "pending_approval": "On approval",
            "approved": "Approved",
            "rejected": "Denied",
            "content_published": "Content published",
            "processing": "Being processed",
            "completed": "Content published"
          },
          "copy": {
            "tiktok": "TikTok URL",
            "instagram": "Instagram URL",
            "sparkCode": "Spark code"
          }
        }
      },
      "sidebar": {
        "statusLabel": "Job status"
      },
      "cancelContractModal": {
        "title": "Do you really want to end your recurring job?",
        "message": "You are about to end your recurring job with {brand}. Are you sure?"
      },
      "approveContent": {
        "title": "Approve content",
        "badge": {
          "rejected": "Rejected",
          "approved": "Approved"
        }
      },
      "uploadedContent": {
        "title": "Uploaded content"
      },
      "contractInfo": {
        "creatorBrief": "Creator brief"
      }
    }
  },
  "shared": {
    "modules": {
      "pageNotFound": {
        "views": {
          "pageNotFound": {
            "meta": {
              "title": "Page not found"
            },
            "h1": "Page Not Found",
            "url": "Please check the URL in the address bar and try again."
          }
        }
      }
    },
    "components": {
      "payment": {
        "paymentCardCreation": {
          "stripe": {
            "error": {
              "description": "Oops! It seems our payment processing platform is experiencing a momentary delay. Please click the Retry button in a moment. Your transaction is important to us, and we appreciate your patience. Thank you!",
              "button": "Retry"
            }
          }
        }
      }
    }
  },
  "marketplaces": {
    "us": {
      "title": "United States",
      "currency": "USD",
      "symbol": "$"
    },
    "ca": {
      "title": "Canada",
      "currency": "CAD",
      "symbol": "$"
    },
    "uk": {
      "title": "United Kingdom",
      "currency": "GBP",
      "symbol": "£"
    },
    "au": {
      "title": "Australia",
      "currency": "AUD",
      "symbol": "$"
    }
  }
}
